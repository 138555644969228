import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import { Grid, Row, Col } from "react-bootstrap";


import { NavItem, Nav, NavDropdown, MenuItem } from "react-bootstrap";


import { Query } from "react-apollo";
import gql from "graphql-tag";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';

import Slider from "react-slick";

const restLink_dam = new HttpLink({ 
  uri: "/api/v1/dam/",
  credentials: 'include'
});

const dam_client = new ApolloClient({
  link: restLink_dam,
  cache: new InMemoryCache(),
});

const restLink_chat = new HttpLink({ 
  uri: "/api/v1/chat/" ,
  credentials: 'include'
});

const chat_client = new ApolloClient({
  link: restLink_chat,
  cache: new InMemoryCache(),
});

const months = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ']


const GET_DAM = gql`
  query getDam($module_name: String!){
    assets_collections(limit:5,order:"ASC",module_name:$module_name) {
      assets{
        id, title, asset, directlink
      }
    }
  }`;


class Courses extends Component {

  
  createLegend(json) {
    var legend = [];
    for (var i = 0; i < json["names"].length; i++) {
      var type = "fa fa-circle text-" + json["types"][i];
      legend.push(<i className={type} key={i} />);
      legend.push(" ");
      legend.push(json["names"][i]);
    }
    return legend;
  }

  minutes_with_leading_zeros(dt) 
  { 
    return (dt.getMinutes() < 10 ? '0' : '') + dt.getMinutes();
  }

  render() {
    
    var settings_banner = {
      autoplaySpeed: 5000,
      autoplay: true,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
   
    return (
        
      <div>
        <div className="banner">
        <Query
            client={dam_client}
            variables={{module_name:"ChatBanner"}}
            query={GET_DAM}
          >
            {({ loading, error, data }) => {
              if (loading) return <div>Carregando...</div>;
              if (error) return <div>Erro :(</div>;
                
                if(!data.assets_collections) {
                  return (<div>(Vazio)</div>);
                } 
                return (         
                  <Slider {...settings_banner}>
                        {data.assets_collections.assets.map((item) => (
                          <div key={item.id}>
                            <a href={item.directlink} target="_blank">
                              <img src={"/dam/assets/"+item.asset} />
                            </a>
                          </div>
                        ))}
                  </Slider>
                )
          }}
          </Query>
      </div>

<div className="content">

    
<div className="container">

        <Grid fluid>
         
              <Query
                client={chat_client}
                query={gql`
                  {
                    chats_collections(limit:2000, order: "ASC", status: "all"){
                      chats{
                        id,
                        title,
                        short_description,
                        description,
                        datetime_start_format,
                        datetime_end_format,
                        externallink,
                        thumbnail,
                        canceled,
                        canceled_description
                      }
                    }
                  }
                `}
              >
                {({ loading, error, data }) => {
                  if (loading) return <div>Carregando...</div>;
                  if (error) return <div>Erro :(</div>;


                    if(!data.chats_collections.chats || !data.chats_collections.chats.length){
                      return <Col lg={12}><h1>Nenhuma transmissão ativa.</h1></Col>
                    }
                    
                    return data.chats_collections.chats.map((item) => (
                    
                    <Row className="border-bottom">
                      <Col col={2} lg={2} sm={2} className="col">
                          {
                            item.thumbnail &&
                            <img src={"/chat/assets/"+item.thumbnail} className="thumbnail_list" />
                          }
                        </Col>
                        <Col lg={2} sm={3} className="col">
                          <div>{new Date(item.datetime_start_format).toLocaleDateString("pt-BR")}</div>
                          <div>Início: {new Date(item.datetime_start_format).getHours()}:{this.minutes_with_leading_zeros(new Date(item.datetime_start_format))}</div>
                          <div>Fim: {new Date(item.datetime_end_format).getHours()}:{this.minutes_with_leading_zeros(new Date(item.datetime_end_format))}</div>
                        </Col>
                        <Col lg={5} md={2}  sm={7} className="col">
                          <div><p><strong>{item.title}</strong></p></div>
                          <p>{item.short_description}</p>
                        </Col>
                        <Col lg={1} sm={3} className="col">
                          <div className="live_item_calendar">
                              {months[new Date(item.datetime_start_format).getMonth()]}
                              <span>{new Date(item.datetime_start_format).getDate()}</span>
                          </div>
                        </Col>
                        <Col lg={2} sm={2} className="col">
                          {
                            item.canceled ?
                              <div className="btn-fill btn btn-warning" onClick={() => {alert(item.canceled_description)}}>
                                Tranmissão cancelada
                              </div>
                            :
                              <a className="btn-fill btn btn-info" href={(item.externallink ? item.externallink : "/#/live/"+item.id)} target={(item.externallink ? "_blank" : "_self")}>
                                Abrir
                              </a>
                          }
                        
                        </Col>
                    </Row>
      ));
      }}
      </Query>

      
      </Grid>
      </div>
      </div>


      </div>
    );
  }
}

export default Courses;
