import React from 'react';
import cx from 'classnames';
import SliderContext from './context'
import ShowDetailsButton from './ShowDetailsButton'
import Mark from './Mark'
import './Item.css'


const Item = ({ completedCourse, movie, active, unlock}) => (
  <SliderContext.Consumer>
    {({ onSelectSlide, currentSlide, elementRef }) => {
      const isActive = movie === active;
      var IconClass;
      var ativo = "";
      var testeP;
      var classIsCompleted;
      


   
      

      if (completedCourse === true){
        var IconClassIsCompleted = "pe-7s-check";
        classIsCompleted = "slider-item-check";
      }else{
        
      }

      if (active.__typename === movie.__typename && active.id === movie.id){
        IconClass = "pe-7s-look";
        ativo = "ativo-lms";
      }else if (unlock){
        // IconClass = "pe-7s-unlock";
      }else{
        IconClass = "pe-7s-lock";
      }

      return (
        <div
          ref={elementRef}
          className={cx('item', {
            'item--open': isActive,
          })}
        >
          <div className={"slider-item "+classIsCompleted+" "+ativo}>
            <div className="slider-item-icon">
            {completedCourse === true ? 
            <i className={"slider-icon pe-7s-check"} />
            :
            <i className={"slider-icon "+IconClass } />
            }
            </div>
            
            <div className="slider-text">
              <p className="slider-p">{movie.title}</p>
              <hr></hr>
              <span>{movie.description}</span>
              {/* <ShowDetailsButton onClick={() => onSelectSlide(movie)} /> */}
              {/* {isActive && <Mark />} */}
            </div>
          </div>
        </div>
      );
    }}
  </SliderContext.Consumer>
);

export default Item;
