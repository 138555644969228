import React, { Component } from "react";
import { NavItem, Nav, NavDropdown, MenuItem } from "react-bootstrap";

import { ApolloClient } from 'apollo-client';
import { Query } from "react-apollo";
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import { isMobile } from 'react-device-detect';
import logo from "../../assets/img/zenite/novo/logo.png";

import cover from "../../assets/img/cover.jpg";



const restLink_account = new HttpLink({
  uri: "/api/v1/account/",
  credentials: 'include'
});
const account_client = new ApolloClient({
 link: restLink_account,
 cache: new InMemoryCache(),
});


class HeaderLinks extends Component {

  
  render() {
    

    return (
          <Nav pullRight>

          
          
            <Query
                client={account_client}
                query={gql`{
                  get_authenticated_user{
                    id
                    first_name
                    profile{
                      is_admin
                    }
                  }
                }
                `}
              >
                {({ loading, error, data }) => {
                  var user = data;
                  if (loading) return <div>Olá</div>;
                  if (error) return <NavItem 
                                      key={1} 
                                      className="nav-link"
                                      //activeClassName="active"
                                     >
                                        Faça Login
                                    </NavItem>;

                  if(!data.get_authenticated_user || typeof data.get_authenticated_user.id === "undefined" || !data.get_authenticated_user.id) {
                    return <NavItem 
                              key={1} 
                              className="nav-link"
                              //activeClassName="active"
                              >
                                Faça Login
                            </NavItem>;
                  }
                    
                  return  (
                    <div>
                    {!isMobile &&
                    <img id='cover-top' src={cover} />
                    }
                    <NavDropdown
                      title={""}
                      id="basic-nav-dropdown-right"
                    >
                       
                      
                      <Query
                          client={account_client}
                          query={gql`{
                            account_auth{
                              logout_url
                            }
                          }
                          `}
                        >
                          {({ loading, error, data }) => {
                            if (loading) return <div></div>;
                            if (error) return <div>Erro :(</div>;
                              
                              if(!data.account_auth.logout_url) {
                                return "";
                              }

                            return  (
                                <React.Fragment>

                                 {isMobile &&
                                  <div class="text-header-zenite text-center my-3">Bem vindo, <strong>{user.get_authenticated_user.first_name}</strong></div>
                                  }
                                    <NavItem 
                                      data-gtm-type="click" 
                                      data-gtm-clicktype="menu" 
                                      data-gtm-name="Primeira Página"
                                      className="nav-link"
                                      //activeClassName="active"
                                      href="/">
                                        Primeira Página
                                    </NavItem>
                                    <NavItem 
                                      data-gtm-type="click" 
                                      data-gtm-clicktype="menu" 
                                      data-gtm-name="Meus Dados"
                                      className="nav-link"
                                      //activeClassName="active"
                                      href="/#/user">
                                        Meus dados
                                    </NavItem>
                                    
                                    <MenuItem divider />
                                    <NavItem 
                                      data-gtm-type="click" 
                                      data-gtm-clicktype="menu" 
                                      data-gtm-name="Sair"
                                      className="nav-link"
                                      //activeClassName="active"
                                      href={data.account_auth.logout_url}>
                                        Sair
                                    </NavItem>
                                    </React.Fragment>
                                );
                              }}
                        </Query>
                    </NavDropdown>
                    </div>
                  );
                }}
              </Query>
            
        </Nav>
    );
  }
}

export default HeaderLinks;
