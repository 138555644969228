import React, { Component, useState, useEffect } from 'react';
import Slider from '../../widgets/NetflixSlider';
import VideoYouTube from "../../components/Youtube/Youtube.jsx";
import { ExamEvaluative } from "../../components/Exam/ExamEvaluative.jsx";

import { HttpLink } from 'apollo-link-http';
import { ApolloClient } from 'apollo-client';
import { Exam } from "../../components/Exam/Exam.jsx";
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { Query } from "react-apollo";
import { components } from 'react-select';

import ReactMarkdown from 'react-markdown';
import htmlParser from 'react-markdown/plugins/html-parser';

const restLink_lms = new HttpLink({
  uri: "/api/v1/lms/",
  credentials: 'include'
});
const lms_client = new ApolloClient({
  link: restLink_lms,
  cache: new InMemoryCache(),
});

const restLink_dam = new HttpLink({
  uri: "/api/v1/dam/",
  credentials: 'include'
});
const dam_client = new ApolloClient({
  link: restLink_dam,
  cache: new InMemoryCache(),
});

const _GET_TRAILITEMS = gql`
  query trailhascourse($trail_id: Int!){
    trailhascourse(trail_id:$trail_id) {
      trailhascourses{
        id
        course{
          id
          title
          description
          thumbnail
          scorm
          active
          video {
            id
            uuid
          }
        }
        
      }
    }
  }
`;

// const _GET_TRAILITEMS = gql`query getCourse($id: Int!) {
//   course(id:$id){
//     id,
//     title,
//     description,
//     scorm,
//     hours,
//     minutes,
//     thumbnail,
//     code,
//     video{
//       id, thumbnail
//     },
//     proof{
//       id, title
//     }, 
//     reactionevaluation{
//       id, title
//     }
//   }
// }
// `}

const GET_COURSE = gql`
  query course($id: Int!){
    course(id:$id){
      id,
      title,
      description,
      scorm,
      hours,
      minutes,
      thumbnail,
      code,
      video{
        id, thumbnail, uuid
      },
      proof{
        id, title
      }, 
      reactionevaluation{
        id, title
      }
    }
  }
`;

const VIDEO_HAS_USER = gql`
  mutation createOrUpdateVideoHasUser($video_id: String!, $time: Int!, $time_reproduced: Int!, $time_seconds: String!){
    createOrUpdateVideoHasUser( input:{video_id:$video_id, time:$time, time_reproduced:$time_reproduced, time_seconds:$time_seconds}) {
      id
    }
  }
`;

const TRAIL_ITEM_HAS_USER = gql`
  mutation trailItemHasUser($trail_id: Int!, $item_id: Int!, $item_type: String!){
      createTrailItemHasUser(input:{trail_id:$trail_id, item_id:$item_id, item_type:$item_type}){
      id
    }
  }
`;

const GET_TRAIL_ITEM_HAS_USER = gql`
  query getTrailItemHasUser($trail_id: Int!){
    trail_itens_by_user(trail_id: $trail_id) {
      trailitenshasuser{
        item_id, item_type
      }
    }
  }
`;

const GET_VIDEO_BY_USER = gql`
  query getVideoByUser($video_id: String!){
    getVideoByUser(video_id:$video_id) {
      time
      time_reproduced
      time_seconds
    }
  }
`;

const GET_ASSET = gql`
  query asset($id: Int!){
    asset(id: $id) {
      id
      title
      asset
    }
  }
`;

const GET_TRAILITEMS = gql`
  query trailitems($trail_id: Int!){
    trailitems(trail_id:$trail_id) {
      trailitems{
        id, item_id, item_type, ordernum
      }
    }
  }
`;


const GET_PROOF = gql`
  query getProof($proof_id: Int!){
    proof(id: $proof_id) {
      questions {
        id
        title
        answers {
          id
          title
        }
      }
    }
  }`;



const GET_TRAIL_PROOF = gql`
  query getTrail($trail_id: Int!){
    trail(id:$trail_id) {
      proof {
        id
        title
      }
    }
  }
`;



const GET_ANSWER_QUESTION = gql`
query answer_question_collections($proof_id: Int!, $item_id: Int!, $item_type: String!){
  answer_question_collections(proof_id: $proof_id, item_id: $item_id, item_type: $item_type){
    answers_question{
      id
    }
  }
}`;

const FINISH_TRAIL = gql`
  mutation addAnswerQuestion($trail_id: Int!, $status: String!){
    updateTrailHasUser(input:{trail_id:$trail_id, status:$status}) {
      id
    }
  }`;

const GET_TRAIL_REACTIONEVALUATION = gql`
  query getTrail($trail_id: Int!){
    trail(id:$trail_id) {
      reactionevaluation {
        id
        title
      }
    }
  }
`;

const GET_TRAIL = gql`
  query getTrail($id: Int!){
    trail(id:$id) {
      id
      title
      active
      blockcontent
    }
  }
`;

const GET_TRAIL_HAS_USER_ITEM = gql`
  query trail_has_user_item($trail_id: Int!){
    trail_has_user_item(trail_id: $trail_id){
      user_id
      status
    }
  }
`;

const GET_SETTINGS = gql`
  query get_settings{
    get_settings{
      id
      youtube_channel_id
      youtube_playlist_id
      youtube_playlist_title
      client_id
      client_secret
      token
      folder_id
      video_platform
    }
  }
  `;




export default function LmsPage({ setLms, handler, player, trail_id, itemActive, trailItemActive, lmsCompletedCourse }) {



  var time = 0;
  var arrSeconds = [];
  var timeAll = 0;
  var percVideo = [];
  var jsonObj = [];

  var completedCourseTrigger = [];

  const parseHtml = htmlParser({
    isValidNode: node => node.type !== 'script',
    processingInstructions: [/* ... */]
  });

  const [trailSettingsState, setTrailSettingsState] = useState([]);

  // const [, setPlayer] = useState(false);

  const [trailItens, setTrailItens] = useState([]);

  const [thisTrail, setThisTrail] = useState([]);

  const [trailReactionevaluation, setTrailReactionevaluation] = useState([]);

  const [, setTrailItemActive] = useState(0);
  const [, setItemActive] = useState(0);
  // const [active, setActive] = useState(0);
  const [completedCourse, setCompletedCourse] = useState([]);
  const [settingsPlatform, setSettingsPlatform] = useState([]);
  // const [aulas, setAulas] = useState([]);
  // const [perc, setPerc] = useState(false);

  const activeCourse = (trailItemActive, itemActive) => {


    handler(false);
    setTrailItemActive(trailItemActive);
    setItemActive(itemActive);
    setLms(trailItemActive, itemActive);



    if (trailItemActive.item_type === "course" && itemActive.video === null) {
      completedCourseTrigger[trailItemActive.ordernum] = true;
      setCompletedCourse(completedCourseTrigger);
      lmsCompletedCourse(completedCourseTrigger);
      lms_client.mutate({
        variables: { trail_id: trail_id, item_id: trailItemActive.item_id, item_type: 'course' },
        mutation: TRAIL_ITEM_HAS_USER
      });
    }

  }

  function setCompletedItem(arrSeconds) {
    lms_client.mutate({
      mutation: VIDEO_HAS_USER,
      variables: {
        video_id: itemActive.video.uuid, time: timeAll, time_reproduced: arrSeconds.length, time_seconds: JSON.stringify(arrSeconds),
      }
    });
  }

  async function init() {


    if (!trail_id) {
      return null;
    }

    var TrailSettings = (await lms_client.mutate({
      variables: { id: trail_id },
      mutation: GET_TRAIL
    })).data;



    if (!TrailSettings) {
      return null;
    }

    var thisTrail = (await lms_client.query({
      variables: { trail_id: trail_id },
      query: GET_TRAIL_PROOF
    })).data.trail;

    if (!thisTrail) {
      return null;
    }

    // //console.log(TrailSettings.trail);

    setTrailSettingsState(TrailSettings.trail);

    var finishTrail = (await lms_client.mutate({
      variables: { trail_id: trail_id, status: "started" },
      mutation: FINISH_TRAIL
    })).data;

    // //console.log(teste);

    // lms_client.mutate({
    //   mutation: GET_TRAILITEMS,
    //   variables: { trail_id: trail_id, status: "started" },
    // });

    var trailItens = (await lms_client.query({
      variables: { trail_id: trail_id },
      query: GET_TRAILITEMS
    })).data.trailitems.trailitems;


    var trailReactionevaluation = (await lms_client.query({
      variables: { trail_id: trail_id },
      query: GET_TRAIL_REACTIONEVALUATION
    })).data.trail;


    var settings = (await lms_client.query({
      query: GET_SETTINGS
    })).data;

    setSettingsPlatform(settings.get_settings.video_platform);


    // var proofTrail = (await lms_client.query({
    //   variables: { proof_id: 2 },
    //   query: GET_PROOF
    // })).data.proof.questions;

    var trailFinish = (await lms_client.query({
      variables: { trail_id: trail_id },
      query: GET_TRAIL_HAS_USER_ITEM
    })).data.trail_has_user_item;


    if (thisTrail.proof !== null && thisTrail.proof !== undefined) {
      if (trailItens[trailItens.length - 1].__typename !== "Proof" && trailItens[trailItens.length - 2].__typename !== "Proof" && trailItens[trailItens.length - 3].__typename !== "Proof") {
        trailItens.push({
          id: thisTrail.proof.id,
          item_id: thisTrail.proof.id,
          item_type: 'Proof',
          ordernum: trailItens.length + 1,
          __typename: "Proof",
        });
      }
    }

    if (trailFinish !== null && trailFinish !== undefined) {
      if (trailFinish.status === "finalized") {
        if (trailItens[trailItens.length - 1].__typename === "Proof") {
          completedCourseTrigger[trailItens.length] = true;
          setCompletedCourse(completedCourseTrigger);
          lmsCompletedCourse(completedCourseTrigger);
        } else {
          completedCourseTrigger[trailItens.length - 2] = true;
          setCompletedCourse(completedCourseTrigger);
          lmsCompletedCourse(completedCourseTrigger);
        }
      }
    }

    if (trailReactionevaluation.reactionevaluation !== null && trailReactionevaluation.reactionevaluation !== undefined) {
      if (trailItens[trailItens.length - 1].__typename !== "Reactionevaluation" && trailItens[trailItens.length - 2].__typename !== "Reactionevaluation" && trailItens[trailItens.length - 3].__typename !== "Reactionevaluation") {
        trailItens.push({
          id: trailReactionevaluation.reactionevaluation.id,
          item_id: trailReactionevaluation.reactionevaluation.id,
          item_type: 'Reactionevaluation',
          ordernum: trailItens.length + 1,
          __typename: "Reactionevaluation",
        });
      }
    }

    if (thisTrail.proof !== null && thisTrail.proof !== undefined) {

      if (trailItens[trailItens.length - 1].__typename !== "Certificate" && trailItens[trailItens.length - 2].__typename !== "Certificate" && trailItens[trailItens.length - 3].__typename !== "Certificate") {
        trailItens.push({
          id: 100,
          item_id: 100,
          item_type: 'Certificate',
          title: 'Certificado',
          ordernum: trailItens.length + 1,
          __typename: "Certificate",
        });
      }

    }

    var answerQuestionTrail = (await lms_client.query({
      variables: { proof_id: trailItens[trailItens.length - 1].id, item_id: trailItens[trailItens.length - 1].item_id, item_type: "trail" },
      query: GET_ANSWER_QUESTION
    })).data.answer_question_collections;



    if (answerQuestionTrail && answerQuestionTrail.answers_question.length) {

      completedCourseTrigger[trailItens.length] = true;
      setCompletedCourse(completedCourseTrigger);
      lmsCompletedCourse(completedCourseTrigger);
    }

    var trailItensCompleted = (await lms_client.query({
      variables: { trail_id: trail_id },
      query: GET_TRAIL_ITEM_HAS_USER,
      fetchPolicy: 'no-cache'
    })).data.trail_itens_by_user.trailitenshasuser;

    trailItensCompleted.forEach(function (trailItemCompleted, trailItemCompletedIndex) {
      trailItens.forEach(function (trailItem, trailItemIndex) {
        if (trailItemCompleted.item_id === trailItem.item_id && trailItemCompleted.item_type === trailItem.item_type) {

          completedCourseTrigger[trailItem.ordernum] = true;
          setCompletedCourse(completedCourseTrigger);
          lmsCompletedCourse(completedCourseTrigger);
        }
      });
    });

    // Seta todos os itens da trilha
    setTrailItens(trailItens);


    setTrailReactionevaluation(trailReactionevaluation.reactionevaluation);

    setThisTrail(thisTrail.proof);

    // Seta o primeiro item da trilha como ativado, depois verifica se ele curso ou asset para pegar o conteudo e setar como ativado


  }

  function uniqArrayValues(a) {
    var seen = {};
    var out = [];
    var len = a.length;
    var j = 0;
    for (var i = 0; i < len; i++) {
      var item = a[i];
      if (seen[item] !== 1) {
        seen[item] = 1;
        out[j++] = item;
      }
    }
    return out;
  }

  useEffect(() => {
    init();
  }, []);

  async function getCurTime() {

    if (!player) {
      return null;
    }

    try {
      if (trailItemActive) {
        if (settingsPlatform === "youtube") {
          time = parseInt(player.getCurrentTime());
          timeAll = parseInt(player.getDuration());
        } else {
          time = parseInt(await player.getCurrentTime());
          timeAll = parseInt(await player.getDuration());
        }

        if (time !== 0) {
          arrSeconds.push(time);
          arrSeconds = uniqArrayValues(arrSeconds);

          if ((arrSeconds.length % 10) === 0) {
            setCompletedItem(arrSeconds);
          }

          if (completedCourseTrigger[trailItemActive.ordernum] !== true) {
            if (parseInt(arrSeconds.length / timeAll * 100) >= 95) {

              completedCourseTrigger = completedCourse;
              completedCourseTrigger[trailItemActive.ordernum] = true;
              setCompletedCourse(completedCourseTrigger);
              lmsCompletedCourse(completedCourseTrigger);
              handleUpdate();
              setCompletedItem(arrSeconds);
              // Salva o item do course quando chegar em 80%
              lms_client.mutate({
                variables: { trail_id: trail_id, item_id: trailItemActive.item_id, item_type: 'course' },
                mutation: TRAIL_ITEM_HAS_USER
              });
            }
          }
        }
      }
      setTimeout(() => getCurTime(), 900);
    }
    catch (e) {
      //console.log("=============== CATCH ===================");
      handler(false);
    }

  }

  function setCourseFinish() {
    //passing empty object will re-render the component
    if (completedCourseTrigger[trailItemActive.ordernum] !== true) {
      completedCourseTrigger = completedCourse;
      completedCourseTrigger[trailItemActive.ordernum] = true;
      setCompletedCourse(completedCourseTrigger);
      lmsCompletedCourse(completedCourseTrigger);
      // completedCourseTrigger = completedCourse;
    }
    handleUpdate();
  }

  let [, setState] = useState();

  function handleUpdate() {
    setState({});
  }

  async function onPlay(event) {
    if (typeof player !== 'undefined' && player) {
      setTimeout(() => getCurTime(), 900);
    }
  }

  async function onEnd(event) {
    if (typeof player !== 'undefined' && player) {
      console.log("---------------------- END VIDEO ------------------------");
      console.log(trailItemActive);
      console.log(trailItens);

      if(trailItens.length && trailItemActive && trailItens.length+1 > trailItemActive.ordernum) { // nao coloca >= para nao considerar para o último

        var dataItem = false;
        var nextItem = trailItens[trailItemActive.ordernum]; // Pega o ordernum, pois o ordernum começa de 1, e o key de 0, entao o ordernum sempre será o key do próximo
        if(nextItem.item_type === "course") {
          dataItem = (await lms_client.query({
            variables: { id: nextItem.item_id },
            query: GET_COURSE
          })).data.course;
          console.log("é curso");
        }
        else if(nextItem.item_type === "asset") {
          dataItem = (await dam_client.query({
            variables: { id: nextItem.item_id },
            query: GET_ASSET
          })).data.asset;
        }

        if(dataItem) {
          console.log("proximo video");
          activeCourse(nextItem, dataItem)
        }
      }
    }
  }

  async function onReady(event) {
    if (settingsPlatform === "youtube") {
      handler(event.target);
    } else {
      handler(event);
    }
  }



  return (
    <div className="course-page">
      <div className="course-content">
        <h1>{itemActive.title}</h1>
        {trailItemActive.item_type === 'course' && itemActive.video &&

          <VideoYouTube
            settings={settingsPlatform}
            key={itemActive.id}
            onPlay={onPlay}
            onEnd={onEnd}
            onReady={onReady}
            videoTime={itemActive.video.videoTime}
            videoId={itemActive.video.uuid}
          />
        }





        {trailItemActive.item_type === 'asset' &&
          <div>
            <a download href={"/dam/assets/" + itemActive.asset} onClick={() => {
              //console.log("change");
              completedCourse[trailItemActive.ordernum] = true;
              setCompletedCourse(completedCourse);
              lmsCompletedCourse(completedCourse);
              lms_client.mutate({
                variables: { trail_id: trail_id, item_id: trailItemActive.item_id, item_type: 'asset' },
                mutation: TRAIL_ITEM_HAS_USER
              });
              handleUpdate();


            }}>
              Baixar arquivo
            </a>
          </div>
        }



        {trailItemActive.item_type === 'Certificate' &&
          <a download href={"api/v1/lms/certified_trail_generat?u=" + itemActive.asset} onClick={() => {


          }}>
            Baixar arquivo
          </a>
        }

        {trailItemActive.item_type === 'Reactionevaluation' &&
          <div>
            <ExamEvaluative setCourseFinish={setCourseFinish} item_id={trailItemActive.item_id} item_type="trail" proof_id={trailItemActive.id} />
          </div>
        }

        {trailItemActive.item_type === 'Proof' &&
          <div>
            <Exam setCourseFinish={setCourseFinish} trail_id={trail_id} item_id={trailItemActive.item_id} item_type="trail" proof_id={trailItemActive.id} />
          </div>
        }
        <div className="d-flex justify-center lms-controls">
          {trailItens.map((trailItem, index) => (
            <React.Fragment>

              {trailItem.ordernum === trailItemActive.ordernum + 1 && trailItem.item_type === 'asset' &&

                <Query
                  client={dam_client}
                  query={GET_ASSET}
                  variables={{ id: trailItem.item_id }}
                >
                  {({ loading, error, data }) => {
                    if (loading) return <div>Carregando...</div>;
                    if (error) return <div>Erro :(</div>;

                    //console.log(trailItem.ordernum);

                    //console.log(trailItemActive.ordernum);

                    //console.log(trailItens);




                    if (!data.asset) {
                      return;
                    }
                    if (trailSettingsState.blockcontent === 1) {
                      var unlock = false;
                    } else {
                      var unlock = true;
                    }

                    if (trailItem.ordernum === 1 || typeof completedCourse[trailItem.ordernum] !== "undefined" || typeof completedCourse[trailItem.ordernum - 1] !== "undefined") {
                      unlock = true;
                    }
                    return (

                      <button key={completedCourse} className="btn-lms-next my-3" onClick={() => activeCourse(trailItem, data.asset)}>
                        Próximo
                        <i className="pe-7s-next btn-icon"></i>
                      </button>

                    );
                  }}
                </Query>
              }

              {trailItem.ordernum === trailItemActive.ordernum + 1 && trailItem.item_type === 'course' &&

                <Query
                  client={lms_client}
                  query={GET_COURSE}
                  variables={{ id: trailItem.item_id }}
                >
                  {({ loading, error, data }) => {
                    if (loading) return <div>Carregando...</div>;
                    if (error) return <div>Erro :(</div>;
                      //console.log("--------------------");
                    //console.log(trailItem.ordernum);

                    //console.log(trailItemActive.ordernum);

                    //console.log(trailItens);

                    var videoId = false;
                    if (data.course.video !== null && data.course.video.uuid !== null) {
                      var videoId = data.course.video.uuid;
                    }

                    if (trailSettingsState.blockcontent === 1) {
                      var unlock = false;
                    } else {
                      var unlock = true;
                    }



                    if (trailItem.ordernum === 1 || typeof completedCourse[trailItem.ordernum] !== "undefined" || typeof completedCourse[trailItem.ordernum - 1] !== "undefined") {
                      unlock = true;
                    }

                    var courseDate = data;


                    return (

                      <button key={completedCourse} className="btn-lms-next my-3" onClick={() => activeCourse(trailItem, courseDate.course)}>
                        Próximo
                        <i className="pe-7s-next btn-icon"></i>
                      </button>

                    );
                  }}
                </Query>
              }

              {trailItem.ordernum === trailItemActive.ordernum - 1 && trailItem.item_type === 'asset' &&

                <Query
                  client={dam_client}
                  query={GET_ASSET}
                  variables={{ id: trailItem.item_id }}
                >
                  {({ loading, error, data }) => {
                    if (loading) return <div>Carregando...</div>;
                    if (error) return <div>Erro :(</div>;


                    if (!data.asset) {
                      return;
                    }
                    if (trailSettingsState.blockcontent === 1) {
                      var unlock = false;
                    } else {
                      var unlock = true;
                    }

                    if (trailItem.ordernum === 1 || typeof completedCourse[trailItem.ordernum] !== "undefined" || typeof completedCourse[trailItem.ordernum - 1] !== "undefined") {
                      unlock = true;
                    }
                    return (

                      <button key={completedCourse} className="btn-lms-next my-3" onClick={() => activeCourse(trailItem, data.asset)}>
                        <i className="pe-7s-prev btn-icon"></i>
                        Anterior

                      </button>

                    );
                  }}
                </Query>
              }

              {trailItem.ordernum === trailItemActive.ordernum - 1 && trailItem.item_type === 'course' &&

                <Query
                  client={lms_client}
                  query={GET_COURSE}
                  variables={{ id: trailItem.item_id }}
                >
                  {({ loading, error, data }) => {
                    if (loading) return <div>Carregando...</div>;
                    if (error) return <div>Erro :(</div>;

                    //console.log("===================");
                    //console.log(trailItem.ordernum);

                    //console.log(trailItemActive.ordernum);

                    //console.log(trailItens);

                    var videoId = false;
                    if (data.course.video !== null && data.course.video.uuid !== null) {
                      var videoId = data.course.video.uuid;
                    }

                    if (trailSettingsState.blockcontent === 1) {
                      var unlock = false;
                    } else {
                      var unlock = true;
                    }



                    if (trailItem.ordernum === 1 || typeof completedCourse[trailItem.ordernum] !== "undefined" || typeof completedCourse[trailItem.ordernum - 1] !== "undefined") {
                      unlock = true;
                    }

                    var courseDate = data;


                    return (

                      <button key={completedCourse} className="btn-lms-next my-3" onClick={() => activeCourse(trailItem, courseDate.course)}>
                        <i className="pe-7s-prev btn-icon"></i>
                        Anterior
                      </button>

                    );
                  }}
                </Query>
              }


            </React.Fragment>
          ))}
        </div>
        {trailItemActive.item_type === 'course' &&
          <ReactMarkdown
            source={itemActive.description}
            escapeHtml={false}
            astPlugins={[parseHtml]}
            linkTarget="_blank"
          />
        }
      </div>
    </div>
  );

}