import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import { Grid, Row, Col } from "react-bootstrap";

import Slider from "react-slick";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { Query } from "react-apollo";
import gql from "graphql-tag";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';


const restLink_dam = new HttpLink({ 
  uri: "/api/v1/dam/",
  credentials: 'include'
 });
const dam_client = new ApolloClient({
  link: restLink_dam,
  cache: new InMemoryCache(),
});

const restLink_lms = new HttpLink({ 
  uri: "/api/v1/lms/",
  credentials: 'include'
});
const lms_client = new ApolloClient({
  link: restLink_lms,
  cache: new InMemoryCache(),
});



const GET_SEARCH_CURSE = gql`
  query getItens($search: String!){
    search_courses_collections(search:$search, type: "online") {
      courses{
        id, title, thumbnail
      }
    }
  }
`;

const GET_SEARCH_ASSET = gql`
  query getAssets($search: String!){
    search_assets_collections(search: $search) {
      assets{
        id, title, asset, thumbnail
      }
    }
  }
`;

const GET_SEARCH_VIDEO = gql`
  query getVideos($search: String!){
    videos_collections_search(search:$search){
      videos{
        id, title, thumbnail
      }
    }
  }
`;




var search_url = window.location.search.replace(window.location.hash, "");
const search_term = decodeURIComponent(search_url.replace("?s=", "").replace('+',' '));


class Search extends Component {
  createLegend(json) {
    var legend = [];
    for (var i = 0; i < json["names"].length; i++) {
      var type = "fa fa-circle text-" + json["types"][i];
      legend.push(<i className={type} key={i} />);
      legend.push(" ");
      legend.push(json["names"][i]);
    }
    return legend;
  }
  render() {
    
    const windowWidth = window.innerWidth;

    var settings_carousel = {
      autoplay: false,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: (windowWidth <= 987 ? 2 : 4),
      slidesToScroll: (windowWidth <= 987 ? 2 : 4)
    };
    return (
        

<div className="content">



        <div className="container">
          <Grid  className="rowCarousel">

          <Row>
            <Col md={12}>
                <h1>
                  Busca por "{search_term}"
                </h1>
            </Col>
          </Row>
          
              <Query
                client={lms_client}
                variables={{search: search_term}}
                query={GET_SEARCH_CURSE}
                >
                {({ loading, error, data }) => {
                  if (loading) return <div>Carregando...</div>;
                  if (error) return <div>Erro :(</div>;
                    console.log(data);

                    if(!data.search_courses_collections.courses.length) {
                      return <div></div>
                    }
                    
                    return (
                      <div>
                      <Row>
                        <Col md={12}>
                            <h2>
                              Cursos
                            </h2>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                        <Slider {...settings_carousel}>
                              { data.search_courses_collections.courses.map((item) => (
                                <div key={item.id} className="course-item">
                                  <a href={"/#/curso/"+item.id}>
                                  <img src={"/lms/assets/"+item.thumbnail} />
                                  <div className="title">{item.title}</div>
                                  </a>
                                </div>
                              ))}
                        </Slider>
                        </Col>
                      </Row>
                      </div>
                    )
              }}
              </Query>
        </Grid>



        <Grid  className="rowCarousel">
              <Query
                client={dam_client}
                variables={{search: search_term}}
                query={GET_SEARCH_ASSET}
                >
                {({ loading, error, data }) => {
                  if (loading) return <div>Carregando...</div>;
                  if (error) return <div>Erro :(</div>;
                    
                    if(!data.search_assets_collections.assets.length) {
                      return <div></div>
                    }

                    return (

                      
                      <div>
                        <Row>
                          <Col md={12}>
                              <h2>
                                Conteúdos digitais 
                              </h2>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                              <Slider {...settings_carousel}>
                                    { data.search_assets_collections.assets.map((item) => (
                                      <div className="media media_asset">
                                        <div>
                                          <h2>{item.title}</h2>
                                          <a href={"/dam/assets/"+item.asset} download target="_blank">
                                          <Button bsStyle="info" fill>
                                            Baixe aqui
                                          </Button>
                                          </a>
                                        </div>
                                    </div>
                                    ))}
                              </Slider>
                          </Col>
                        </Row>
                      </div>
                    
                    )
                     
              }}
              </Query>
          </Grid>


          <Grid  className="rowCarousel">
              <Query
                client={lms_client}
                variables={{search: search_term}}
                query={GET_SEARCH_VIDEO}
                >
                {({ loading, error, data }) => {
                  if (loading) return <div>Carregando...</div>;
                  if (error) return <div>Erro :(</div>;
                    
                    if(!data.videos_collections_search.videos.length) {
                      return <div></div>
                    }

                    return (

                      
                      <div>
                        <Row>
                          <Col md={12}>
                              <h2>
                                Vídeos
                              </h2>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                              <Slider {...settings_carousel}>
                                    { data.videos_collections_search.videos.map((item) => (
                                      <div key={item.id} className="course-item">
                                        <a href={"/#/video/"+item.id+"/"}>
                                        <img src={item.thumbnail} />
                                        <div className="title">{item.title}</div>
                                        </a>
                                      </div>
                                    ))}
                              </Slider>
                          </Col>
                        </Row>
                      </div>
                    
                    )
                     
              }}
              </Query>
          </Grid>



        </div>
        </div>
    );
  }
}

export default Search;
