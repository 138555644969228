import React, { Component } from "react";

import { ApolloClient } from 'apollo-client';
import { Query } from "react-apollo";
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';
import Button from "../CustomButton/CustomButton.jsx";


const restLink_lms = new HttpLink({
   uri: "/api/v1/lms/",
   credentials: 'include'
  });
const lms_client = new ApolloClient({
  link: restLink_lms,
  cache: new InMemoryCache(),
});

const GET_PROOF = gql`
  query getProof($proof_id: Int!){
    proof(id: $proof_id) {
      minimum,
      questions {
        id
        title
        answers {
          id
          title
          is_correct
        }
      }
    }
  }`;

const ANSWER_QUESTION = gql`
  mutation addAnswerQuestion($answer_id: Int!, $question_id: Int!, $item_id: Int!, $item_type: String!){
    createAnswerQuestion(input: {answer_id: $answer_id, question_id: $question_id, item_id: $item_id, item_type: $item_type}) {
      id
    }
  }`;

  const FINISH_COURSE = gql`
  mutation addAnswerQuestion($course_id: Int!, $status: String!){
    updateCourseHasUser(input:{course_id:$course_id, status:$status}) {
      id
    }
  }`;

  const FINISH_TRAIL = gql`
  mutation addAnswerQuestion($trail_id: Int!, $status: String!){
    updateTrailHasUser(input:{trail_id:$trail_id, status:$status}) {
      id
    }
  }`;
  
  

export class ProofEvaluative extends Component {
  
  constructor() {
    super();
    this.state = {
      sendAnswers: false,
      msg: ""
    };

  }


  render() {

    return (
      <Query
        client={lms_client}
        query={GET_PROOF}
        variables={{proof_id: this.props.proof_id}}
        >
        {({ loading, error, data }) => {
          if (loading) return <div>Carregando...</div>;
          if (error) return <div>Erro :(</div>;
          
            var proof = data.proof;

            if(this.props.item_type == "trail") {
              var typeMutation = FINISH_TRAIL;
              var mutationVariables = {
                trail_id: this.props.item_id,
                status: "finalized"
              };
              var endMessage = "Parabéns! Você foi aprovado(a) na avaliação. Acesse no Menu > Cursos > Certificados das trilhas";
            } 
            else {
              var typeMutation = FINISH_COURSE;
              var mutationVariables = {
                course_id: this.props.item_id,
                status: "finalized"
              };
              var endMessage = "Parabéns! Você foi aprovado(a) na avaliação. Acesse no Menu > Cursos > Certificados dos cursos";
            }
            

            
            
          return (
            <div className="proof">
              
              {this.state.sendAnswers ?
              
                <h2>{this.state.msg}</h2>

              :
            
              <div>
                {
                  proof.questions.map((question) => (
                    <div className="proof_question" key={question.id}>
                      <b>{question.title}</b>
                      {
                        question.answers.map((answer) => (
                          <div className="proof_answer" key={answer.id}>
                            <input is_c={answer.is_correct} className="answer_item" type="radio" name={question.id} value={answer.id} /> {answer.title}
                          </div>
                        ))
                      }
                    </div>
                  ))
                }
                
                <Button bsStyle="info" onClick={() => {

                  var answers = document.getElementsByClassName("answer_item");
                  var count_correct = 0;

                  for (var i = 0; i < answers.length; i++) {
                    var answer = answers[i];
                    
                    if(answer.checked) {

                      var $question_id = answer.getAttribute("name");
                      var $answer_id = answer.getAttribute("value");
                      var $is_c = answer.getAttribute("is_c");
                      if($is_c === '1') {
                        count_correct++;
                      }
                      
                    };

                    
                  } 

                  if(count_correct < proof.minimum) {
                    
                    this.setState(state => ({
                      ...state,
                      sendAnswers: true,
                      msg: "Você não passou na avaliação, tente novamente depois!"
                    }));


                  } else {

                    
                    // Define o curso como finalizado
                    lms_client.mutate({
                      mutation: typeMutation,
                      variables: mutationVariables
                    }).then(
                      result => {
                        if(this.props.item_type == "course") {
                          this.props.setCourseFinish(result.data.updateCourseHasUser.id);
                        }
                        this.setState(state => ({
                          ...state,
                          sendAnswers: true,
                          msg: endMessage 
                        }));
                    });

                    // Salva as respostas
                    for (var i = 0; i < answers.length; i++) {
                      var answer = answers[i];
                      
                      if(answer.checked) {

                        var $question_id = answer.getAttribute("name");
                        var $answer_id = answer.getAttribute("value");

                        lms_client.mutate({
                          mutation: ANSWER_QUESTION,
                          variables: {
                            question_id: $question_id,
                            answer_id: $answer_id,
                            item_id: this.props.item_id,
                            item_type: this.props.item_type
                          }
                        });
                      };

                    } 
                  }
                  
                }}>
                Confirmar
              </Button>
              </div>
            }
          </div>
          );
        }}
        
        </Query>
    );
  }
}

export default ProofEvaluative;
