import Dashboard from "../layouts/Dashboard/Dashboard.jsx";
import LoginTemplate from "../layouts/Login/Login.jsx";

if(window.location.hash.indexOf("#/login") === 0 || window.location.hash.indexOf("#/register") === 0){// Verifica se está na página de auth
    var layout = LoginTemplate;
} else {
    var layout = Dashboard;
}

var indexRoutes = [
    { path: "/", name: "Index", component: layout }
];

export default indexRoutes;
