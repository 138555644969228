import React, { Component } from "react";

import {
  Grid,
  Row,
  Col,
  Alert,
  FormGroup,
  ControlLabel,
} from "react-bootstrap";

import { isMobile } from "react-device-detect";

import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import gql from "graphql-tag";
import { HttpLink } from "apollo-link-http";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import FooterZ from "../../components/Footer/FooterZ.jsx";
import HeaderM from "../../components/Header/HeaderM.jsx";

import capa from "../../assets/img/capa2.png";
import fundobranco from "../../assets/img/Fundo-claro_1.png";
import logo from "../../assets/img/Zenite_Branco.svg";

const restLink_account = new HttpLink({
  credentials: "include",
  uri: "/api/v1/account/",
});
const account_client = new ApolloClient({
  link: restLink_account,
  cache: new InMemoryCache(),
});

const LOGIN = gql`
  query login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      id
      token
      error_login_msg
    }
  }
`;

class Login extends Component {
  state = {
    email: null,
    password: null,
    msg: null,
    passwordShown: false,
    msg_type: "info",
  };

  togglePasswordVisiblity = () => {
    this.setState({
      passwordShown: this.state.passwordShown ? false : true,
    });
  };

  login = async () => {
    const user = (
      await account_client.query({
        variables: { email: this.state.email, password: this.state.password },
        query: LOGIN,
        fetchPolicy: "no-cache",
      })
    ).data.login;
    if (user.token !== null) {
      document.cookie = "token_user=" + user.token; // Grava o coookie
      // this.setState({
      //   msg_type: "info",
      //   msg: "Login feito com sucesso, aguarde, você será redirecionado."
      // });
      window.location.replace("/");
    } else {
      this.setState({
        msg_type: "warning",
        msg: user.error_login_msg,
      });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.login();
  };

  //onChange_email = email => this.setState({ email })

  onChange_email = (event) => {
    this.setState({ email: event.target.value });
  };
  onChange_password = (event) => {
    this.setState({ password: event.target.value });
  };

  render() {
    var settings_banner = {
      autoplaySpeed: 5000,
      autoplay: true,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      <div>
        {!isMobile && (
          <div
            style={{
              background: `url(${capa}) no-repeat`,
              backgroundSize: "cover",
            }}
          >
            <div className="login-img-zenite container">
              <div className="container  login-img-zenite-logo">
                <img src={logo} />
              </div>
              <div>
                <div className="login-box-header login-box-header-color2">
                  {" "}
                  <div className="login-box-text ">FAÇA SEU LOGIN</div>
                </div>
                <div className="login-box">
                  <form
                    onSubmit={(e) => {
                      this.handleSubmit(e);
                      e.preventDefault();
                    }}
                  >
                    <FormInputs
                      ncols={["col-md-12 input-zenite"]}
                      proprieties={[
                        {
                          onChange: this.onChange_email,
                          name: "email",

                          type: "email",
                          bsClass: "form-control",
                          placeholder: "E-mail",
                          defaultValue: "",
                        },
                      ]}
                    />
                    <div className="form-group input-zenite password-zenite">
                      <input
                        onChange={this.onChange_password}
                        value={this.state.password}
                        name="password"
                        placeholder="Senha"
                        type={this.state.passwordShown ? "text" : "password"}
                        class="form-control"
                      ></input>
                      <i
                        onClick={this.togglePasswordVisiblity}
                        className="slider-icon pe-7s-leaf"
                      ></i>
                    </div>

                    <div className="text-left text-password-zenite">
                      <a href="/#/recupear-senha">Esqueceu sua senha?</a>
                    </div>

                    <Row>
                      <Col md={12}>
                        <Button
                          bsStyle="info"
                          className={
                            this.state.email
                              ? "btn-zenite active-login"
                              : "btn-zenite"
                          }
                          fill
                          type="submit"
                        >
                          Acessar
                          <i className="slider-icon pe-7s-check" />
                        </Button>
                      </Col>

                      {this.state.msg && (
                        <Col md={10}>
                          <Alert bsStyle={this.state.msg_type}>
                            {this.state.msg}
                          </Alert>
                        </Col>
                      )}
                    </Row>
                  </form>
                </div>
                {/*
                  <div className="login-box-footer">Ainda não se cadastrou? <a>Clique aqui</a></div>
                   */}
              </div>
            </div>
          </div>
        )}

        {isMobile && (
          <div className="mobile-zenite">
            <HeaderM />
            <div
              style={{
                background: `url(${fundobranco}) no-repeat`,
                backgroundSize: "cover",
              }}
              className="login-img-zenite container"
            >
              <div className="box-zenite my-3">
                <div className="login-box-header login-box-header-color1">
                  {" "}
                  <div className="login-box-text">100% ONLINE E AO VIVO</div>
                </div>
                <div className="login-box">
                  <form
                    onSubmit={(e) => {
                      this.handleSubmit(e);
                      e.preventDefault();
                    }}
                  >
                    <FormInputs
                      ncols={["col-md-12 input-zenite"]}
                      proprieties={[
                        {
                          onChange: this.onChange_email,
                          name: "email",

                          type: "email",
                          bsClass: "form-control",
                          placeholder: "E-mail",
                          defaultValue: "",
                        },
                      ]}
                    />
                    <div className="form-group input-zenite password-zenite">
                      <input
                        onChange={this.onChange_password}
                        value={this.state.password}
                        name="password"
                        placeholder="Senha"
                        type={this.state.passwordShown ? "text" : "password"}
                        class="form-control"
                      ></input>
                      <i
                        onClick={this.togglePasswordVisiblity}
                        className="slider-icon pe-7s-leaf"
                      ></i>
                    </div>

                    <div className="text-left text-password-zenite">
                      <a href="/#/recupear-senha">Esqueceu sua senha?</a>
                    </div>
                    <Row>
                      <Col md={12}>
                        <Button
                          bsStyle="info"
                          className="btn-zenite"
                          fill
                          type="submit"
                        >
                          Acessar
                          <i className="slider-icon pe-7s-check" />
                        </Button>
                      </Col>
                      {this.state.msg && (
                        <Col md={10}>
                          <Alert bsStyle={this.state.msg_type}>
                            {this.state.msg}
                          </Alert>
                        </Col>
                      )}
                    </Row>
                  </form>
                </div>
              </div>
              {/*
                  <div className="login-box-footer">Ainda não se cadastrou? <a>Clique aqui</a></div>
                 */}
            </div>
          </div>
        )}

        <FooterZ />
      </div>
    );
  }
}

export default Login;
