import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import { Grid, Row, Col } from "react-bootstrap";


import { NavItem, Nav, NavDropdown, MenuItem } from "react-bootstrap";


import { Query } from "react-apollo";
import gql from "graphql-tag";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';


import { Card } from "../../components/Card/Card.jsx";
import { StatsCard } from "../../components/StatsCard/StatsCard.jsx";
import { Tasks } from "../../components/Tasks/Tasks.jsx";



const restLink_lms = new HttpLink({
   uri: "/api/v1/lms/",
   credentials: 'include'
   });
const lms_client = new ApolloClient({
  link: restLink_lms,
  cache: new InMemoryCache(),
});


const GET_CURSES = gql`{
  courses_favorites_by_user {
    UserFavoriteCourse {
      id
      course {
        id
        title
        thumbnail
      }
    }
  }
}
`;



class Favorites extends Component {

  
  createLegend(json) {
    var legend = [];
    for (var i = 0; i < json["names"].length; i++) {
      var type = "fa fa-circle text-" + json["types"][i];
      legend.push(<i className={type} key={i} />);
      legend.push(" ");
      legend.push(json["names"][i]);
    }
    return legend;
  }

  render() {
    
   
    return (
        

<div className="content">
<div className="container">
        

        <Grid fluid>
          <Row>
            

              <Query
                client={lms_client}
                query={GET_CURSES}
              >
                {({ loading, error, data }) => {
                  if (loading) return <div>Carregando...</div>;
                  if (error) return <div>Erro :(</div>;
                    
                    if(!data.courses_favorites_by_user.UserFavoriteCourse || !data.courses_favorites_by_user.UserFavoriteCourse.length){
                      return <Col lg={12}><h1>Nenhum curso adicionado aos favoritos.</h1></Col>
                    }

                  return data.courses_favorites_by_user.UserFavoriteCourse.map((item) => (
                    
                    <Col lg={4} sm={6} className="col" key={item.id}>
                    <div className="media">
                      <a 
                      data-gtm-type="click" 
                      data-gtm-clicktype="link" 
                      data-gtm-name={item.course.title}
                      href={"/#/curso/"+item.course.id} >
                        <img src={"/lms/assets/"+item.course.thumbnail} />
                      <div>{item.course.title}</div>
                      </a>
                    </div>
                    </Col>
      ));
      }}
      </Query>

      </Row>
      </Grid>
      </div>
      </div>
    );
  }
}

export default Favorites;
