import React, { Component, useState } from "react";
import {
  Grid,
  Row,
  Col,
  Alert,
  Table,
  FormGroup,
  ControlLabel
} from "react-bootstrap";

import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

import { isMobile } from 'react-device-detect';

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

import ReactLoading from 'react-loading';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; //

import Modal from 'react-awesome-modal';







const restLink_account = new HttpLink({ credentials: 'include', uri: "/api/v1/account/" });
const account_client = new ApolloClient({
  link: restLink_account,
  cache: new InMemoryCache(),
});


const GET_LAWSUIT_SCHEDULE = gql`
query LawsuitSchedules_collections($lawsuit_id: Int!){
  LawsuitSchedules_collections(lawsuit_id:$lawsuit_id, limit:2000, order: "ASC", orderby: "datetime_start"){
      lawsuitSchedules{
        id
        title
        teacher{
          id
          name
          description
          thumbnail
        }
        datetime_format_br
      }
    }
  }
    
`;


const GET_TEACHER = gql`
  query question_current($lawsuit_id: Int!){
    teachers_collections(lawsuit_id: $lawsuit_id, limit: 9999) {
        teachers{
          id
          name
          description
          thumbnail
        }
      }
    }
    
`;


class LawsuitScheduleList extends Component {

  state = {
    teachers: [],
    lawsuit_schedule: []
  }


  getVars = async () => {

    if (this.props.lawsuit_id) {

      const lawsuit_schedule = (await account_client.query({
        variables: {
          lawsuit_id: this.props.lawsuit_id
        },
        query: GET_LAWSUIT_SCHEDULE,
        fetchPolicy: 'no-cache'
      })).data.LawsuitSchedules_collections.lawsuitSchedules;

      if (lawsuit_schedule.length > 0) {

        var teachers_ids = [];
        var teachers = [];
        lawsuit_schedule.map((item) => {
          if (!teachers_ids.includes(item.teacher.id)) {
            teachers_ids.push(item.teacher.id);
            teachers.push(item.teacher);
          }
        });
        this.setState({ lawsuit_schedule, teachers });

      }
      // Se não tem agendamento, pega os professores no formato antigo
      else {
        const teachers = (await account_client.query({
          variables: {
            lawsuit_id: this.props.lawsuit_id
          },
          query: GET_TEACHER,
          fetchPolicy: 'no-cache'
        })).data.teachers_collections.teachers;
        this.setState({ teachers });
      }


    }


  }


  componentDidMount() {
    this.getVars();
    console.log(this.state.view);

  }

  render() {



    return (

      <div>


        {(this.props.viewState === "all" || this.props.viewState === "teacher") && this.state.teachers.length > 0 &&
          <div className="teacher-zenite">
            <div className="content">
              <div className="container">
                <div className="">
                  <h3 name="teacher">Professores</h3>
                  {this.state.teachers.map((item) => (
                    <Row key={item.id}>
                      <Col className="text-center" lg={2} md={2} xs={12}>
                        {
                          item.thumbnail &&
                          <img src={"/account/assets/" + item.thumbnail} width={!isMobile ? 150 : 70} />
                        }

                      </Col>
                      <Col lg={10} md={10} xs={12}>
                        <p style={!isMobile ? {} : { fontSize: 16 }} ><strong>{item.name}</strong></p>
                        <p style={!isMobile ? {} : { fontSize: 12, lineHeight: 1.5 }}>{item.description}</p>
                      </Col>
                    </Row>
                  ))}
                </div>
              </div>
            </div>
          </div>
        }

        {(this.props.viewState === "all" || this.props.viewState === "schedule") && this.props.isSchedule === 1 &&
          this.state.lawsuit_schedule.length > 0 &&
          <div className="teacher-zenite schedule-zenite">
            <div className="content">
              <div className="container">
                <div className=" ">
                  <h3 name="schedule">Programação</h3>

                  {this.state.lawsuit_schedule.map((item) => (
                    <Row key={item.id} className="border-bottom pb-4" style={!isMobile ? {} : { paddingTop: 40 }}>

                      <Col md={1} xs={3}>
                        {
                          item.teacher.thumbnail &&
                          <img src={"/account/assets/" + item.teacher.thumbnail} width={80} />
                        }
                      </Col>
                      <Col md={4} xs={9}>
                        <p style={!isMobile ? {} : { fontSize: 16 }}>
                          {item.teacher.name}
                        </p>
                      </Col>
                      <Col md={4} xs={9}>
                        <p style={!isMobile ? {} : { fontSize: 16 }}>
                          {item.title}
                        </p>
                      </Col>

                      {
                          !isMobile &&
                      <Col md={3} xs={12}>
                      
                        <h4>Ao Vivo</h4>
                        <p style={!isMobile ? {} : { fontSize: 16 }}>
                          {item.datetime_format_br}
                        </p>
                     
                      </Col>

                       }
                        {
                          isMobile &&
                      <Col md={6} xs={12}>
                      
                        
                        <p style={{ fontSize: 14, fontWeight: "bold", textAlign: "center", marginTop: 20 }}>
                          Ao vivo em {item.datetime_format_br}
                        </p>
                     
                      </Col>
                       }
                    </Row>
                  ))}
                </div>
              </div>
            </div>
          </div>
        }

      </div>

    );
  }
}

export default LawsuitScheduleList;
