import React, { Component } from "react";

import ApolloClient from "apollo-boost";
import gql from "graphql-tag";
import { Grid, Row, Col } from "react-bootstrap";


const lms_client = new ApolloClient({
  uri: "/api/v1/lms/"
});

const GET_FAVORITE_COURSE = gql`
  query favoriteCourse($course_id: Int!) {
    course_favorite_by_user(course_id: $course_id) {
      id
    }
  }
`;

const GET_LIKE_COURSE = gql`
  query likeCourse($course_id: Int!) {
    course_like_by_user(course_id: $course_id) {
      id
    }
  }
`;

const TOGGLE_LIKE_COURSE = gql`
  mutation insertLikeCourse($course_id: Int!) {
    toggleUserLikeCourse(input:{course_id:$course_id}){
      id
    }
  }
`;

const TOGGLE_FAVORITE_COURSE = gql`
  mutation insertFavoriteCourse($course_id: Int!) {
    toggleUserFavoriteCourse(input:{course_id:$course_id}){
      id
    }
  }
`;

export class SidebarCourse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      like: false,
      favorite: false
    };
  }
  
  componentDidMount() {

    lms_client.query({
        variables:{course_id: this.props.course.id },
        query: GET_FAVORITE_COURSE
    })
    .then(
      result => {
        this.setState({
          favorite: (result.data.course_favorite_by_user ? true : false)
        });
      }
    );

    lms_client.query({
        variables:{course_id: this.props.course.id },
        query: GET_LIKE_COURSE
    })
    .then(
      result => {
        this.setState({
          like: (result.data.course_like_by_user ? true : false)
        });
      }
    );
  }

  setLike() {
    this.setState(state => ({
      ...state,
      like: !this.state.like
    }));

    lms_client.mutate({
        variables:{course_id: this.props.course.id },
        mutation: TOGGLE_LIKE_COURSE
    });
  }
  
  setFavorite() {
    this.setState(state => ({
      ...state,
      favorite: !this.state.favorite
    }));
    
    lms_client.mutate({
        variables:{course_id: this.props.course.id },
        mutation: TOGGLE_FAVORITE_COURSE
    });
  }

  minutes_with_leading_zeros(minutes) 
  { 
    return (minutes < 10 ? '0' : '') + minutes;
  }

  render() {
    
    return (
      <div className="sidebar-course">
        <Row>
          <Col md={4}>
            <div className="sidebar-course-item">
              <i className="pe-7s-look"></i>
              <div><span>{this.props.course.hours ? this.props.course.hours : 0}:{this.minutes_with_leading_zeros(this.props.course.minutes ? this.props.course.minutes : 0)}</span></div>
              <div>Tempo de Curso</div>
            </div>
          </Col>
          <Col md={4}>
            <div className={this.state.like ? 'active' : ''}>
              <div className="sidebar-course-item course-like " onClick={() => this.setLike()}>
                <i className="pe-7s-like2"></i>
                <div>Curtir conteúdo</div>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className={this.state.favorite ? 'active' : ''}>
              <div className="sidebar-course-item course-favorite" onClick={() => this.setFavorite()}>
                <i className="pe-7s-star"></i>
                <div>Adicionar aos favoritos</div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default SidebarCourse;
