import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import { Grid, Row, Col } from "react-bootstrap";

import Slider from "react-slick";


import { Query } from "react-apollo";
import gql from "graphql-tag";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';


import { Card } from "../../components/Card/Card.jsx";
import { StatsCard } from "../../components/StatsCard/StatsCard.jsx";
import { Tasks } from "../../components/Tasks/Tasks.jsx";
import {
  dataPie,
  legendPie,
  dataSales,
  optionsSales,
  responsiveSales,
  legendSales,
  dataBar,
  optionsBar,
  responsiveBar,
  legendBar
} from "../../variables/Variables.jsx";

import { NavItem, Nav, NavDropdown, MenuItem } from "react-bootstrap";

import banner_default from "../../assets/img/banner-medias.png";

const restLink_dam = new HttpLink({ 
  uri: "/api/v1/dam/",
  credentials: 'include'
});
const dam_client = new ApolloClient({
  link: restLink_dam,
  cache: new InMemoryCache(),
});


const restLink_lms = new HttpLink({ credentials: 'include', uri: "/api/v1/lms/" });
const lms_client = new ApolloClient({
  link: restLink_lms,
  cache: new InMemoryCache(),
});


const GET_TRAIL = gql`
query getTrails($category_id:Int!){
    trails_collections(category_id:$category_id){
      trails{
        id,title, thumbnail
      }
    }
  }
`;



const GET_TRAILITEMS = gql`
  query getItens($trail_id: Int!){
    trailitems(trail_id:$trail_id) {
      trailitems{
        item_id, item_type
      }
    }
  }
`;

const GET_CURSE = gql`
  query getCourse($course_id: Int!){
    course(id:$course_id){
      id, title, thumbnail
    }
  }
`;

const GET_ASSET = gql`
  query getAsset($dam_id: Int!){
    asset(id:$dam_id){
      id, title, thumbnail
    }
  }
`;

const GET_CATEGORIES = gql`{
  category_collections(parent:null){
    categories{
      id,title
    }
  }
}
`;

const GET_SUBCATEGORIES = gql`
query getCagegories($parent_id:Int!){
    category_collections(parent:$parent_id){
      categories{
        id,title
      }
    }
  }
`;

const GET_CATEGORY = gql`
  query getCategory($category_id: Int!){
    category(id:$category_id){
      banner
    }
  }
`;


class Dashboard extends Component {
  createLegend(json) {
    var legend = [];
    for (var i = 0; i < json["names"].length; i++) {
      var type = "fa fa-circle text-" + json["types"][i];
      legend.push(<i className={type} key={i} />);
      legend.push(" ");
      legend.push(json["names"][i]);
    }
    return legend;
  }
  render() {
    
    const windowWidth = window.innerWidth;
    this.category_id = (this.props.match.params.category_id ? this.props.match.params.category_id : 0);
    console.log("#####################");
    console.log(this.category_id);

    var settings_courses = {
      autoplay: false,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: (windowWidth <= 987 ? 2 : 4),
      slidesToScroll: (windowWidth <= 987 ? 2 : 4)
    };
    return (
        <div>
          <Query
        client={dam_client}
        query={GET_CATEGORY}
        variables={{category_id:this.category_id}}
        >
        {({ loading, error, data }) => {
          if (loading) return <div>Carregando...</div>;
          if (error) return <div>Erro :(</div>;
          
          return (
            <img className="banner_content" src={data.category && data.category.banner ? "/dam/assets/"+data.category.banner : banner_default} />
            );
          }}
        </Query>

<div className="content">
<div className="container">
<Grid fluid>
          <Row>
<div className="contentMenu">
        
        <Nav>
        <NavItem
        data-gtm-type="click" 
        data-gtm-clicktype="submenu" 
        data-gtm-name="Todos"
        href={"/#/trilhas/"}>Todos</NavItem>
        <Query
          client={dam_client}
          query={GET_CATEGORIES}
        >
          {({ loading, error, data }) => {
            if (loading) return <div>Carregando...</div>;
            if (error) return <div>Erro :(</div>;
              
              if(!data.category_collections.categories) {
                return (<div>(Vazio)</div>);
              } 

            return data.category_collections.categories.map((categ) => (
              
              <NavDropdown
                eventKey={2}
                title={categ.title}
                id="basic-nav-dropdown-right"
              >
                    <Query
                          client={dam_client}
                          query={GET_SUBCATEGORIES}
                          variables={{parent_id:categ.id}}
                        >
                          {({ loading, error, data }) => {
                            if (loading) return <div>Carregando...</div>;
                            if (error) return <div>Erro :(</div>;
                              
                              if(!data.category_collections) {
                                return (<div>(Vazio)</div>);
                              } 

                            return data.category_collections.categories.map((sub_categ) => (
                              <NavItem 
                              data-gtm-type="click" 
                              data-gtm-clicktype="submenu" 
                              data-gtm-name={sub_categ.title}
                              eventKey={sub_categ.id} href={"/#/trilhas/"+sub_categ.id}>{sub_categ.title}</NavItem>
                            ));
                        }}
                      </Query>
              </NavDropdown>
          ));
        }}
      </Query>
        </Nav>
      </div>
      </Row>
      </Grid>
<Row>
<Query
  client={lms_client}
  query={GET_TRAIL}
  variables={{category_id:this.category_id}}
  >
  {({ loading, error, data }) => {
    if (loading) return <tr><td>Carregando...</td></tr>;
    if (error) return <tr><td>Erro :(</td></tr>;
      
      return data.trails_collections.trails.map((item_trail) => (
          
            <Col md={4}>
              <Query
                client={lms_client}
                variables={{trail_id:item_trail.id}}
                query={GET_TRAILITEMS}
                >
                {({ loading, error, data }) => {
                  if (loading) return <tr><td>Carregando...</td></tr>;
                  if (error) return <tr><td>Erro :(</td></tr>;
                    
                    const item = data.trailitems.trailitems[0];
                    if(!item) {
                      return <div></div>;
                    }

                    return (
                              <div>
                              {item.item_type == "course" ?

                              <Query
                                  client={lms_client}
                                  variables={{course_id:item.item_id}}
                                  query={GET_CURSE}
                                  >
                                  {({ loading, error, data }) => {
                                    if (loading) return <tr><td>Carregando...</td></tr>;
                                    if (error) return <tr><td>Erro :(</td></tr>;
                                      
                                      var course = data.course;
                                      return (
                                        <div key={course.id} className="course-item">
                                          <a 
                                          data-gtm-type="click" 
                                          data-gtm-clicktype="link" 
                                          data-gtm-name={item_trail.title}
                                          href={"/#/trail/"+course.id+"/"+item.item_type+"/"+item_trail.id}>
                                          <img src={"/lms/assets/"+(item_trail.thumbnail ? item_trail.thumbnail : course.thumbnail)} />
                                          <div className="title">{item_trail.title}</div>
                                          </a>
                                        </div>
                                  )
                                }}
                              </Query>

                              :
                                  <Query
                                  client={dam_client}
                                  variables={{dam_id:item.item_id}}
                                  query={GET_ASSET}
                                  >
                                  {({ loading, error, data }) => {
                                    if (loading) return <tr><td>Carregando...</td></tr>;
                                    if (error) return <tr><td>Erro :(</td></tr>;
                                      
                                      var asset = data.asset;

                                      return (
                                        <div key={asset.id} className="course-item">
                                          <a 
                                          data-gtm-type="click" 
                                          data-gtm-clicktype="link" 
                                          data-gtm-name={item_trail.title}
                                          href={"/#/trail/"+asset.id+"/"+item.item_type+"/"+item_trail.id}>
                                          <img src={"/lms/assets/"+(item_trail.thumbnail ? item_trail.thumbnail : asset.thumbnail)} />
                                          <div className="title">{item_trail.title}</div>
                                          </a>
                                        </div>
                                  )
                                }}
                              </Query>
                              }

                            </div>
                            
                    )
              }}
              </Query>
            </Col>
        
      ));
      }}
      </Query>
      </Row>
      </div>
      </div>
      </div>
    );
  }
}

export default Dashboard;
