import React, { Component, useState } from "react";
import {
  Grid,
  Row,
  Col,
  Alert,
  Table,
  FormGroup,
  ControlLabel,
} from "react-bootstrap";

import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import gql from "graphql-tag";
import { HttpLink } from "apollo-link-http";

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

import ReactLoading from "react-loading";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; //

import Modal from "react-awesome-modal";

import ReactMarkdown from "react-markdown";
import htmlParser from "react-markdown/plugins/html-parser";

const restLink_form = new HttpLink({
  credentials: "include",
  uri: "/api/v1/forms/",
});
const form_client = new ApolloClient({
  link: restLink_form,
  cache: new InMemoryCache(),
});

const restLink_account = new HttpLink({
  credentials: "include",
  uri: "/api/v1/account/",
});
const account_client = new ApolloClient({
  link: restLink_account,
  cache: new InMemoryCache(),
});

const SEND_ALL_VOTES_AND_CONFIRM = gql`
  mutation vote($all_votes: String!) {
    send_all_votes_and_confirm(all_votes: $all_votes) {
      id
    }
  }
`;

const GET_QUESTIONS = gql`
  query question_current($form_id: Int!) {
    questions_collections(form_id: $form_id) {
      questions {
        id
        title
        isMultipleAnswers
        group_name
        isText
        isClosed
        user_vote
        answers {
          id
          title
        }
      }
    }
  }
`;

class Questions extends Component {
  form_id;
  form_edit;
  loggedQuestionInterval;
  colors_btn = {
    default: "info",
    Sim: "success",
    Não: "danger",
    Abstenção: "warning",
  };
  answerTexts = []; // Respostas das questões abertas

  state = {
    visible: false,
    loading_page: false,
    end_question: false,
    alertInfo: "",
    alertConfirm: "",
    datetimeStart: new Date(),
    datetimeEnd: null,
    question_current: false,
    VotesArray: [], // Para colorir os botoes dos votos já feitos
    VotesSend: [], // Para enviar os votos
  };

  constructor() {
    super();
  }

  onChange_datetimeStart = (datetimeStart) => this.setState({ datetimeStart });
  onChange_datetimeEnd = (datetimeEnd) => this.setState({ datetimeEnd });

  handleCheckboxChange = (e) => {
    this.setState((state) => ({
      ...state,
      checkboxValue_isActive: !this.state.checkboxValue_isActive,
    }));
  };

  handleCheckboxChange_showgrid = (e) => {
    this.setState((state) => ({
      ...state,
      checkboxValue_showgrid: !this.state.checkboxValue_showgrid,
    }));
  };

  componentDidMount() {}

  componentWillUnmount() {}

  async voteAction(question_current_id, answer_id) {
    var VotesArray = this.state.VotesArray;
    var VotesSend = this.state.VotesSend;

    // Alterar o state apenas do voto selecionado
    var key_item_vote = question_current_id;
    VotesArray[key_item_vote] = answer_id;
    VotesSend.push({
      question_current_id: question_current_id,
      answer_id: answer_id,
    });
    this.setState((state) => ({
      ...state,
      VotesArray,
      VotesSend,
    }));
  }
  // Multiplas respostas
  async addVoteAction(question_current_id, answer_id) {
    var VotesArray = this.state.VotesArray;
    var VotesSend = this.state.VotesSend;

    // Alterar o state apenas do voto selecionado
    var key_item_vote = question_current_id;
    if (typeof VotesArray[key_item_vote] !== "undefined") {
      // Faz o Toggle
      if (VotesArray[key_item_vote].includes(answer_id)) {
        VotesArray[key_item_vote].splice(
          VotesArray[key_item_vote].indexOf(answer_id),
          1
        );
        let indexVotesSend = VotesSend.indexOf({
          question_current_id: question_current_id,
          answer_id: answer_id,
        });
        VotesSend.splice(indexVotesSend, 1);
      } else {
        VotesArray[key_item_vote].push(answer_id);
        VotesSend.push({
          question_current_id: question_current_id,
          answer_id: answer_id,
        });
      }
    } else {
      VotesArray[key_item_vote] = [answer_id];
      VotesSend.push({
        question_current_id: question_current_id,
        answer_id: answer_id,
      });
    }

    console.log("#################################");
    console.log("VotesArray", VotesArray);
    console.log("VotesSend", VotesSend);

    this.setState((state) => ({
      ...state,
      VotesArray,
      VotesSend,
    }));
  }

  onChange_answerText = (event) => {
    this.answerTexts[event.target.name] = event.target.value;
  };

  async confirmVotesAction() {
    try {
      // Se o usuário alterar algum voto, ele não é sobrescrito, é apenas adicionado novamente,
      // então utiliza a função reverse para considerar apenas o ultimo
      var VotesSend = this.state.VotesSend.reverse();

      // Adiciona as resposta de texto, se tiver
      if (this.answerTexts.length) {
        this.answerTexts.map((v, k) => {
          VotesSend.push({
            question_current_id: k,
            answer_text: v,
          });
        });
      }

      var votes_string = JSON.stringify(VotesSend);

      this.setState({
        loading_page: true,
      });

      await form_client.mutate({
        mutation: SEND_ALL_VOTES_AND_CONFIRM,
        variables: {
          all_votes: votes_string,
        },
      });

      this.setState((state) => ({
        ...state,
        loading_page: false,
        end_question: true,
        alertConfirm: "Obrigado pela sua resposta.",
        ["confirm_votes_" + this.state.question_current.id]: true,
      }));
    } catch (err) {
      console.error(err);
    }
  }

  // Verfica se o voto já foi feito, para colocar a cor adequada no botão
  getClassBtn(answer, question_id, isMultipleAnswers = false) {
    var keyVote = question_id;
    if (isMultipleAnswers) {
      if (
        typeof this.state.VotesArray[keyVote] != "undefined" &&
        this.state.VotesArray[keyVote].includes(answer.id)
      ) {
        return this.colors_btn[answer.title]
          ? this.colors_btn[answer.title]
          : "info";
      }
    } else {
      if (
        typeof this.state.VotesArray[keyVote] != "undefined" &&
        this.state.VotesArray[keyVote] == answer.id
      ) {
        return this.colors_btn[answer.title]
          ? this.colors_btn[answer.title]
          : "info";
      }
    }

    return "default";
  }

  openModal() {
    this.setState({
      visible: true,
    });
  }

  closeModal() {
    this.setState({
      visible: false,
    });
  }

  render() {
    const parseHtml = htmlParser({
      isValidNode: (node) => node.type !== "script",
      processingInstructions: [
        /* ... */
      ],
    });

    var titleButton = "Avaliação";
    if (typeof this.props.title !== "undefined" && this.props.title) {
      titleButton = this.props.title;
    }

    return (
      <div>
        <Button
          className="btn-absolute-bottom btn btn-fill btn-info btn-zenite-text btn-chat"
          fill
          onClick={() => this.openModal()}
        >
          {titleButton}
        </Button>

        <Modal
          visible={this.state.visible}
          width="80%"
          height="90%"
          effect="fadeInUp"
          onClickAway={() => this.closeModal()}
        >
          <div className="content content-modal">
            <Row>
              <Col md={12}>
                <Button bsStyle="info" fill onClick={() => this.closeModal()}>
                  FECHAR
                </Button>

                <h1>{this.props.form.title}</h1>
                <ReactMarkdown
                  source={this.props.form.description}
                  escapeHtml={false}
                  astPlugins={[parseHtml]}
                  linkTarget="_blank"
                />

                <div>
                  {!this.state.alertInfo.length || (
                    <div role="alert" className="alert alert-info">
                      <span>{this.state.alertInfo}</span>
                    </div>
                  )}
                  {!this.state.alertConfirm.length || (
                    <div role="alert" className="alert alert-info">
                      <span>{this.state.alertConfirm}</span>
                    </div>
                  )}

                  {this.state.end_question && (
                    <Alert bsStyle="info">
                      <div className="txt-center">
                        <strong>Clique no botão "Fechar"</strong>
                      </div>
                    </Alert>
                  )}

                  {!this.state.end_question && (
                    <div>
                      <Query
                        fetchPolicy="no-cache"
                        client={form_client}
                        query={GET_QUESTIONS}
                        variables={{
                          form_id: parseInt(this.props.form.id),
                        }}
                      >
                        {({ loading, error, data }) => {
                          if (loading) return <div>Carregando...</div>;
                          if (error)
                            return <div>Erro ao carregar a pergunta</div>;

                          var questions_collections =
                            data.questions_collections.questions;

                          // Verifica se tem alguma votação ativa
                          if (!questions_collections.length) {
                            return (
                              <div>
                                <Alert bsStyle="info">
                                  <div className="txt-center">
                                    <strong>
                                      Nenhuma pergunta ativa
                                      <br />
                                      Clique no botão "Sair da Votação"
                                    </strong>
                                  </div>
                                </Alert>
                              </div>
                            );
                          }

                          // Verifica se o usuário já votou
                          /*
                                            if(question_current.user_vote) {
                                              return <div>
                                                        <h1>{question_current.title}</h1>
                                                        <Alert bsStyle="info">
                                                          <div className="txt-center">
                                                            <strong>
                                                              Você já votou nessa pergunta<br />
                                                              Clique no botão "Sair da Votação"
                                                            </strong>
                                                          </div>
                                                        </Alert>
                                                      </div>
                                              
                                            }
                                            */

                          var questions_group = [];
                          var questions_group_names = [];
                          questions_collections.map((question_current) => {
                            var group_name = question_current.group_name
                              ? question_current.group_name
                              : "Geral";
                            if (
                              typeof questions_group[group_name] === "undefined"
                            ) {
                              questions_group[group_name] = [];
                            }
                            questions_group[group_name].push(question_current);
                            if (!questions_group_names.includes(group_name)) {
                              questions_group_names.push(group_name);
                            }
                          });

                          return questions_group_names.map(
                            (group_name, group_key) => (
                              <div key={group_key}>
                                <h1>{group_name}</h1>

                                {questions_group[group_name].map(
                                  (question_current) => (
                                    <div key={question_current.id}>
                                      {question_current &&
                                        !this.state.alertInfo && (
                                          <div>
                                            <div className="subtitle_container">
                                              <div className="subtitle">
                                                {question_current.title}{" "}
                                              </div>

                                              {// Se for pergunta aberta
                                              question_current.isText ? (
                                                <div className="margin-10">
                                                  <FormInputs
                                                    ncols={["col-md-12"]}
                                                    proprieties={[
                                                      {
                                                        name:
                                                          question_current.id,
                                                        label: "",
                                                        type: "text",
                                                        bsClass: "form-control",
                                                        placeholder:
                                                          "Sua resposta",
                                                        defaultValue: "",
                                                        onChange: this
                                                          .onChange_answerText,
                                                      },
                                                    ]}
                                                  />
                                                </div>
                                              ) : question_current.isMultipleAnswers ===
                                                1 ? (
                                                <div className="options_center">
                                                  {question_current.answers.map(
                                                    (answer) => (
                                                      <span
                                                        key={answer.id}
                                                        className="btnMargin"
                                                      >
                                                        <Button
                                                          bsStyle={this.getClassBtn(
                                                            answer,
                                                            question_current.id,
                                                            true
                                                          )}
                                                          fill
                                                          onClick={() =>
                                                            this.addVoteAction(
                                                              question_current.id,
                                                              answer.id
                                                            )
                                                          }
                                                        >
                                                          {answer.title}
                                                        </Button>
                                                      </span>
                                                    )
                                                  )}
                                                </div>
                                              ) : (
                                                <div className="options_center">
                                                  {question_current.answers.map(
                                                    (answer) => (
                                                      <span
                                                        key={answer.id}
                                                        className="btnMargin"
                                                      >
                                                        <Button
                                                          bsStyle={this.getClassBtn(
                                                            answer,
                                                            question_current.id
                                                          )}
                                                          fill
                                                          onClick={() =>
                                                            this.voteAction(
                                                              question_current.id,
                                                              answer.id
                                                            )
                                                          }
                                                        >
                                                          {answer.title}
                                                        </Button>
                                                      </span>
                                                    )
                                                  )}
                                                </div>
                                              )}
                                            </div>

                                            {this.state.loading_page && (
                                              <Alert bsStyle="info">
                                                <div className="txt-center">
                                                  <ReactLoading
                                                    type="spinningBubbles"
                                                    color="#fff"
                                                  />
                                                  <strong>
                                                    Registrando...
                                                  </strong>
                                                  <div className="loading_message">
                                                    Aguarde...
                                                  </div>
                                                </div>
                                              </Alert>
                                            )}

                                            {!this.state.alertConfirm
                                              .length || (
                                              <div
                                                role="alert"
                                                className="alert alert-info"
                                              >
                                                <span>
                                                  {this.state.alertConfirm}
                                                </span>
                                              </div>
                                            )}
                                          </div>
                                        )}
                                    </div>
                                  )
                                )}
                              </div>
                            )
                          ); // GET_QUESTIONS
                        }}
                      </Query>
                      {!this.state.loading_page && (
                        <Button
                          bsStyle="info"
                          fill
                          onClick={() => this.confirmVotesAction()}
                        >
                          CONFIRMAR
                        </Button>
                      )}
                    </div>
                  )}

                  <div className="clearfix" />
                </div>
              </Col>
            </Row>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Questions;
