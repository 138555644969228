import React, { Component, useState } from "react";
import {
  Grid,
  Row,
  Col,
  Alert,
  Table,
  FormGroup,
  ControlLabel
} from "react-bootstrap";

import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

import ReactLoading from 'react-loading';

import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; //

import Iframe from 'react-iframe';

const restLink_form = new HttpLink({ credentials: 'include', uri: "/api/v1/forms/" });
const form_client = new ApolloClient({
  link: restLink_form,
  cache: new InMemoryCache(),
});

const restLink_account = new HttpLink({ credentials: 'include', uri: "/api/v1/account/" });
const account_client = new ApolloClient({
  link: restLink_account,
  cache: new InMemoryCache(),
});

const SEND_ALL_VOTES_AND_CONFIRM = gql`
  mutation vote($all_votes: String!){
    send_all_votes_and_confirm(all_votes: $all_votes) {
      id
    }
  }
`;

const GET_FORM = gql`
  query getForm($form_id: Int!){
    form(id:$form_id){
      id
      title
      isActive
      datetimeStartFormat
      datetimeEndFormat
    }
  }
`;

const GET_CREDITORS = gql`query creditorHasRecovering_collection($lawsuit_id: Int!, $form_id: Int!){
  creditorHasRecovering_collection(lawsuit_id: $lawsuit_id, form_id: $form_id) {
    creditorHasRecovering{
      id
      scenario
      class_number
      value
      value_format
      creditor{
        id
        title
      }
      recovering{
        id
        title
      }
    }
  }
}
`;

const GET_QUESTION = gql`
  query question_current($form_id: Int!){
    question_current(form_id: $form_id) {
      id
      title
      isClosed
      user_vote
    }
  }
`;

const GET_ANSWERS = gql`
  query answers_collections($question_id: Int!){
    answers_collections(question_id: $question_id) {
      answers{
        id
        title
      }
    }
  }
`;

const GET_RECOVERING = gql`
  query recovering_collection($form_id: Int!){
    recovering_collection(form_id: $form_id, list_all:1) {
      recoverings{
        id
        title
      }
    }
  }
`;

class Form extends Component {

  form_id;
  form_edit;
  loggedQuestionInterval;
  colors_btn = {
    'default': 'info',
    'Sim': 'success',
    'Não': 'danger',
    'Abstenção': 'warning'
  };

  state = {
    loading_page: false,
    end_question: false,
    alertInfo: "",
    alertConfirm: "",
    datetimeStart: new Date(),
    datetimeEnd: null,
    question_current: false,
    VotesArray: [], // Para colorir os botoes dos votos já feitos
    VotesSend: [] // Para enviar os votos
  };

  constructor() {
    super();

    
  }

  onChange_datetimeStart = datetimeStart => this.setState({ datetimeStart })
  onChange_datetimeEnd = datetimeEnd => this.setState({ datetimeEnd })

  handleCheckboxChange = (e) => {
    this.setState(state => ({
      ...state,
      checkboxValue_isActive: !this.state.checkboxValue_isActive
    }));

  }

  handleCheckboxChange_showgrid = (e) => {
    this.setState(state => ({
      ...state,
      checkboxValue_showgrid: !this.state.checkboxValue_showgrid
    }));
  }


  componentDidMount() {
  }

  componentWillUnmount() {
  }
  
  async voteAction(question_current_id, answer_id, recovering) {

    var VotesArray = this.state.VotesArray;
    var VotesSend = this.state.VotesSend;
      
    // Alterar o state apenas do voto selecionado
    var key_item_vote = question_current_id+"_"+recovering.id;
    VotesArray[key_item_vote] = answer_id;
    VotesSend.push({
      'question_current_id': question_current_id, 
      'recovering_id': recovering.id, 
      'recovering_title': recovering.title, 
      'answer_id' : answer_id
    });
    this.setState(state => ({
      ...state,
      VotesArray,
      VotesSend,
      ["replicated_vote_"+recovering.id]: true
    }));

  
    
  }

  
  async confirmVotesAction() {

    try {

      // Se o usuário alterar algum voto, ele não é sobrescrito, é apenas adicionado novamente, 
      // então utiliza a função reverse para considerar apenas o ultimo
      var VotesSend = this.state.VotesSend.reverse(); 
    
      var votes_string = JSON.stringify(VotesSend);

      this.setState({
        loading_page: true
      });

      console.log('---------------------');
      console.log(votes_string);

      await form_client.mutate({
        mutation: SEND_ALL_VOTES_AND_CONFIRM,
        variables: {
          all_votes: votes_string
        }
      });


      this.setState(state => ({
        ...state,
        loading_page: false,
        end_question: true,
        alertConfirm: "Voto(s) confirmado(s), não será mais possível alterá-lo(s).",
        ["confirm_votes_"+this.state.question_current.id]: true
      }));

      confirmAlert({
        title: '',
        message: "Obrigado! Voto(s) confirmado(s), não será mais possível alterá-lo(s).",
        buttons: [
          {
            label: 'Fechar'
          }
        ]
      });

    } catch(err) {
      console.error(err)
    }
    
  }

  // Verfica se o voto já foi feito, para colocar a cor adequada no botão
  getClassBtn(answer, question_id, recovering_id) {
    var keyVote = question_id+"_"+recovering_id;
    if(typeof this.state.VotesArray[keyVote] != "undefined" && this.state.VotesArray[keyVote] == answer.id) {
      return (this.colors_btn[answer.title] ? this.colors_btn[answer.title] : "info");
    }
    return "default";
  }
  

  render() {


    if(this.props.match.params.id) {
      this.form_id = parseInt(this.props.match.params.id);
    } else {
      this.form_id = 0;
    }

    return (

      <Query
      client={form_client}
      query={GET_FORM}
      variables={{form_id:this.form_id}}
      >
      {({ loading, error, data }) => {
        if (loading) return <div>Carregando...</div>;
        if (error) return <div>Erro :(</div>;
          
          this.form_current = data.form;

        return (
          <div>

              <div className="content">

                <Grid fluid>
                  <Row>
                    <Col md={12}>
                    <NavLink
                        to="/forms"
                        className="btn btn-info btn-fill"
                      >
                      SAIR DA VOTAÇÃO
                    </NavLink>
                    
                      <Card
                        title={this.form_current.title}
                        content={
                          <div>
                            

                              {
                                !this.state.alertInfo.length ||
                                <div role="alert" className="alert alert-info"><span>{this.state.alertInfo}</span></div>
                              }
                              {
                                !this.state.alertConfirm.length ||
                                <div role="alert" className="alert alert-info"><span>{this.state.alertConfirm}</span></div>
                              }
                              
                              {
                                this.state.end_question &&
                                <Alert bsStyle="info">
                                  <div className="txt-center">
                                    <strong>
                                      Clique no botão "Sair da Votação"
                                    </strong>
                                  </div>
                                </Alert>
                              }

                              {
                                !this.state.end_question &&

                                    <Query
                                        fetchPolicy='no-cache'
                                        client={form_client}
                                        query={GET_QUESTION}
                                        variables={{
                                          form_id: parseInt(this.props.match.params.id)
                                        }}
                                        >
                                        {({ loading, error, data }) => {
                                          if (loading) return <div>Carregando...</div>;
                                          if (error) return <div>Erro ao carregar a pergunta</div>;

                                            var question_current = data.question_current;

                                            // Verifica se tem alguma votação ativa
                                            if(!question_current) {
                                              return <div>
                                                        <Alert bsStyle="info">
                                                          <div className="txt-center">
                                                            <strong>
                                                              Nenhuma pergunta ativa<br />
                                                              Clique no botão "Sair da Votação"
                                                            </strong>
                                                          </div>
                                                        </Alert>
                                                      </div>
                                            }

                                            // Verifica se o usuário já votou
                                            if(question_current.user_vote) {
                                              return <div>
                                                        <h1>{question_current.title}</h1>
                                                        <Alert bsStyle="info">
                                                          <div className="txt-center">
                                                            <strong>
                                                              Você já votou nessa pergunta<br />
                                                              Clique no botão "Sair da Votação"
                                                            </strong>
                                                          </div>
                                                        </Alert>
                                                      </div>
                                              
                                            }

                                          return  (
                                            <Query
                                                fetchPolicy='no-cache'
                                                client={account_client}
                                                query={GET_RECOVERING}
                                                variables={{
                                                  form_id: parseInt(this.props.match.params.id)
                                                }}
                                                >
                                                {({ loading, error, data }) => {
                                                  if (loading) return <div>Carregando...</div>;
                                                  if (error) return <div>Erro </div>;

                                                  return data.recovering_collection.recoverings.map((recovering) => (
                                                      <Query
                                                      fetchPolicy='no-cache'
                                                      client={form_client}
                                                      query={GET_ANSWERS}
                                                      variables={{question_id:question_current.id}}
                                                      >
                                                      {({ loading, error, data }) => {
                                                        if (loading) return <div>Carregando...</div>;
                                                        if (error) return <div>Erro ao carregar as opções de resposta</div>;

                                                          var answers_collections = data.answers_collections.answers;

                                                          // Fecha a pergunta se o Admin marcar para fechar, ou se o usuário clicar para confirmar todas as respostas
                                                          return (

                                                            <div>

                                                                {question_current && !this.state.alertInfo &&

                                                                  <div>

                                                                      <div className="subtitle_container" >
                                                                        
                                                                        <div className="subtitle">{question_current.title}</div>

                                                                        <Table striped hover>
                                                                            <tbody>
                                                                                <tr >
                                                                                    {answers_collections.map((answer) => (
                                                                                        <td key={answer.id}>
                                                                                          <Button 
                                                                                          bsStyle={this.getClassBtn(answer, question_current.id, recovering.id)}
                                                                                          fill 
                                                                                          onClick={() => this.voteAction(question_current.id, answer.id, recovering)}
                                                                                          >
                                                                                            {answer.title} 
                                                                                          </Button>
                                                                                        </td>
                                                                                    ))}
                                                                                </tr>
                                                                              </tbody>
                                                                          </Table>
                                                                      </div>

                                                                    {this.state.loading_page &&
                                                                      <Alert bsStyle="info">
                                                                        <div className="txt-center">
                                                                          <ReactLoading type="spinningBubbles" color="#fff" />
                                                                          <strong>Registrando...</strong>
                                                                          <div className="loading_message">
                                                                            Aguarde, isso pode demorar um pouco.
                                                                          </div>
                                                                        </div>
                                                                        
                                                                      </Alert>
                                                                    }
                                                                    {!this.state.loading_page &&
                                                                      <Button bsStyle="info" fill onClick={() => this.confirmVotesAction()}>
                                                                        CONFIRMAR VOTO
                                                                      </Button>
                                                                    }
                                                                    {
                                                                      !this.state.alertConfirm.length ||
                                                                      <div role="alert" className="alert alert-info"><span>{this.state.alertConfirm}</span></div>
                                                                    }
                                                                  </div>
                                                                }
                                                      </div>
                                                      );// GET_ANSWERS
                                                      }}
                                                    </Query>
                                                ));// GET_RECOVERING
                                              }}
                                            </Query>
                                          );// GET_QUESTION
                                        }}
                                      </Query>
                            }
                            
                            <div className="clearfix" />
                          </div>
                        }
                      />
                  
                      
                    </Col>
                    
                  </Row>
                </Grid>
              </div>
      </div>

      );
      }}
      </Query>
      
    );
  }
}

export default Form;
