import React, { Component, useState } from "react";
import {
  Grid,
  Row,
  Col,
  Alert,
  Table,
  FormGroup,
  ControlLabel
} from "react-bootstrap";

import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';


import 'react-confirm-alert/src/react-confirm-alert.css'; //



const restLink_form = new HttpLink({ credentials: 'include', uri: "/api/v1/forms/" });
const form_client = new ApolloClient({
  link: restLink_form,
  cache: new InMemoryCache(),
});



const ADD_TODO = gql`
  mutation createQuiz($lawsuit_id: Int!, $title: String!, $isActive: Int!, $isDefault: Int!, $isText: Int!, $answers: String!){
    createQuiz( lawsuit_id:$lawsuit_id, input:{title:$title, isActive:$isActive,  isDefault:$isDefault, isText:$isText, answers:$answers}){
      id
    }
  }
`;

class QuizAdminRegister extends Component {


  state = {
    
  };


  state = {
    alertInfo: "",
    alertInfo_updateQuestion: "",
    checkboxValue_isActive: false,
    checkboxValue_isText: false,
    checkboxValue_isDefault: true,
    show_answer_form : false,
    number_of_answers: 3
  }

  constructor() {
    super();
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  handleCheckboxChange = (e) => {
    this.setState(state => ({
      ...state,
      checkboxValue_isActive: !this.state.checkboxValue_isActive
    }));
  }
  
  handleCheckboxChange_isText = (e) => {
    this.setState(state => ({
      ...state,
      checkboxValue_isText: !this.state.checkboxValue_isText
    }));
  }
  
  handleCheckboxChange_isDefault = (e) => {
    this.setState(state => ({
      ...state,
      checkboxValue_isDefault: !this.state.checkboxValue_isDefault,
      show_answer_form: this.state.checkboxValue_isDefault
    }));
  }

  onChange_number_of_answers = (event) => {
    this.setState({number_of_answers: event.target.value});
  }

  
 
  getAnswers = () => {

    let answersForm = []

    for (let i = 1; i <= this.state.number_of_answers; i++) {
      answersForm.push(<FormInputs 
          key={i}
          ncols={["col-md-12"]}
          proprieties={[
            {
              name: "answer_"+i,
              label: "Opção de resposta "+i,
              type: "text",
              bsClass: "form-control",
              placeholder: "Opção de resposta "+i,
              defaultValue: ""
            }
          ]}
        />)
    }
    return answersForm;
  }
  

  render() {


    return (

          <div>
             <Card
                  //title=""
                  content={
                      <Mutation mutation={ADD_TODO} client={form_client}>
                      {(createQuiz, { data }) => (
                            <form
                            onSubmit={e => {
                              this.props.handleSubmit(e);
                              e.preventDefault();

                              var answers_string = "";
                              for (let i = 1; i <= this.state.number_of_answers; i++) {
                                var value = e.target.elements['answer_'+i].value;
                                if(value) {
                                  answers_string += "***"+(e.target.elements['answer_'+i].value);
                                }
                              }
                              console.log({ 
                                lawsuit_id: this.props.lawsuit.id,
                                title: e.target.elements.title.value,
                                isActive: 0,
                                isDefault: (e.target.elements.isDefault.checked ? 1 : 0),
                                isText: (e.target.elements.isText.checked ? 1 : 0),
                                answers: answers_string
                              });
                              createQuiz({variables: { 
                                lawsuit_id: this.props.lawsuit.id,
                                title: e.target.elements.title.value,
                                isActive: 0,
                                isDefault: (e.target.elements.isDefault.checked ? 1 : 0),
                                isText: (e.target.elements.isText.checked ? 1 : 0),
                                answers: answers_string
                              } });
                            }}
                            >

                            <FormInputs
                              ncols={["col-md-12"]}
                              proprieties={[
                                {
                                  name: "title",
                                  label: "Digite a pergunta",
                                  type: "text",
                                  bsClass: "form-control",
                                  placeholder: "Digite a pergunta",
                                  defaultValue: ""
                                }
                              ]}
                            />

                          <FormGroup>
                              <ControlLabel>
                                <input
                                  value="1"
                                  name="isText"
                                  type="checkbox"
                                  checked={this.state.checkboxValue_isText}
                                  onChange={this.handleCheckboxChange_isText}
                                />  Pergunta aberta
                              </ControlLabel>
                            </FormGroup>


                          <div className={this.state.checkboxValue_isText ? "hide" : ""}>
                            <FormGroup>
                              <ControlLabel>
                                <input
                                  value="1"
                                  name="isDefault"
                                  type="checkbox"
                                  checked={this.state.checkboxValue_isDefault}
                                  onChange={this.handleCheckboxChange_isDefault}
                                />  Opções "SIM" e "NÂO"
                              </ControlLabel>
                            </FormGroup>
                          </div>


                          <div className={this.state.show_answer_form && !this.state.checkboxValue_isText ? "" : "hide"}>

                            <FormInputs
                                ncols={["col-md-12"]}
                                proprieties={[
                                  {
                                    name: "number_of_answers",
                                    label: "Número de respostas",
                                    type: "number",
                                    bsClass: "form-control",
                                    placeholder: "Número de respostas",
                                    defaultValue: this.state.number_of_answers,
                                    onChange: this.onChange_number_of_answers
                                  }
                                ]}
                              />

                            <div>
                                <p><strong>Opções de resposta:</strong></p>
                                {this.getAnswers()}
                            </div>
                          </div>

                            <Row>
                              <Col md={2}>
                                <Button bsStyle="info" pullRight fill type="submit">
                                  Salvar
                                </Button>
                              </Col>
                            </Row>
                              <div className="clearfix" />
                        </form>
                      )}
                      </Mutation>
                  }
              />

          </div>
      

      
    );
  }
}

export default QuizAdminRegister;
