import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";

import { ApolloClient } from 'apollo-client';
import { Query } from "react-apollo";
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

import { Chat } from "../../components/Chat/Chat.jsx";
import { Proof } from "../../components/Proof/Proof.jsx";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";

//import { markdownToHTML} from 'markdown-draft-js';
import ReactMarkdown from 'react-markdown';
import htmlParser from 'react-markdown/plugins/html-parser';


const parse = require('html-react-parser');

const restLink = new HttpLink({ credentials: 'include', uri: "/api/v1/chat/" });
const chat_client = new ApolloClient({
  link: restLink,
  cache: new InMemoryCache(),
});

const restLink_dam = new HttpLink({ credentials: 'include', uri: "/api/v1/dam/" });
const dam_client = new ApolloClient({
  link: restLink_dam,
  cache: new InMemoryCache(),
});

const restLink_analytics = new HttpLink({ credentials: 'include', uri: "/api/v1/analytics/" });
const analytics_client = new ApolloClient({
  link: restLink_analytics,
  cache: new InMemoryCache(),
});


var $chat_id = false;
var $render_start = true;


const ANALYTICS_INSERT_CHAT_HAS_USER = gql`
  mutation createChatHasUser($chat_id: Int!) {
    createChatHasUser(input:{chat_id:$chat_id}) {
      id
    }
  }
`;

class Live extends Component {


  urlPlayer;
  background;

  constructor() {
    super();
    setInterval(this.setChatHasUser, 60000);
    
  }

  
  

  setChatHasUser() {

    if($chat_id) {
      analytics_client.mutate({
        variables:{chat_id:  $chat_id},
        mutation: ANALYTICS_INSERT_CHAT_HAS_USER
      });
    }
    
  }

  render() {

    $chat_id = parseInt(this.props.match.params.id);
    if($render_start) {
      $render_start = false;
      this.setChatHasUser();
    }

    const parseHtml = htmlParser({
      isValidNode: node => node.type !== 'script',
      processingInstructions: [/* ... */]
    })
    

    return (
      
      <Query
        client={chat_client}
        variables={{ id: this.props.match.params.id }}
        query={gql`
        query getChat($id: Int!) {
          chat(id:$id){
            id,
            title,
            description,
            liveid,
            quiz_id,
            reactionevaluation_id,
            thumbnail,
            background
          }
        }
        `}
      >
        {({ loading, error, data }) => {
          if (loading) return <div>Carregando...</div>;
          if (error) return <div>Erro :(</div>;
            

            this.background = {
              background: `url(/chat/assets/${data.chat.background})`
            };;

          return (

                <div className="content" style={this.background}>
                  <Grid fluid className="background-chat">
                    <Row>
                      <Col md={8}>
                        <div className="embed-responsive embed-responsive-16by9 card shadow-sm">
                            {parse(data.chat.liveid)}
                        </div>
                        
                        <h1>{data.chat.title}</h1>
                        {
                          data.chat.thumbnail &&
                          <h2><img src={"/chat/assets/"+data.chat.thumbnail} height="100" /></h2>
                        }
                       

                        <Tabs>
                          <TabList>
                            <Tab>Descrição</Tab>
                            <Tab>Arquivos</Tab>
                            {
                              data.chat.quiz_id &&
                              <Tab>Quiz</Tab>
                            }
                            {
                              data.chat.reactionevaluation_id &&
                              <Tab>Avaliação de reação</Tab>
                            }
                          </TabList>

                          <TabPanel>
                              <p>
                                <ReactMarkdown
                                    source={data.chat.description}
                                    escapeHtml={false}
                                    astPlugins={[parseHtml]}
                                    linkTarget="_blank"
                                  />
                              </p>
                          </TabPanel>
                          <TabPanel>
                          <Query
                            client={dam_client}
                            variables={{ id: data.chat.id }}
                            query={gql`
                            query getAssets($id: Int!) {
                              assets_collections(limit: 1000, order: "ASC", module_name: "AnexoSala", external_parent_id: $id) {
                                assets {
                                  id
                                  title
                                  asset
                                }
                              }
                            }
                            `}
                          >
                            {({ loading, error, data }) => {
                              if (loading) return <div>Carregando...</div>;
                              if (error) return <div>Erro :(</div>;
                                
                                if(!data.assets_collections.assets.length) {
                                  return (<h2>(Nennhum)</h2>);
                                }

                              return data.assets_collections.assets.map((item) => (
                                <div key={item.id}>
                                  <a className="btn btn-fill btn-info btn-list" href={"/dam/assets/"+item.asset} download>{item.title}</a>
                                </div>
                              ));
                            }}
                          </Query>
                            
                          </TabPanel>
                          
                            {
                              data.chat.quiz_id &&
                              <TabPanel>
                                <Proof item_id={data.chat.id} item_type="chat" proof_id={data.chat.quiz_id} />
                              </TabPanel>
                            }
                            {
                              data.chat.reactionevaluation_id &&
                              <TabPanel>
                                <Proof item_id={data.chat.id} item_type="chat" proof_id={data.chat.reactionevaluation_id} />
                              </TabPanel>
                            }
                        </Tabs>


                      </Col>
                      <Col md={4}>

                        <Chat chat_id={this.props.match.params.id} />

                      </Col>
                    </Row>

                  </Grid>
                </div>
        );
      }}
    </Query>
    );
  }
}

export default Live;
