import React, { Component } from "react";
import { Grid, Row, Col, Alert } from "react-bootstrap";

import { ApolloClient } from 'apollo-client';
import { Query, Mutation } from "react-apollo";
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";



const restLink_account = new HttpLink({
   uri: "/api/v1/account/",
   credentials: 'include'
});
const account_client = new ApolloClient({
  link: restLink_account,
  cache: new InMemoryCache(),
});

const restLink = new HttpLink({ 
  uri: "/api/v1/lms/",
  credentials: 'include'
 });
const lms_client = new ApolloClient({
  link: restLink,
  cache: new InMemoryCache(),
});

const ADD_TODO = gql`
  mutation createSubscription($user_id: Int!, $classroom_id: Int!){
      createSubscription(input:{user_id:$user_id, classroom_id:$classroom_id}) {
        id
      }
    }
`;

const GET_AUTH_USER = gql`
  {
    get_authenticated_user{
      id,
      name
    }
  }
`;

const DELETE_SUBSCRIPTION = gql`
  mutation deleteSubscription($id: Int!){
    deleteSubscription(id:$id) {
      id
    }
  }
`;

class CoursePage extends Component {


  user_auth;


  constructor() {
    super();
    this.state = {
      is_subscription: false
    };
    
  }

  handleSubmit = (event) => {
    event.preventDefault();
   
    this.setState({
      is_subscription: !this.state.is_subscription
    });

  }

  render() {



    return (
      
      <div id="CoursePage">

      <Query
          client={account_client}
          query={GET_AUTH_USER}
        >
          {({ loading, error, data }) => {
            if (loading) return <tr><td>Carregando...</td></tr>;
            if (error) return <tr><td>Erro :(</td></tr>;
              
            this.user_auth = data.get_authenticated_user;


            return (
      <Query
        client={lms_client}
        variables={{ id: this.props.match.params.id }}
        query={gql`
        query getClassroom($id: Int!) {
            classroom(id:$id){
              id, limit_registrations, course{title,description},address
            }
          }`}
      >
        {({ loading, error, data }) => {
          if (loading) return <div>Carregando...</div>;
          if (error) return <div>Erro :(</div>;
            
            this.classroom = data.classroom;

          return (
            <div>
                <div className="content">
                  <Grid fluid>

                  </Grid>

                  <Mutation mutation={ADD_TODO} client={lms_client}>
                    {(createSubscription, { data }) => (
                    <div className="content">
                      <Grid fluid>
                        <Row>
                          <Col md={12}>

                            <Card
                              title={this.classroom.course.title}
                              content={
                                <form 
                                onSubmit={e => {
                                  this.handleSubmit(e);
                                  e.preventDefault();
                                  createSubscription({variables: { 
                                    classroom_id: this.classroom.id,
                                    user_id: this.user_auth.id
                                  } });
                                  console.log("inscrever");
                                }}
                                >

                                <Query
                                    client={lms_client}
                                    fetchPolicy='no-cache'
                                    variables={{ classroom_id: this.classroom.id }}
                                    query={gql`
                                    query getClassroom($classroom_id: Int!) {
                                      subscription(classroom_id:$classroom_id) {
                                        subscription{
                                          id
                                          user_id
                                        }
                                      }
                                    }
                                    `}
                                  >
                                    {({ loading, error, data }) => {
                                      if (loading) return <div>Carregando...</div>;
                                      if (error) return <div>Erro :(</div>;
                                        
                                        this.num_subscription = 0;
                                        var is_subscription = false;
                                        var subscription_item = null;

                                        if(data.subscription) {
                                          this.num_subscription = data.subscription.subscription.length;
                                          // Verifica se o usuário está na lista de inscritos
                                          var user_auth = this.user_auth;
                                          data.subscription.subscription.forEach(function(i){
                                            if(user_auth.id === i.user_id) {
                                              is_subscription = true;
                                              subscription_item = i;
                                            }
                                          });
                                        }
                                        

                                      return (
                                            <div>
                                              {this.state.is_subscription || is_subscription ?

                                                    <div>
                                                    <Row>
                                                      <Col md={12}>
                                                        <div>{this.classroom.course.description}</div>
                                                        <div>
                                                          <br />
                                                          {this.classroom.address}
                                                        </div>
                                                        <hr />
                                                        <Alert bsStyle="info" >
                                                          <span>Inscrito com sucesso</span>
                                                        </Alert>

                                                        {subscription_item && subscription_item.id &&
                                                          <Button
                                                          data-gtm-type="click" 
                                                          data-gtm-clicktype="button" 
                                                          data-gtm-name={"Cancelar Inscrição - "+this.classroom.course.title}
                                                          bsStyle="warning" fill onClick={() => {
                                                                
                                                                  // Delete subscription
                                                                  lms_client.mutate({
                                                                    mutation: DELETE_SUBSCRIPTION,
                                                                    variables: {
                                                                      id:subscription_item.id
                                                                    }
                                                                  }).then(
                                                                    result => {
                                                                      window.location.reload();
                                                                  });
                                                                    
                                                            }}>
                                                            Cancelar inscrição
                                                          </Button>
                                                        }
                                                        
                                                      
                                                      </Col>
                                                    </Row>

                                                    </div>

                                              :
                                                  <div>
                                                    <Row>
                                                      <Col md={12}>
                                                        <div>{this.classroom.course.description}</div>
                                                        
                                                        {this.classroom.limit_registrations-this.num_subscription > 0 ?
                                                          <div>
                                                            <div>
                                                              <br />
                                                              Local: {this.classroom.address}
                                                              </div>
                                                            <hr />
                                                            <div>
                                                              Número de vagas: {this.classroom.limit_registrations-this.num_subscription}
                                                            </div>
                                                            <br />
                                                            <Button 
                                                            data-gtm-type="click" 
                                                            data-gtm-clicktype="button" 
                                                            data-gtm-name={"Inscrição - "+this.classroom.course.title}
                                                            bsStyle="info" fill type="submit">
                                                                Inscrever
                                                            </Button>
                                                          </div>
                                                        :
                                                          <Alert bsStyle="warning" >
                                                            <span>Vagas esgotadas</span>
                                                          </Alert>
                                                        }
                                                      </Col>
                                                    </Row>
                                                    
                                                    <Row>
                                                      <Col md={4}>
                                                        
                                                      </Col>
                                                      <Col md={2}>
                                                        
                                                      </Col>
                                                    </Row>
                                                </div>
                                              }
                                         </div>
                                      );
                                    }}
                                </Query>

                               
                                  <div className="clearfix" />
                                </form>
                              }
                            />

                          
                          </Col>
                          
                        </Row>
                      </Grid>
                    </div>
                    )}
                    </Mutation>


                </div>
            
          </div>
        );
      }}
    </Query>

);

}}
</Query>


     

    </div>
    );
  }
}

export default CoursePage;
