import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import { Grid, Row, Col } from "react-bootstrap";


import { NavItem, Nav, NavDropdown, MenuItem } from "react-bootstrap";


import { Query } from "react-apollo";
import gql from "graphql-tag";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';

const months = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ']

const restLink_lms = new HttpLink({
   uri: "/api/v1/lms/",
   credentials: 'include'
});
const lms_client = new ApolloClient({
  link: restLink_lms,
  cache: new InMemoryCache(),
});


const GET_CURSES = gql`{
  classroom_collections(order: "DESC", orderby: "id"){
    classroom{
      id,datetime_start_format, datetime_end_format, course{thumbnail,title}
    }
  }
}
`;



class PresentialList extends Component {

  
  createLegend(json) {
    var legend = [];
    for (var i = 0; i < json["names"].length; i++) {
      var type = "fa fa-circle text-" + json["types"][i];
      legend.push(<i className={type} key={i} />);
      legend.push(" ");
      legend.push(json["names"][i]);
    }
    return legend;
  }

  minutes_with_leading_zeros(dt) 
  { 
    return (dt.getMinutes() < 10 ? '0' : '') + dt.getMinutes();
  }

  render() {
    
   
    return (
        

<div className="content">
<div className="container presential-course">
        

        <Grid fluid>
          <Row>
            

              <Query
                client={lms_client}
                query={GET_CURSES}
              >
                {({ loading, error, data }) => {
                  if (loading) return <div>Carregando...</div>;
                  if (error) return <div>Erro :(</div>;
                    
                  return data.classroom_collections.classroom.map((item) => (
                    

                    <Row className="border-bottom">
                        <Col lg={1} sm={1} className="col">
                          <div className="live_item_calendar">
                              {months[new Date(item.datetime_start_format).getMonth()]}
                              <span>{new Date(item.datetime_start_format).getDate()}</span>
                          </div>
                        </Col>
                        <Col lg={2} sm={2} className="col">
                          {new Date(item.datetime_start_format).toLocaleDateString("pt-BR")}<br />
                          {new Date(item.datetime_start_format).getHours()}:{this.minutes_with_leading_zeros(new Date(item.datetime_start_format))}
                          <br /><br />
                          Fim: {new Date(item.datetime_end_format).toLocaleDateString("pt-BR")}<br />
                          {new Date(item.datetime_end_format).getHours()}:{this.minutes_with_leading_zeros(new Date(item.datetime_end_format))}
                        </Col>
                        <Col lg={7} sm={7} className="col">
                          {item.course.title}
                        </Col>
                        <Col lg={2} sm={2} className="col">
                        <a 
                        data-gtm-type="click" 
                        data-gtm-clicktype="link" 
                        data-gtm-name={item.course.title}
                        className="btn-fill btn btn-info" href={"/#/curso-presencial/"+item.id}>
                        Matricular
                        </a>
                        </Col>
                    </Row>

      ));
      }}
      </Query>

      </Row>
      </Grid>
      </div>
      </div>
    );
  }
}

export default PresentialList;
