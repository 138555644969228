import React, { Component, useState } from "react";
import {
  Grid,
  Row,
  Col,
  Alert,
  Table,
  FormGroup,
  ControlLabel
} from "react-bootstrap";

import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

import ReactLoading from 'react-loading';

import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; //

import Modal from 'react-awesome-modal';
import QuizAdminRegister from "../../components/Quiz/AdminRegister.jsx";
import QuizAdminLis from "../../components/Quiz/AdminList.jsx";


const restLink_form = new HttpLink({ credentials: 'include', uri: "/api/v1/forms/" });
const form_client = new ApolloClient({
  link: restLink_form,
  cache: new InMemoryCache(),
});

const restLink_account = new HttpLink({ credentials: 'include', uri: "/api/v1/account/" });
const account_client = new ApolloClient({
  link: restLink_account,
  cache: new InMemoryCache(),
});



const GET_QUESTION = gql`
  query question_quiz($lawsuit_id: Int!){
    question_quiz(lawsuit_id: $lawsuit_id) {
        id
        title
        isText
        isClosed
        user_vote
        answers {
          id
          title
        }
      }
  }
`;

const SEND_ALL_VOTES_AND_CONFIRM = gql`
  mutation vote($all_votes: String!){
    send_all_votes_and_confirm(all_votes: $all_votes) {
      id
    }
  }
`;




class QuizAdmin extends Component {

  loggedQuestionInterval;
  colors_btn = {
    'default': 'info',
    'Sim': 'success',
    'Não': 'danger',
    'Abstenção': 'warning'
  };
  answerTexts = []; // Respostas das questões abertas

  state = {
    visible : false,
    loading_page: false,
    addQuiz: false,
    question_quiz: false,
    alertConfirm: "",
    questions_confirm_ids: [], // Para armazenar as perguntas já respondidas
    VotesArray: [], // Para colorir os botoes dos votos já feitos
    VotesSend: [] // Para enviar os votos
  };

  constructor() {
    super();

    
  }

  getQuiz = async () => {


    if(this.props.lawsuit && this.props.lawsuit.id) {
        // Form
        const question_quiz = (await form_client.query({
          variables: {
            lawsuit_id: this.props.lawsuit.id
          },
          query: GET_QUESTION,
          fetchPolicy: 'no-cache'
        })).data.question_quiz;

        this.setState({question_quiz});

        // Só ativa o quiz se tiver um quiz ativo, e se ele não foi respondido
        if(question_quiz && question_quiz.id && !this.state.questions_confirm_ids.includes(question_quiz.id)) {
          this.props.setEnableQuiz(true);
        }
        else {
          this.props.setEnableQuiz(false);
        }

    } 
    setTimeout(this.getQuiz, 25000);

  }

    // Verfica se o voto já foi feito, para colocar a cor adequada no botão
    getClassBtn(answer, question_id) {
      var keyVote = question_id;
      if(typeof this.state.VotesArray[keyVote] != "undefined" && this.state.VotesArray[keyVote] == answer.id) {
        return (this.colors_btn[answer.title] ? this.colors_btn[answer.title] : "info");
      }
      return "default";
    }

  endQuiz = async () =>  {
    this.props.setEndQuiz();
    //console.log("set end quiz");
  }


  async confirmVotesAction() {

    try {

      // Se o usuário alterar algum voto, ele não é sobrescrito, é apenas adicionado novamente, 
      // então utiliza a função reverse para considerar apenas o ultimo
      var VotesSend = this.state.VotesSend.reverse(); 


       // Adiciona as resposta de texto, se tiver
       if(this.answerTexts.length) {
        this.answerTexts.map((v,k) => {
          VotesSend.push({
            'question_current_id': k, 
            'answer_text' : v
          });
        });
      }

      
    
      var votes_string = JSON.stringify(VotesSend);

      this.setState({
        loading_page: true
      });


      await form_client.mutate({
        mutation: SEND_ALL_VOTES_AND_CONFIRM,
        variables: {
          all_votes: votes_string
        }
      });

      var questions_confirm_ids = this.state.questions_confirm_ids;
      questions_confirm_ids.push(this.state.question_quiz.id);

      if(typeof this.props.add_questions_confirm_ids !== "undefined") {
        this.props.add_questions_confirm_ids(questions_confirm_ids);
      }

      this.setState(state => ({
        ...state,
        loading_page: false,
        VotesArray: [],
        VotesSend: [],
        questions_confirm_ids
      }));

      
      setTimeout(this.endQuiz, 2000);

      
    } catch(err) {
      console.error(err)
    }
    
  }

  async voteAction(question_current_id, answer_id) {

    var VotesArray = this.state.VotesArray;
    var VotesSend = this.state.VotesSend;
      
    // Alterar o state apenas do voto selecionado
    var key_item_vote = question_current_id;
    VotesArray[key_item_vote] = answer_id;
    VotesSend.push({
      'question_current_id': question_current_id, 
      'answer_id' : answer_id
    });
    this.setState(state => ({
      ...state,
      VotesArray,
      VotesSend
    }));
  }



  componentDidMount() {
    this.getQuiz();
    //this.getQuizInterval = setInterval(this.getQuiz, 15000);
  }

  componentWillUnmount() {
   // clearInterval(this.getQuizInterval);
  }


  render() {


    //console.log("=================== this.state.question_quiz ===================");
    //console.log(this.state.question_quiz);


    if(typeof this.props.questions_confirm_ids !== "undefined") {
      var array_questions_confirm_ids = this.props.questions_confirm_ids;
    }
    else {
      var array_questions_confirm_ids = this.state.questions_confirm_ids;
    }


    //console.log("=================== this.props.questions_confirm_ids ===================");
    //console.log(this.props.questions_confirm_ids);
    //console.log(array_questions_confirm_ids);

    if(!this.state.question_quiz) {
      return (
        <div className="message-quiz">
          Nenhuma enquete ativa
        </div>
      );
    }

    return (

      <div className="quiz_bar">

        {
          !this.state.loading_page &&
            <div className="quiz_title">
              {this.state.question_quiz.title}
            </div>
        }
        {
          // Pergunta aberta
          !array_questions_confirm_ids.includes(this.state.question_quiz.id) && // Se já votou
          !this.state.question_quiz.isClosed && // Se a pergunta foi fechada pelo admin
          !this.state.loading_page &&
          this.state.question_quiz.isText === 1
          &&
          <div>
                <FormInputs
                  ncols={["col-md-12"]}
                  proprieties={[
                    {
                      name: this.state.question_quiz.id,
                      label: "",
                      type: "text",
                      bsClass: "form-control",
                      placeholder: "Sua resposta",
                      defaultValue: "",
                      onChange: this.onChange_answerText
                    }
                  ]}
                />
          </div>
          }
          { 
            !array_questions_confirm_ids.includes(this.state.question_quiz.id) &&// Se já votou
            !this.state.question_quiz.isClosed && // Se a pergunta foi fechada pelo admin
            !this.state.loading_page &&
            this.state.question_quiz.isText !== 1
            &&
            <div>
              {
                this.state.question_quiz.answers.map((answer) => (
                  <div className="quiz_btns">
                      <Button 
                      bsStyle={this.getClassBtn(answer, this.state.question_quiz.id)}
                      fill 
                      onClick={() => this.voteAction(this.state.question_quiz.id, answer.id)}
                      >
                        {answer.title} 
                      </Button>
                  </div>
                ))
              }
            </div>
          }

          {
            !array_questions_confirm_ids.includes(this.state.question_quiz.id) &&// Se já votou
            !this.state.question_quiz.isClosed && // Se a pergunta foi fechada pelo admin
            !this.state.loading_page &&
            <div className="btn-confirm">
              <Button bsStyle="info" fill onClick={() => this.confirmVotesAction()}>
                CONFIRMAR
              </Button>
            </div>
          }
                      

          {
          this.state.loading_page &&
            <div>
              <strong>Registrando...</strong><br />
                Aguarde...
            </div>
          }
          
          {
            array_questions_confirm_ids.includes(this.state.question_quiz.id) &&// Se já votou
            !this.state.question_quiz.isClosed && // Se a pergunta foi fechada pelo admin
            <div>
              <div role="alert" className="alert alert-info"><span>Obrigado pela sua resposta.</span></div>
            </div>
          }
          
          {
            this.state.question_quiz.isClosed === 1 && // Se a pergunta foi fechada pelo admin
            <div>
              <div role="alert" className="alert alert-info"><span>Votação encerrada.</span></div>
            </div>
          }

  
      </div>
      

      
    );
  }
}

export default QuizAdmin;
