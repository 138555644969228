import React from 'react';
import {
  Row,
  Col
} from "react-bootstrap";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { Chat } from "../Chat/Chat.jsx";
import Quiz from "../Quiz/Quiz.jsx";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const setEnableQuiz = async (enableQuiz) => {
}

const setEndQuiz = async () => {
}



export default function ChatAndQuiz(lawsuit) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [questions_confirm_ids, add_questions_confirm_ids] = React.useState([]);

  //var questions_confirm_ids = [];
  //const add_questions_confirm_ids = async (item) => {
  //  questions_confirm_ids.push(item);
  //}

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  return (

    <div className="quiz-and-chat">
          <AppBar position="static" className="background-zenite">
            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
              <Tab label="Chat" {...a11yProps(0)} />
              <Tab label="Enquetes" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <Chat chat_id={lawsuit.lawsuit.chat_id} enable={true} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            {
              lawsuit.lawsuit.is_quiz === 1
              ?
              <Quiz setEnableQuiz={setEnableQuiz} setEndQuiz={setEndQuiz} lawsuit={lawsuit.lawsuit} questions_confirm_ids={questions_confirm_ids} add_questions_confirm_ids={add_questions_confirm_ids} />
              :
              <div className="message-quiz">
                Nenhuma enquete ativa
              </div>
            }
          </TabPanel>
    </div>
  );
}
