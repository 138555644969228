import React, { Component } from "react";

import {
  Grid,
  Row,
  Col,
  Alert,
  FormGroup,
  ControlLabel,
} from "react-bootstrap";

import { isMobile } from "react-device-detect";
import { Query, Mutation } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import gql from "graphql-tag";
import { HttpLink } from "apollo-link-http";

import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";
import Slider from "react-slick";

import banner_default from "../../assets/img/linx/Banner.png";

import FooterZ from "../../components/Footer/FooterZ.jsx";
import HeaderM from "../../components/Header/HeaderM.jsx";

import capa from "../../assets/img/capa2.png";
import fundobranco from "../../assets/img/Fundo-claro_1.png";
import logo from "../../assets/img/Zenite_Branco.svg";
import logofooter from "../../assets/img/logo-footer.png";

const restLink_account = new HttpLink({
  credentials: "include",
  uri: "/api/v1/account/",
});
const account_client = new ApolloClient({
  link: restLink_account,
  cache: new InMemoryCache(),
});

const RESET_PASS = gql`
  query reset_password($email: String!) {
    reset_password(email: $email) {
      id
      error_login_msg
    }
  }
`;

class ResetPassword extends Component {
  state = {
    email: null,
    msg: null,
    msg_type: "info",
  };

  reset_password = async () => {
    const user = (
      await account_client.query({
        variables: { email: this.state.email },
        query: RESET_PASS,
        fetchPolicy: "no-cache",
      })
    ).data.reset_password;
    if (user.id !== null && user.id !== "") {
      this.setState({
        msg_type: "info",
        msg:
          'Senha configurada para o senha padrão, enviamos essa senha para o seu email. Clique em "Voltar para o login"',
      });
    } else {
      this.setState({
        msg_type: "warning",
        msg: user.error_login_msg,
      });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.reset_password();
  };

  //onChange_email = email => this.setState({ email })

  onChange_email = (event) => {
    this.setState({ email: event.target.value });
  };

  render() {
    var settings_banner = {
      autoplaySpeed: 5000,
      autoplay: true,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      <div>
        {!isMobile && (
          <div
            style={{
              background: `url(${capa}) no-repeat`,
              backgroundSize: "cover",
            }}
          >
            <div className="login-img-zenite container">
              <div className="container  login-img-zenite-logo">
                <img src={logo} />
              </div>
              <div>
                {/* <div className="login-box-header login-box-header-color2"> <div className="login-box-text ">Recupere sua senha</div></div> */}
                <div className="login-box">
                  <p className="text-recover">Recupere sua senha</p>

                  <form
                    onSubmit={(e) => {
                      this.handleSubmit(e);
                      e.preventDefault();
                    }}
                  >
                    <FormInputs
                      ncols={["col-md-12 input-zenite"]}
                      proprieties={[
                        {
                          onChange: this.onChange_email,
                          name: "email",

                          type: "email",
                          bsClass: "form-control",
                          placeholder: "E-mail",
                          defaultValue: "",
                        },
                      ]}
                    />

                    <Row>
                      <Col md={12}>
                        <Button
                          bsStyle="info"
                          className={
                            this.state.email
                              ? "btn-zenite active-login"
                              : "btn-zenite"
                          }
                          fill
                          type="submit"
                        >
                          Enviar
                          <i className="slider-icon pe-7s-check" />
                        </Button>
                      </Col>

                      {this.state.msg && (
                        <Col md={10}>
                          <Alert bsStyle={this.state.msg_type}>
                            {this.state.msg}
                          </Alert>
                        </Col>
                      )}
                    </Row>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}

        {isMobile && (
          <div className="mobile-zenite">
            <HeaderM />
            <div
              style={{
                background: `url(${fundobranco}) no-repeat`,
                backgroundSize: "cover",
              }}
              className="login-img-zenite container"
            >
              <div className="box-zenite my-3">
                {/* <div className="login-box-header login-box-header-color1"> <div className="login-box-text">100% ONLINE E AO VIVO</div></div> */}
                <div className="login-box">
                  <p className="text-recover">Recupere sua senha</p>

                  <form
                    onSubmit={(e) => {
                      this.handleSubmit(e);
                      e.preventDefault();
                    }}
                  >
                    <FormInputs
                      ncols={["col-md-12 input-zenite"]}
                      proprieties={[
                        {
                          onChange: this.onChange_email,
                          name: "email",

                          type: "email",
                          bsClass: "form-control",
                          placeholder: "E-mail",
                          defaultValue: "",
                        },
                      ]}
                    />

                    <Row>
                      <Col md={12}>
                        <Button
                          bsStyle="info"
                          className={
                            this.state.email
                              ? "btn-zenite active-login"
                              : "btn-zenite"
                          }
                          fill
                          type="submit"
                        >
                          Enviar
                          <i className="slider-icon pe-7s-check" />
                        </Button>
                      </Col>
                      {this.state.msg && (
                        <Col md={10}>
                          <Alert bsStyle={this.state.msg_type}>
                            {this.state.msg}
                          </Alert>
                        </Col>
                      )}
                    </Row>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}

        <FooterZ />
      </div>
    );
  }
}

export default ResetPassword;
