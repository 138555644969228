import React, { Component } from "react";
import { Query, Mutation } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import gql from "graphql-tag";
import { HttpLink } from "apollo-link-http";
import ZoomMtgEmbedded from "@zoomus/websdk/embedded";
const client = ZoomMtgEmbedded.createClient();

const restLink_account = new HttpLink({
  credentials: "include",
  uri: "/api/v1/account/",
});
const account_client = new ApolloClient({
  link: restLink_account,
  cache: new InMemoryCache(),
});

var zoomSignature = [];

export class Zoom extends Component {
  state = {
    joinZoom: false,
    zoomSignature: [],
  };
  async componentDidMount() {
    // console.log("=======================");
    // console.log("componentDidMount");
    let meetingSDKElement = document.getElementById("meetingSDKElement");

    var heightPage =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;

    client.init({
      debug: true,
      zoomAppRoot: meetingSDKElement,
      language: "pt-BR",
      customize: {
        video: {
          isResizable: true,
          viewSizes: {
            default: {
              width: 1000,
              height: 600,
            },
          },
        },
      },
    });

    console.log("this.props.meetingNumber");
    console.log(this.props.meetingNumber);

    if (typeof zoomSignature[this.props.meetingNumber] === "undefined") {
      // console.log("======== GET zoomSignature =========");
      zoomSignature[this.props.meetingNumber] = (
        await account_client.query({
          variables: {
            meetingNumber: this.props.meetingNumber,
          },
          query: gql`
            query zoomSignature($meetingNumber: String!) {
              zoomSignature(id: $meetingNumber) {
                signature
                api_key
              }
            }
          `,
          fetchPolicy: "no-cache",
        })
      ).data.zoomSignature;
      this.setState({
        zoomSignature,
      });
    } else if (
      typeof this.state.zoomSignature[this.props.meetingNumber] === "undefined"
    ) {
      this.setState({
        zoomSignature,
      });
    }
  }

  join(signature, api_key, meetingNumber, user_name, user_email) {
    // console.log("=======================");
    // console.log("join");
    this.setState({
      joinZoom: true,
    });
    client.join({
      signature: signature,
      meetingNumber: meetingNumber,
      userName: user_name,
      apiKey: api_key,
      userEmail: user_email,
      // passWord: "",
      success: (success) => {
        console.log(success);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  render() {
    return (
      <React.Fragment>
        <div>
          {!this.state.joinZoom &&
            typeof this.state.zoomSignature[this.props.meetingNumber] !==
              "undefined" && (
              <button
                className="btn btn-primary btn-fill btnJoinZoom"
                onClick={() =>
                  this.join(
                    this.state.zoomSignature[this.props.meetingNumber]
                      .signature,
                    this.state.zoomSignature[this.props.meetingNumber].api_key,
                    this.props.meetingNumber,
                    this.props.user_name,
                    this.props.user_email
                  )
                }
              >
                ENTRAR NA SALA
              </button>
            )}
        </div>
        <div id="meetingSDKElement"></div>
      </React.Fragment>
    );
  }
}

export default Zoom;
