import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import { Grid, Row, Col } from "react-bootstrap";



class Suport extends Component {

  
  render() {
    
   
    return (
        

<div className="content">

<div className="container">
        

        <Grid fluid>
         
        <div>
          <p><strong>Navegadores compatíveis com o Player de vídeo e com o website:</strong></p>
          <p>Praticamente todos os navegadores do mercado são compatíveis com os elementos de vídeo em HTML5 e com codec de vídeo H.264, utilizados pela Samba Tech.</p>
          <p><strong>O Portal é compatível com os seguintes navegadores e versões:</strong></p>
          <p>&nbsp;</p>
          <p><strong>&#8211; Google Chrome</strong> (Todas as versões)</p>
          <p><strong>&#8211; Mozilla Firefox</strong> (No Windows 7 ou superior, versão 22 ou superior do Mozilla Firefox)</p>
          <p><strong>&#8211; Opera</strong> (No Windows 8 ou superior, versão 25 ou superior)</p>
          <p><strong>&#8211; Microsoft Internet Explorer </strong>(No Windows 8 ou superior, versão 10 ou superior)</p>
          <p><strong>&#8211; Apple Safari</strong> (Todas as versões)</p>
          <p>&nbsp;</p>
          <p><strong>Dispositivos mobiles compatíveis:</strong></p>
          <p>&nbsp;</p>
          <p><strong>&#8211; ANDROID: </strong>a partir da versão 4.4 (Roda HLS) &#8211; Navegador Google Chrome;</p>
          <p><strong>&#8211; iOS:</strong> a partir da versão <strong>8+</strong> (Roda HLS) &#8211; Navegador Safari;</p>
          <p><strong>&#8211; WINDOWS PHONE:</strong> O Player Samba suporta o Sistema Operacional mobile <strong>Windows Phone 8.1</strong>, homologado no dispositivo <strong>LUMIA 630</strong> &#8211; Navegador Internet Explorer.</p>
          <p>&nbsp;</p>
          <p><strong>Smart TV&#8217;s compatíveis:</strong></p>
          <p>Até o presente momento, nosso Player não está homologado para navegadores ou em Apps para TV&#8217;s, assim sendo, poderá ou não funcionar em Smart TVs.</p>
          <p>&nbsp;</p>
          <p>Obrigado,</p>
        </div>

      
      </Grid>
      </div>
      </div>
    );
  }
}

export default Suport;
