import React from 'react';
import {
  Row,
  Col
} from "react-bootstrap";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

import { isMobile } from 'react-device-detect';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';




const restLink_dam = new HttpLink({ credentials: 'include', uri: "/api/v1/dam/" });
const dam_client = new ApolloClient({
  link: restLink_dam,
  cache: new InMemoryCache(),
});

const GET_DAM = gql`
  query assets_collections($lawsuit_id: Int!, $category_id: Int!){
    assets_collections(external_parent_id: $lawsuit_id, category_id: $category_id) {
          assets{
            id
            title
            asset
            sort
            is_highlighted
            category {
              value: id
              label: title
            }
          }
        }
      }
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleTabs(lawsuit_id) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="container assets-zenite">

    



    <div className={classes.root}>
      <h3 className="">Materiais</h3>

    {
      isMobile &&
      <Accordion>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Material do curso
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        <Query
                          client={dam_client}
                          query={GET_DAM}
                          variables={{
                            lawsuit_id: lawsuit_id.lawsuit_id,
                            category_id: 1
                          }}
                        >
                          {({ loading, error, data }) => {
                            if (loading) return <div>Carregando...</div>;
                            if (error) return <div>Erro :(</div>;

                            if (!data.assets_collections || !data.assets_collections.assets.length) {
                              return <div className="none-file">Nenhum arquivo nessa categoria</div>;
                            }

                            return (
                              <div name="assets" id="arquivos_div">
                                

                                {data.assets_collections.assets.map((item) => (
                                  <div key={item.id} className={(item.is_highlighted ? "material-highlight" : '') + " border-top d-flex align-center row"}> 
                                  
                                    <Col md={9} className=" pl-0">
                                      <p>{item.title}</p>
                                      {/* <p><strong>{item.sort}</strong></p>
                                      <p><strong>{item.is_highlighted}</strong></p> */}
                                    </Col>
                                    <Col md={3} className=" pr-0">
                                      <a download href={"/dam/assets/" + item.asset} className="btn-custom-zenite py-1 px-1 btn-custom-zenite-text">DOWNLOAD</a>
                                    </Col>
                                  
                                  </div>
                                ))}
                              </div>
                            );

                          }}
                        </Query>
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Legislação
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        <Query
                            client={dam_client}
                            query={GET_DAM}
                            variables={{
                              lawsuit_id: lawsuit_id.lawsuit_id,
                              category_id: 2
                            }}
                          >
                            {({ loading, error, data }) => {
                              if (loading) return <div>Carregando...</div>;
                              if (error) return <div>Erro :(</div>;

                              if (!data.assets_collections || !data.assets_collections.assets.length) {
                                return <div className="none-file">Nenhum arquivo nessa categoria</div>;
                              }

                              return (
                                <div name="assets" id="arquivos_div">
                                  

                                  {data.assets_collections.assets.map((item) => (
                                    <div key={item.id} className={(item.is_highlighted ? "material-highlight" : '') + " border-top d-flex align-center row"}> 
                                    
                                      <Col md={10} className=" pl-0">
                                        <p>{item.title}</p>
                                        {/* <p><strong>{item.sort}</strong></p>
                                        <p><strong>{item.is_highlighted}</strong></p> */}
                                      </Col>
                                      <Col md={2} className=" pr-0">
                                        <a download href={"/dam/assets/" + item.asset} className="btn-custom-zenite py-1 px-1 btn-custom-zenite-text">DOWNLOAD</a>
                                      </Col>
                                    
                                    </div>
                                  ))}
                                </div>
                              );

                            }}
                          </Query>
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Leitura complementar
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        <Query
                          client={dam_client}
                          query={GET_DAM}
                          variables={{
                            lawsuit_id: lawsuit_id.lawsuit_id,
                            category_id: 3
                          }}
                        >
                          {({ loading, error, data }) => {
                            if (loading) return <div>Carregando...</div>;
                            if (error) return <div>Erro :(</div>;

                            if (!data.assets_collections || !data.assets_collections.assets.length) {
                              return <div className="none-file">Nenhum arquivo nessa categoria</div>;
                            }

                            return (
                              <div name="assets" id="arquivos_div">
                                

                                {data.assets_collections.assets.map((item) => (
                                  <div key={item.id} className={(item.is_highlighted ? "material-highlight" : '') + " border-top d-flex align-center row"}> 
                                  
                                    <Col md={10} className=" pl-0">
                                      <p>{item.title}</p>
                                      {/* <p><strong>{item.sort}</strong></p>
                                      <p><strong>{item.is_highlighted}</strong></p> */}
                                    </Col>
                                    <Col md={2} className=" pr-0">
                                      <a download href={"/dam/assets/" + item.asset} className="btn-custom-zenite py-1 px-1 btn-custom-zenite-text">DOWNLOAD</a>
                                    </Col>
                                  
                                  </div>
                                ))}
                              </div>
                            );

                          }}
                        </Query>
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
    }
        


    {
        !isMobile &&

        <div>
          <AppBar position="static" className="background-zenite">
            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
              <Tab label="Material do curso" {...a11yProps(0)} />
              <Tab label="Legislação" {...a11yProps(1)} />
              <Tab label="Leitura complementar" {...a11yProps(2)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
          <Query
            client={dam_client}
            query={GET_DAM}
            variables={{
              lawsuit_id: lawsuit_id.lawsuit_id,
              category_id: 1
            }}
          >
            {({ loading, error, data }) => {
              if (loading) return <div>Carregando...</div>;
              if (error) return <div>Erro :(</div>;

              if (!data.assets_collections || !data.assets_collections.assets.length) {
                return <div className="none-file">Nenhum arquivo nessa categoria</div>;
              }

              return (
                <div name="assets" id="arquivos_div">
                  

                  {data.assets_collections.assets.map((item) => (
                    <div key={item.id} className={(item.is_highlighted ? "material-highlight" : '') + " border-top d-flex align-center row"}> 
                    
                      <Col md={10} className=" pl-0">
                        <p>{item.title}</p>
                        {/* <p><strong>{item.sort}</strong></p>
                        <p><strong>{item.is_highlighted}</strong></p> */}
                      </Col>
                      <Col md={2} className=" pr-0">
                        <a download href={"/dam/assets/" + item.asset} className="btn-custom-zenite py-1 px-1 btn-custom-zenite-text">DOWNLOAD</a>
                      </Col>
                    
                    </div>
                  ))}
                </div>
              );

            }}
          </Query>
          </TabPanel>
          <TabPanel value={value} index={1}>
          <Query
            client={dam_client}
            query={GET_DAM}
            variables={{
              lawsuit_id: lawsuit_id.lawsuit_id,
              category_id: 2
            }}
          >
            {({ loading, error, data }) => {
              if (loading) return <div>Carregando...</div>;
              if (error) return <div>Erro :(</div>;

              if (!data.assets_collections || !data.assets_collections.assets.length) {
                return <div className="none-file">Nenhum arquivo nessa categoria</div>;
              }

              return (
                <div name="assets" id="arquivos_div">
                  

                  {data.assets_collections.assets.map((item) => (
                    <div key={item.id} className={(item.is_highlighted ? "material-highlight" : '') + " border-top d-flex align-center row"}> 
                    
                      <Col md={10} className=" pl-0">
                        <p>{item.title}</p>
                        {/* <p><strong>{item.sort}</strong></p>
                        <p><strong>{item.is_highlighted}</strong></p> */}
                      </Col>
                      <Col md={2} className=" pr-0">
                        <a download href={"/dam/assets/" + item.asset} className="btn-custom-zenite py-1 px-1 btn-custom-zenite-text">DOWNLOAD</a>
                      </Col>
                    
                    </div>
                  ))}
                </div>
              );

            }}
          </Query>
          </TabPanel>
          <TabPanel value={value} index={2}>
          <Query
            client={dam_client}
            query={GET_DAM}
            variables={{
              lawsuit_id: lawsuit_id.lawsuit_id,
              category_id: 3
            }}
          >
            {({ loading, error, data }) => {
              if (loading) return <div>Carregando...</div>;
              if (error) return <div>Erro :(</div>;

              if (!data.assets_collections || !data.assets_collections.assets.length) {
                return <div className="none-file">Nenhum arquivo nessa categoria</div>;
              }

              return (
                <div name="assets" id="arquivos_div">
                  

                  {data.assets_collections.assets.map((item) => (
                    <div key={item.id} className={(item.is_highlighted ? "material-highlight" : '') + " border-top d-flex align-center row"}> 
                    
                      <Col md={10} className=" pl-0">
                        <p>{item.title}</p>
                        {/* <p><strong>{item.sort}</strong></p>
                        <p><strong>{item.is_highlighted}</strong></p> */}
                      </Col>
                      <Col md={2} className=" pr-0">
                        <a download href={"/dam/assets/" + item.asset} className="btn-custom-zenite py-1 px-1 btn-custom-zenite-text">DOWNLOAD</a>
                      </Col>
                    
                    </div>
                  ))}
                </div>
              );

            }}
          </Query>
          </TabPanel>
        </div>
      }
    </div>
    </div>
  );
}
