import React, { Component, useState, useEffect } from 'react';
import Slider from '../../widgets/NetflixSlider';
import VideoYouTube from "../Youtube/Youtube.jsx";
import { ExamEvaluative } from "../Exam/ExamEvaluative.jsx";

import { HttpLink } from 'apollo-link-http';
import { ApolloClient } from 'apollo-client';
import { Exam } from "../Exam/Exam.jsx";
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { Query } from "react-apollo";
import { components } from 'react-select';

const restLink_lms = new HttpLink({
  uri: "/api/v1/lms/",
  credentials: 'include'
});
const lms_client = new ApolloClient({
  link: restLink_lms,
  cache: new InMemoryCache(),
});

const restLink_dam = new HttpLink({
  uri: "/api/v1/dam/",
  credentials: 'include'
});
const dam_client = new ApolloClient({
  link: restLink_dam,
  cache: new InMemoryCache(),
});

const _GET_TRAILITEMS = gql`
  query trailhascourse($trail_id: Int!){
    trailhascourse(trail_id:$trail_id) {
      trailhascourses{
        id
        course{
          id
          title
          description
          thumbnail
          scorm
          active
          video {
            id
            uuid
          }
        }
        
      }
    }
  }
`;

// const _GET_TRAILITEMS = gql`query getCourse($id: Int!) {
//   course(id:$id){
//     id,
//     title,
//     description,
//     scorm,
//     hours,
//     minutes,
//     thumbnail,
//     code,
//     video{
//       id, thumbnail
//     },
//     proof{
//       id, title
//     }, 
//     reactionevaluation{
//       id, title
//     }
//   }
// }
// `}

const GET_COURSE = gql`
  query course($id: Int!){
    course(id:$id){
      id,
      title,
      description,
      scorm,
      hours,
      minutes,
      thumbnail,
      code,
      video{
        id, thumbnail, uuid
      },
      proof{
        id, title
      }, 
      reactionevaluation{
        id, title
      }
    }
  }
`;

const VIDEO_HAS_USER = gql`
  mutation createOrUpdateVideoHasUser($video_id: String!, $time: Int!, $time_reproduced: Int!, $time_seconds: String!){
    createOrUpdateVideoHasUser( input:{video_id:$video_id, time:$time, time_reproduced:$time_reproduced, time_seconds:$time_seconds}) {
      id
    }
  }
`;

const TRAIL_ITEM_HAS_USER = gql`
  mutation trailItemHasUser($trail_id: Int!, $item_id: Int!, $item_type: String!){
      createTrailItemHasUser(input:{trail_id:$trail_id, item_id:$item_id, item_type:$item_type}){
      id
    }
  }
`;

const GET_TRAIL_ITEM_HAS_USER = gql`
  query getTrailItemHasUser($trail_id: Int!){
    trail_itens_by_user(trail_id: $trail_id) {
      trailitenshasuser{
        item_id, item_type
      }
    }
  }
`;

const GET_ASSET = gql`
  query asset($id: Int!){
    asset(id: $id) {
      id
      title
      asset
    }
  }
`;

const GET_TRAILITEMS = gql`
  query trailitems($trail_id: Int!){
    trailitems(trail_id:$trail_id) {
      trailitems{
        id, item_id, item_type, ordernum
      }
    }
  }
`;


const GET_PROOF = gql`
  query getProof($proof_id: Int!){
    proof(id: $proof_id) {
      questions {
        id
        title
        answers {
          id
          title
        }
      }
    }
  }`;



const GET_TRAIL_PROOF = gql`
  query getTrail($trail_id: Int!){
    trail(id:$trail_id) {
      proof {
        id
        title
      }
    }
  }
`;



const GET_ANSWER_QUESTION = gql`
query answer_question_collections($proof_id: Int!, $item_id: Int!, $item_type: String!){
  answer_question_collections(proof_id: $proof_id, item_id: $item_id, item_type: $item_type){
    answers_question{
      id
    }
  }
}`;

const FINISH_TRAIL = gql`
  mutation addAnswerQuestion($trail_id: Int!, $status: String!){
    updateTrailHasUser(input:{trail_id:$trail_id, status:$status}) {
      id
    }
  }`;

const GET_TRAIL_REACTIONEVALUATION = gql`
  query getTrail($trail_id: Int!){
    trail(id:$trail_id) {
      reactionevaluation {
        id
        title
      }
    }
  }
`;

const GET_TRAIL = gql`
  query getTrail($id: Int!){
    trail(id:$id) {
      id
      title
      active
      blockcontent
    }
  }
`;

const GET_TRAIL_HAS_USER_ITEM = gql`
  query trail_has_user_item($trail_id: Int!){
    trail_has_user_item(trail_id: $trail_id){
      user_id
      status
    }
  }
`;

const GET_SETTINGS = gql`
  query get_settings{
    get_settings{
      id
      youtube_channel_id
      youtube_playlist_id
      youtube_playlist_title
      client_id
      client_secret
      token
      folder_id
      video_platform
    }
  }
  `;


var intervalGetCurlTime = null;

export default function BurguerMenu({ setActive }) {


  var player = false;

  var time = 0;
  var arrSeconds = [];
  var timeAll = 0;
  var percVideo = [];
  var jsonObj = [];

  var completedCourseTrigger = [];

  const [trailReactionevaluation, setTrailReactionevaluation] = useState([]);

  const [trailItemActive, setTrailItemActive] = useState(0);
  const [itemActive, setItemActive] = useState(0);
  // const [active, setActive] = useState(0);
  const [, setCompletedCourse] = useState([]);
  const [settingsPlatform, setSettingsPlatform] = useState([]);
  // const [aulas, setAulas] = useState([]);
  // const [perc, setPerc] = useState(false);

  

  function setCompletedItem(arrSeconds) {
    lms_client.mutate({
      mutation: VIDEO_HAS_USER,
      variables: {
        video_id: itemActive.video.uuid, time: timeAll, time_reproduced: arrSeconds.length, time_seconds: JSON.stringify(arrSeconds),
      }
    });
  }





  useEffect(() => {
    // init();
  }, []);





  let [, setState] = useState();

  function handleUpdate() {
    //passing empty object will re-render the component

    setState({});
  }




  return (
    <div className="burguer-menu">
      <button type="button" className="navbar-toggle navbar-toggle-acreditation collapsed" onClick={setActive}>
        <span className="sr-only">Toggle navigation</span>
        <span className="icon-bar"></span>
        <span className="icon-bar">
        </span>
        <span className="icon-bar">
        </span>
      </button>
    </div>
  );

}