import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";


import { NavItem, Nav, NavDropdown, MenuItem } from "react-bootstrap";


import { Query } from "react-apollo";
import gql from "graphql-tag";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';

import Button from "../../components/CustomButton/CustomButton.jsx";

import banner_default from "../../assets/img/banner-medias.png";

const restLink_dam = new HttpLink({ 
  uri: "/api/v1/dam/",
  credentials: 'include'
});
const dam_client = new ApolloClient({
  link: restLink_dam,
  cache: new InMemoryCache(),
});


const GET_DAM = gql`
  query getDam($module_name: String!, $category_id:Int!){
    assets_collections(limit:200,order:"Desc",module_name:$module_name,category_id:$category_id) {
      assets{
        id, title, asset, thumbnail
      }
    }
  }
`;


const GET_CATEGORIES = gql`{
  category_collections(parent:null){
    categories{
      id,title
    }
  }
}
`;

const GET_SUBCATEGORIES = gql`
query getCagegories($parent_id:Int!){
    category_collections(parent:$parent_id){
      categories{
        id,title
      }
    }
  }
`;

const GET_CATEGORY = gql`
  query getCategory($category_id: Int!){
    category(id:$category_id){
      banner
    }
  }
`;

class MediaCenter extends Component {

  constructor() {
    super();
    this.category_id = 0;
  }
  
  createLegend(json) {
    var legend = [];
    for (var i = 0; i < json["names"].length; i++) {
      var type = "fa fa-circle text-" + json["types"][i];
      legend.push(<i className={type} key={i} />);
      legend.push(" ");
      legend.push(json["names"][i]);
    }
    return legend;
  }

  render() {
    
    this.category_id = (this.props.match.params.category_id ? this.props.match.params.category_id : 0);
    
    return (
        

<div className="">


      <Query
        client={dam_client}
        query={GET_CATEGORY}
        variables={{category_id:this.category_id}}
        >
        {({ loading, error, data }) => {
          if (loading) return <div>Carregando...</div>;
          if (error) return <div>Erro :(</div>;
          
          return (
            <img className="banner_content" src={data.category && data.category.banner ? "/dam/assets/"+data.category.banner : banner_default} />
            );
          }}
        </Query>

<div className="container">
<Grid fluid>
          <Row>
<div className="contentMenu">
        
        <Nav>
        <NavItem
        data-gtm-type="click" 
        data-gtm-clicktype="submenu" 
        data-gtm-name="Todos"
        href={"/#/conteudos-digitais/"}>Todos</NavItem>
        <Query
          client={dam_client}
          query={GET_CATEGORIES}
        >
          {({ loading, error, data }) => {
            if (loading) return <div>Carregando...</div>;
            if (error) return <div>Erro :(</div>;
              
              if(!data.category_collections.categories) {
                return (<div>(Vazio)</div>);
              } 

            return data.category_collections.categories.map((categ) => (
              
              <NavDropdown
                eventKey={2}
                title={categ.title}
                id="basic-nav-dropdown-right"
              >
                    <Query
                          client={dam_client}
                          query={GET_SUBCATEGORIES}
                          variables={{parent_id:categ.id}}
                        >
                          {({ loading, error, data }) => {
                            if (loading) return <div>Carregando...</div>;
                            if (error) return <div>Erro :(</div>;
                              
                              if(!data.category_collections) {
                                return (<div>(Vazio)</div>);
                              } 

                            return data.category_collections.categories.map((sub_categ) => (
                              <NavItem
                              data-gtm-type="click" 
                              data-gtm-clicktype="submenu" 
                              data-gtm-name={sub_categ.title}
                              eventKey={sub_categ.id} href={"/#/conteudos-digitais/"+sub_categ.id}>{sub_categ.title}</NavItem>
                            ));
                        }}
                      </Query>
              </NavDropdown>
          ));
        }}
      </Query>
        </Nav>
      </div>
      </Row>
      </Grid>

        <Grid fluid>
          <Row>
            

              <Query
                client={dam_client}
                variables={{module_name:"Media", category_id:this.category_id}}
                query={GET_DAM}
              >
                {({ loading, error, data }) => {
                  if (loading) return <div>Carregando...</div>;
                  if (error) return <div>Erro :(</div>;
                    
                    if(!data.assets_collections.assets) {
                      return (<div>(Vazio)</div>);
                    } 

                  return data.assets_collections.assets.map((item) => (
                    
                    <Col lg={4} sm={6}>
                    <div className="media media_asset">
                        <div>
                          <h2>{item.title}</h2>
                          <a 
                          data-gtm-type="click" 
                          data-gtm-clicktype="download" 
                          data-gtm-name={item.title}
                          href={"/api/v1/dam/download/?id="+item.id+"&file="+item.asset} download target="_blank">
                          <Button bsStyle="info" fill>
                            Baixe aqui
                          </Button>
                          </a>
                        </div>
                    </div>
                    </Col> 
      ));
      }}
      </Query>

      </Row>
      </Grid>
      </div>
      </div>
    );
  }
}

export default MediaCenter;
