import React from "react";
//import ReactDOM from "react-dom";

import HttpsRedirect from 'react-https-redirect';


import { HashRouter, Route, Switch } from "react-router-dom";

import indexRoutes from "./routes/index.jsx";

import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";
import { render } from "react-dom";
import gql from "graphql-tag";

import getCookie from "./components/Cookie/Cookie.jsx";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/sass/light-bootstrap-dashboard.css?v=1.2.0";
import "./assets/css/demo.css";
import "./assets/css/pe-icon-7-stroke.css";
import "./assets/css/pluz.css";


const account_client = new ApolloClient({
  uri: "/api/v1/account/"
});

var get_session  = async () => { 
  var get_session_result = (await account_client.query({
      fetchPolicy: 'no-cache',
      query: gql`{
        get_session{
          id
        }
      }`
  })).data.get_session;

  // Finaliza a sessão, caso faça o login em outro dispositivo
  if(typeof get_session_result.id !== 'undefined' && get_session_result.id === 0) {
    clearInterval(getSession);
    window.location.replace("/api/v1/account/logout");
  }
    
}
// Se não está na página de login, faz o processo de atualizar a sessão a cada 3 min
if(window.location.hash.indexOf("#/login") !== 0 && window.location.hash.indexOf("#/registro") !== 0 && window.location.hash.indexOf("#/recupear-senha") !== 0) {
  get_session();
  var getSession = setInterval(get_session, 180000);
} else {
  if(typeof getSession !== 'undefined') {
    clearInterval(getSession);
  }
}


function go_to_login() {
  account_client.query({
      query: gql`{
        account_auth{
            login_url
        }
      }`
  })
  .then(
    result => {
      window.location.replace(result.data.account_auth.login_url);
      if(result.data.account_auth.login_url == "/#/login" || result.data.account_auth.login_url == "/#/registro" || result.data.account_auth.login_url == "/#/recupear-senha") {
        window.location.reload();
      }
    }
  );
}

const auth_uri = "#/auth/";

//if(window.location.hash.indexOf(bot_uri) === 0) {
//  const token_user = window.location.hash.replace(auth_uri, "");
//  document.cookie = "token_user="+token_user;// Grava o coookie
//} else 
if(window.location.hash.indexOf(auth_uri) === 0){// Verifica se está na página de auth
  const token_user = window.location.hash.replace(auth_uri, "");
  document.cookie = "token_user="+token_user;// Grava o coookie
  try {
      if(window.self !== window.top){
        window.location.replace(window.location.protocol+"//"+window.location.host+"/#/pluzbot/");// Envia para o both
      } else {
        window.location.replace(window.location.protocol+"//"+window.location.host);// Envia para raiz
      }
  } catch (e) {
    window.location.replace(window.location.protocol+"//"+window.location.host);// Envia para raiz
  }
} 

  
const token_user = getCookie("token_user");


if(window.location.hash.indexOf("#/login") === 0 || window.location.hash.indexOf("#/registro") === 0 || window.location.hash.indexOf("#/recupear-senha") === 0){// Verifica se está na página de login
  const App = () => (
    //<HttpsRedirect>
        <ApolloProvider client={account_client}>
          <HashRouter>
            <Switch>
              {indexRoutes.map((prop, key) => {
                return <Route to={prop.path} component={prop.component} key={key} />;
              })}
            </Switch>
          </HashRouter>
        </ApolloProvider>
    //</HttpsRedirect>
  );
  render(<App />, document.getElementById("root"));
}
else if(token_user) {
  account_client.query({
      variables:{token:token_user},
      query: gql`
      query get_user($token: String!) {
        get_user_by_token(token: $token){
            id
        }
    }`
  })
  .then(
    result => {
      if(result.data.get_user_by_token) {
        const App = () => (
         //<HttpsRedirect>
          <ApolloProvider client={account_client}>
            <HashRouter>
              <Switch>
                {indexRoutes.map((prop, key) => {
                  return <Route to={prop.path} component={prop.component} key={key} />;
                })}
              </Switch>
            </HashRouter>
          </ApolloProvider>
         //</HttpsRedirect>
        );
        
        render(<App />, document.getElementById("root"));
      } else {
        go_to_login();
      }
    }
  );
} else {
  go_to_login();
}

