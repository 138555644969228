import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import { Grid, Row, Col } from "react-bootstrap";


import { NavItem, Nav, NavDropdown, MenuItem } from "react-bootstrap";


import { Query } from "react-apollo";
import gql from "graphql-tag";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import Slider from "react-slick";

import { Card } from "../../components/Card/Card.jsx";
import { StatsCard } from "../../components/StatsCard/StatsCard.jsx";
import { Tasks } from "../../components/Tasks/Tasks.jsx";



const restLink_lms = new HttpLink({ 
  uri: "/api/v1/lms/",
  credentials: 'include'
});
const lms_client = new ApolloClient({
  link: restLink_lms,
  cache: new InMemoryCache(),
});

const restLink_dam = new HttpLink({ 
  uri: "/api/v1/dam/",
  credentials: 'include'
});

const dam_client = new ApolloClient({
  link: restLink_dam,
  cache: new InMemoryCache(),
});

const GET_DAM = gql`
  query getDam($module_name: String!){
    assets_collections(limit:5,order:"DESC",module_name:$module_name) {
      assets{
        id, title, asset, directlink
      }
    }
  }`;



const GET_VIDEO = gql`
query getVideos($category_id: Int!){
  videos_collections_by_category(category_id: $category_id){
    videos{
      id, title, thumbnail
    }
  }
}
`;

const GET_CATEGORIES = gql`{
  video_categories_collections(parent_name:"Sala Virtual") {
    categories {
      id
      name
      children {
        id
        name
      }
    }
  }
}
`;

class Video extends Component {

  constructor() {
    super();
    this.category_id = 0;
  }
  
  createLegend(json) {
    var legend = [];
    for (var i = 0; i < json["names"].length; i++) {
      var type = "fa fa-circle text-" + json["types"][i];
      legend.push(<i className={type} key={i} />);
      legend.push(" ");
      legend.push(json["names"][i]);
    }
    return legend;
  }

  render() {

    var settings_banner = {
      autoplaySpeed: 5000,
      autoplay: true,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    
    
    return (
      <div>
      <div className="banner">
              <Query
                  client={dam_client}
                  variables={{module_name:"ChatBanner"}}
                  query={GET_DAM}
                >
                  {({ loading, error, data }) => {
                    if (loading) return <div>Carregando...</div>;
                    if (error) return <div>Erro :(</div>;
                      
                      if(!data.assets_collections) {
                        return (<div>(Vazio)</div>);
                      } 
                      return (         
                        <Slider {...settings_banner}>
                              {data.assets_collections.assets.map((item) => (
                                <div key={item.id}>
                                  <a href={item.directlink} target="_blank">
                                    <img src={"/dam/assets/"+item.asset} />
                                  </a>
                                </div>
                              ))}
                        </Slider>
                      )
                }}
                </Query>
            </div>   

  <div className="">
  <div className="container">
  

  <Grid fluid>
          <Row>
<div className="contentMenu">
        
        <Nav>
        <NavItem href={"/#/lives-gravadas/"}>Todos</NavItem>
        <Query
          client={lms_client}
          query={GET_CATEGORIES}
        >
          {({ loading, error, data }) => {
            if (loading) return <div>Carregando...</div>;
            if (error) return <div>Erro :(</div>;
              
              if(!data.video_categories_collections.categories) {
                return (<div>(Vazio)</div>);
              } 

            return data.video_categories_collections.categories.map((categ) => (
              
              <NavDropdown
                eventKey={2}
                title={categ.name}
                id="basic-nav-dropdown-right"
              >
                    {categ.children.map((sub_categ) => (
                              <NavItem eventKey={sub_categ.id} href={"/#/lives-gravadas/"+sub_categ.id}>{sub_categ.name}</NavItem>
                      ))
                     }
              </NavDropdown>
          ));
        }}
      </Query>
        </Nav>
      </div>
      </Row>
      </Grid>

        <Grid fluid>
          <Row>
            
              <Query
                client={lms_client}
                variables={{ category_id: (this.props.match.params.category_id ? parseInt(this.props.match.params.category_id) : 2) }}
                query={GET_VIDEO}
              >
                {({ loading, error, data }) => {
                  if (loading) return <div>Carregando...</div>;
                  if (error) return <div>Erro :(</div>;
                    
                    if(!data.videos_collections_by_category.videos) {
                      return (<div>(Vazio)</div>);
                    } 

                  return data.videos_collections_by_category.videos.map((item) => (
                    
                    <Col lg={4} sm={6}>
                    <div className="media">
                      <a href={"/#/video/"+item.id}>
                      <img src={item.thumbnail} />
                      <div>{item.title}</div>
                      </a>
                    </div>
                    </Col>
      ));
      }}
      </Query>

      </Row>
      </Grid>
      </div>
      </div>
      </div>
    );
  }
}

export default Video;
