import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl
} from "react-bootstrap";

import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";


const ADD_TODO = gql`
  mutation createChat($title: String!, $description: String!){
    createChat(input:{title:$title,description:$description}){
      title
    }
  }
`;

class ChatItem extends Component {

  handleSubmit = (event) => {
    event.preventDefault()
    console.log(event.target.elements.title.value)
    console.log(event.target.elements.description.value)
  }

  
  render() {
    return (
      <Mutation mutation={ADD_TODO}>
      {(createChat, { data }) => (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
            
            <NavLink
                to='/chat'
                className=""
                //activeClassName=""
              >
              <p>VOLTAR</p>
            </NavLink>

              <Card
                title="Chat"
                content={
                  <form 
                  onSubmit={e => {
                    e.preventDefault();
                    createChat({ variables: { title: e.target.elements.title.value, description: e.target.elements.description.value } });
                    //input.value = "";
                  }}
                  >
                  <FormInputs
                    ncols={["col-md-5"]}
                    proprieties={[
                      {
                        name: "title",
                        label: "Título",
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "Título",
                        defaultValue: ""
                      }
                    ]}
                  />
                  <FormInputs
                    ncols={["col-md-5"]}
                    proprieties={[
                      {
                        name: "description",
                        label: "Descrição",
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "Descrição",
                        defaultValue: ""
                      }
                    ]}
                  />
                   
                    <Button bsStyle="info" pullRight fill type="submit">
                      Salvar
                    </Button>
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
            
          </Row>
        </Grid>
      </div>
      )}
      </Mutation>
    );
  }
}

export default ChatItem;
