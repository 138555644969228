import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";

import { ApolloClient } from 'apollo-client';
import { Query } from "react-apollo";
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';
import { Proof } from "../../components/Proof/Proof.jsx";
import { ProofEvaluative } from "../../components/Proof/ProofEvaluative.jsx";
import { Scorm } from "../../components/Course/Scorm.jsx";
import thumb_certificate from "../../assets/img/certificate-400x225.jpg";
import thumb_reactionevaluation from "../../assets/img/avaliacao-reacao-400x225.jpg";
import thumb_proof from "../../assets/img/avaliacao-aprendizagem-400x225.jpg";
import { SidebarCourse } from "../../components/Sidebar/SidebarCourse.jsx";
import { CourseCarousel } from "../../components/CoursePage/CourseCarousel.jsx";
import { TrailCarousel } from "../../components/CoursePage/TrailCarousel.jsx";

import Slider from "react-slick";


const restLink_account = new HttpLink({
  uri: "/api/v1/account/",
  credentials: 'include'
});
const account_client = new ApolloClient({
 link: restLink_account,
 cache: new InMemoryCache(),
});

const restLink = new HttpLink({ 
  uri: "/api/v1/lms/",
  credentials: 'include'
 });
const lms_client = new ApolloClient({
  link: restLink,
  cache: new InMemoryCache(),
});

const restLink_dam = new HttpLink({
  uri: "/api/v1/dam/",
  credentials: 'include'
});
const dam_client = new ApolloClient({
  link: restLink_dam,
  cache: new InMemoryCache(),
});



const GET_COURSES = gql`{
  courses_collections{
    courses{
      id,title,thumbnail
    }
  }
}
`;



const TRAIL_ITEM_HAS_USER = gql`
  mutation trailItemHasUser($trail_id: Int!, $item_id: Int!, $item_type: String!){
      createTrailItemHasUser(input:{trail_id:$trail_id, item_id:$item_id, item_type:$item_type}){
      id
    }
  }
`;




class CoursePage extends Component {


  urlPlayer;
  current_item_id;
  trail_id;

  state = {
    courseFinish: false
  }

  constructor() {
    super();

    this.current_item_id = 0;

    this.itemsTrailCompleted = [];

    this.setCourseFinish = this.setCourseFinish.bind(this);
    
  }

  setCourseFinish(courseFinish) {
    this.setState({
      courseFinish: courseFinish
    })
  }


  render() {

    var itemKey = this.props.match.params.type+"-"+this.props.match.params.id;
    if(this.itemsTrailCompleted.indexOf(itemKey) === -1) {
      this.itemsTrailCompleted.push(itemKey);
    }

    const windowWidth = window.innerWidth;
    
    var settings_carousel = {
      autoplay: false,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: (windowWidth <= 987 ? 2 : 4),
      slidesToScroll: (windowWidth <= 987 ? 2 : 4)
    };

    var settings_courses = {
      vertical: true,
      autoplay: false,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: (windowWidth <= 987 ? 2 : 4),
      slidesToScroll: (windowWidth <= 987 ? 2 : 4)
    };


    return (
      
      <Query
      client={account_client}
      query={gql`{
        get_authenticated_user{
          id,
          name
        }
      }
      `}
    >
      {({ loading, error, data }) => {
        if (loading) return <div>Olá</div>;
        if (error) return <div>Erro :(</div>;
          
          const auth_user = data.get_authenticated_user;

          var a = "é nova" ;
          var b = "é antiga";

          
          
        return  (
          
      <div id="CoursePage">


          <Grid>
              <div className="course_sidebar">


                { // Se não tiver a trilha, exibe as avaliações do curso ----- //!this.props.match.params.trail_id && (data.course.proof || data.course.reactionevaluation) && 
                  !this.props.match.params.trail_id && this.props.match.params.id && 
                  <CourseCarousel courseFinish={this.state.courseFinish} course_id={this.props.match.params.id} course_proof_type={this.props.match.params.course_proof_type ? this.props.match.params.course_proof_type : false} />
                }

                { // Só exibe a trilha se tiver o param do ID da trilha na URL
                  this.props.match.params.trail_id &&
                  <TrailCarousel trail_id={this.props.match.params.trail_id} item_id={this.props.match.params.id} item_type={this.props.match.params.type} /> 
                }
                
              </div>
              <div className="course_content">

                    {this.props.match.params.type == "reactionevaluation" ||  this.props.match.params.type == "proof" ?

                        <div className="content">
                        <Grid fluid>
                          <Row>
                            <Col md={12}>
                              <div>
                              {this.props.match.params.type  == "proof"
                                ?
                                  <ProofEvaluative item_id={this.props.match.params.trail_id}  item_type="trail" setCourseFinish={this.setCourseFinish} proof_id={this.props.match.params.id} />
                                :
                                  <Proof item_id={this.props.match.params.trail_id} item_type="trail" proof_id={this.props.match.params.id} />
                                }
                              </div>
                            </Col>
                          </Row>

                        </Grid>
                        </div>

                    :
                    <div>
                    {this.props.match.params.type == "asset" ?

                          
                          <Query
                          client={dam_client}
                          variables={{ id: this.props.match.params.id }}
                          query={gql`
                          query getAsset($id: Int!) {
                              asset(id:$id){
                                id,
                                title,
                                thumbnail,
                                asset
                            }
                          }
                          `}
                          >
                          {({ loading, error, data }) => {
                            if (loading) return <div>Carregando...</div>;
                            if (error) return <div>Erro :(</div>;
                              
                              // Salva o Item como Concluído para o usuário, só por ter aberto o item
                              if(this.props.match.params.trail_id) {
                                lms_client.mutate({
                                    variables:{trail_id:this.props.match.params.trail_id, item_id:data.asset.id, item_type:'asset' },
                                    mutation: TRAIL_ITEM_HAS_USER
                                });
                              }
                              

                            return (
                              <div>
                              
                                <div className="content">
                                    <Grid fluid>
                                      <Row>
                                        <Col md={12}>
                                        <div>
                                          <div className="download_asset_big">
                                            <a href={"/dam/assets/"+data.asset.asset} download target="_blank">
                                              <i className="pe-7s-cloud-download"></i>
                                                <img src={"/dam/assets/"+data.asset.thumbnail} />
                                              </a>
                                          </div>
                                          
                                          <h1>{data.asset.title}</h1>
                                          </div>
                                        </Col>
                                      </Row>

                                    </Grid>
                                  </div>
                            </div>
                          );
                          }}
                          </Query>

                              :

                              <Query
                              client={lms_client}
                              variables={{ id: this.props.match.params.id }}
                              query={gql`
                              query getCourse($id: Int!) {
                                course(id:$id){
                                  id,
                                  title,
                                  description,
                                  scorm,
                                  hours,
                                  minutes,
                                  thumbnail,
                                  code,
                                  video{
                                    id, thumbnail
                                  },
                                  proof{
                                    id, title
                                  }, 
                                  reactionevaluation{
                                    id, title
                                  }
                                }
                              }
                              `}
                            >
                              {({ loading, error, data }) => {
                                if (loading) return <div>Carregando...</div>;
                                if (error) return <div>Erro :(</div>;
                                  
                                  // Salva o Item como Concluído para o usuário, só por ter aberto o item
                                  if(this.props.match.params.trail_id) {
                                    var trail_id = this.props.match.params.trail_id;
                                    
                                    lms_client.mutate({
                                      variables:{trail_id:trail_id, item_id:data.course.id, item_type:'course' },
                                      mutation: TRAIL_ITEM_HAS_USER
                                    });

                                  }

                                  if(data.course.code && data.course.code.indexOf("[h5") === 0) {
                                    var lenCode = (data.course.code.length - 11);
                                    var codeCourse = data.course.code.substr(data.course.code.indexOf('="')+2, lenCode);
                                    var iframeUrl = "https://pluz.io/porto-seguro/wp-admin/admin-ajax.php?action=h5p_embed&id="+codeCourse;
                                  } else if(data.course.video && data.course.video.id) {
                                    var iframeUrl = "https://fast.player.liquidplatform.com/pApiv2/embed/a48377e3ea6d8603fcafcb42130e3369/"+data.course.video.id;
                                  } else {
                                    var iframeUrl = "";
                                  }

                                return (
                                  <div>
                                    <div className="content content-scorm">
                                        <Grid fluid>
                                          <Row>
                                            <Col md={12}>
                                            <div>
                                              {this.props.match.params.course_proof_id
                                                ?
                                                  <div>
                                                    {this.props.match.params.course_proof_type == "proof"
                                                    ?
                                                      <ProofEvaluative item_id={data.course.id} item_type="course" setCourseFinish={this.setCourseFinish} proof_id={this.props.match.params.course_proof_id} />
                                                    :
                                                      <Proof item_id={data.course.id} item_type="course" proof_id={this.props.match.params.course_proof_id} />
                                                    }
                                                  </div>
                                                
                                                :
                                                  <div>
                                                    {data.course.scorm ?
                                                      <Scorm course={data.course} user={auth_user} />
                                                    :
                                                      <div className="embed-responsive embed-responsive-16by9 card shadow-sm">
                                                          <iframe  className="embed-responsive-item" allowFullScreen webkitallowfullscreen="true" mozallowfullscreen="true" src={iframeUrl} scrolling="no" frameBorder="0" allow="geolocation; microphone; camera; encrypted-media; midi"></iframe>
                                                      </div>
                                                    }
                                                  </div>
                                                }
                                              <h1>{data.course.title}</h1>
                                              <p>{data.course.description}</p>
                                              </div>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col md={12}>
                                              <SidebarCourse course={data.course} />
                                            </Col>
                                          </Row>
                                    </Grid>
                                  </div>
                                </div>
                              );
                            }}
                          </Query>

                        }
                        </div>
                        }

                  </div>
              </Grid>
              <div className="both"></div>




                <div className="content content_bar">
                  <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <h2>
                              Mais assistidos
                            </h2>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                            <Query
                            client={lms_client}
                            query={GET_COURSES}
                            >
                            {({ loading, error, data }) => {
                              if (loading) return <div>Carregando...</div>;
                              if (error) return <div>Erro :(</div>;
                                
                                return (
                                  <Slider {...settings_carousel}>
                                        {data.courses_collections.courses.map((item) => (
                                          <div key={item.id} className="course-item">
                                            <a 
                                            data-gtm-type="click" 
                                            data-gtm-clicktype="link" 
                                            data-gtm-name={item.title}
                                            href={"/#/curso/"+item.id}>
                                            <img src={"/lms/assets/"+item.thumbnail} />
                                            <div className="title">{item.title}</div>
                                            </a>
                                          </div>
                                        ))}
                                  </Slider>
                                )
                          }}
                          </Query>
                        </Col>
                      </Row>
                      </Grid>
                    </div>

              </div>

      );
    }}
    </Query> // End Ger Auth User
    );
  }
}

export default CoursePage;
