import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import { Grid, Row, Col } from "react-bootstrap";


import { NavItem, Nav, NavDropdown, MenuItem } from "react-bootstrap";


import { Query } from "react-apollo";
import gql from "graphql-tag";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';



const restLink_lms = new HttpLink({
   uri: "/api/v1/lms/",
   credentials: 'include'
});
const lms_client = new ApolloClient({
  link: restLink_lms,
  cache: new InMemoryCache(),
});


const GET_CERTIFICATES = gql`{
  trail_has_user{
    trailhasuser{
      id,
      trail{
        id,
        title,
        thumbnail
      }
    }
  }
}
`;



class CertificateTrailList extends Component {

  
  createLegend(json) {
    var legend = [];
    for (var i = 0; i < json["names"].length; i++) {
      var type = "fa fa-circle text-" + json["types"][i];
      legend.push(<i className={type} key={i} />);
      legend.push(" ");
      legend.push(json["names"][i]);
    }
    return legend;
  }

  render() {
    
   
    return (
        

<div className="content">
<div className="container">
        

        <Grid fluid>
          <Row>
            

              <Query
                client={lms_client}
                query={GET_CERTIFICATES}
              >
                {({ loading, error, data }) => {
                  if (loading) return <div>Carregando...</div>;
                  if (error) return <div>Erro :(</div>;

                  if(!data.trail_has_user.trailhasuser || !data.trail_has_user.trailhasuser.length){
                    return <Col lg={12}><h1>Você não tem certificados de Trilhas.</h1></Col>
                  }
                    
                  return data.trail_has_user.trailhasuser.map((cursehasuser) => (
                    
                    <Col lg={4} sm={6} className="col" key={cursehasuser.id}>
                    <div className="media">
                      <img src={"/lms/assets/"+cursehasuser.trail.thumbnail} />
                      <div>{cursehasuser.trail.title}</div>
                      <a 
                      data-gtm-type="click" 
                      data-gtm-clicktype="download" 
                      data-gtm-name={"Certificado Trilha - " + cursehasuser.trail.title}
                      target="_blank" download className="btn" href={"/api/v1/lms/certified_trail_generat/?u="+cursehasuser.id} >
                        Gerar Certificado
                      </a>
                    </div>
                    </Col>
      ));
      }}
      </Query>

      </Row>
      </Grid>
      </div>
      </div>
    );
  }
}

export default CertificateTrailList;
