import React, { Component, useState } from "react";
import {
  Grid,
  Row,
  Col,
  Alert,
  Table,
  FormGroup,
  ControlLabel
} from "react-bootstrap";

import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';




const restLink_form = new HttpLink({ credentials: 'include', uri: "/api/v1/forms/" });
const form_client = new ApolloClient({
  link: restLink_form,
  cache: new InMemoryCache(),
});


const GET_QUESTIONS = gql`
  query getForms($lawsuit_id: Int!, $type: String!){
    forms_collections( lawsuit_id: $lawsuit_id, type: $type, list_all: 1){
      forms{
        id
        questions {
          id
          title
          isActive
          isText
          isClosed
        }
      }
    }
  }
`;

const QUESTION_TOGGLE_ACTIVE = gql`
  mutation questionToggleActive($question_id: Int!, $isActive: Int!){
    questionToggleActive(question_id:$question_id, isActive:$isActive){
      id
    }
  }
`;

const QUESTION_TOGGLE_CLOSED = gql`
  mutation questionToggleClosed($question_id: Int!, $isClosed: Int!){
    questionToggleClosed(question_id:$question_id, isClosed:$isClosed){
      id
    }
  }
`;


class QuizAdminLis extends Component {


  state = {
    
  };


  state = {
    alertInfo: "",
    alertInfo_updateQuestion: "",
    checkboxValue_isActive: false,
    checkboxValue_isText: false,
    checkboxValue_isDefault: true,
    show_answer_form : false,
    number_of_answers: 3
  }

  constructor() {
    super();
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }


  activeToggle(item) {
    // Só utiliza a var item.approved, quando o State não está definido, pois o item.approved é fixo
    var approved_var = (this.state[item.id+"_isActive"] !== undefined ? this.state[item.id+"_isActive"] : item.isActive);
    var approved = (approved_var ? 0 : 1);
    form_client.mutate({
      mutation: QUESTION_TOGGLE_ACTIVE,
      variables: {
        question_id:item.id, 
        isActive: approved
      }
    });
    // Alterar o state
    this.setState(state => ({
      ...state,
      [item.id+"_isActive"]: approved
    }));
  }


  closedToggle(item) {
    // Só utiliza a var item.approved, quando o State não está definido, pois o item.approved é fixo
    var approved_var = (this.state[item.id+"_isClosed"] !== undefined ? this.state[item.id+"_isClosed"] : item.isClosed);
    var approved = (approved_var ? 0 : 1);
    form_client.mutate({
      mutation: QUESTION_TOGGLE_CLOSED,
      variables: {
        question_id:item.id, 
        isClosed: approved
      }
    });
    // Alterar o state
    this.setState(state => ({
      ...state,
      [item.id+"_isClosed"]: approved
    }));
  }

  render() {


    return (

          <div>
                    <Query
                    client={form_client}
                    query={GET_QUESTIONS}
                    variables={{
                      lawsuit_id: parseInt(this.props.lawsuit.id), 
                      type: "quiz",
                    }}
                    fetchPolicy='no-cache'
                  >
                    {({ loading, error, data }) => {
                      if (loading) return <div>Carregando...</div>;
                      if (error) return <div>Erro :(</div>;
                        
                        if(!data.forms_collections) {
                          return <div></div>;
                        } 

                        return data.forms_collections.forms.map((form) => (
                            <div key={form.id}>
                              <Table striped hover>
                                <tbody>
                                  {
                                    form.questions.map((question) => (
                                      <tr key={question.id}>
                                        <td>{question.title}</td>
                                        <td width={200}>
                                          <Button bsStyle={(this.state[question.id+"_isActive"] === undefined && question.isActive === 1) || this.state[question.id+"_isActive"] === 1? "danger" : "info"} fill onClick={() => this.activeToggle(question)}>
                                            {(this.state[question.id+"_isActive"] === undefined && question.isActive === 1) || this.state[question.id+"_isActive"] === 1? "Ocultar" : "Exibir"}
                                          </Button>
                                        </td>
                                        <td width={250}>
                                          {
                                            ((this.state[question.id+"_isActive"] === undefined && question.isActive === 1) || this.state[question.id+"_isActive"] === 1)
                                            &&
                                            <Button className="btn btn-fill margin-left" bsStyle={(this.state[question.id+"_isClosed"] === undefined && question.isClosed === 1) || this.state[question.id+"_isClosed"] === 1? "danger" : "info"} onClick={() => this.closedToggle(question)}>
                                              {(this.state[question.id+"_isClosed"] === undefined && question.isClosed === 1) || this.state[question.id+"_isClosed"] === 1? "Abrir votação novamente" : "Terminar votação"}
                                            </Button>
                                          }
                                        </td>
                                      </tr>
                                    ))
                                  }
                                </tbody>
                              </Table>
                            </div>
                          ));
                        }}
                      </Query>
                  

          </div>
      

      
    );
  }
}

export default QuizAdminLis;
