import React, { Component, useState } from "react";
import {
  Grid,
  Row,
  Col,
  Alert,
  Table,
  FormGroup,
  ControlLabel
} from "react-bootstrap";

import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

import ReactLoading from 'react-loading';

import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; //

import Modal from 'react-awesome-modal';
import QuizAdminRegister from "../../components/Quiz/AdminRegister.jsx";
import QuizAdminLis from "../../components/Quiz/AdminList.jsx";


const restLink_form = new HttpLink({ credentials: 'include', uri: "/api/v1/forms/" });
const form_client = new ApolloClient({
  link: restLink_form,
  cache: new InMemoryCache(),
});

const restLink_account = new HttpLink({ credentials: 'include', uri: "/api/v1/account/" });
const account_client = new ApolloClient({
  link: restLink_account,
  cache: new InMemoryCache(),
});



class QuizAdmin extends Component {

  loggedQuestionInterval;
  colors_btn = {
    'default': 'info',
    'Sim': 'success',
    'Não': 'danger',
    'Abstenção': 'warning'
  };
  answerTexts = []; // Respostas das questões abertas

  state = {
    visible : false,
    loading_page: false,
    addQuiz: false
  };

  constructor() {
    super();

    
  }


  componentDidMount() {

  }

  componentWillUnmount() {
  }



  handleSubmit_addQuiz = (event) => {
    event.preventDefault();


    this.setState({
      loading_page: true
    });

    setTimeout(() => {
      this.setState({
        loading_page: false,
        addQuiz: false
      });
    },2000);
    
  }

  


  // Verfica se o voto já foi feito, para colocar a cor adequada no botão
  getClassBtn(answer, question_id) {
    var keyVote = question_id;
    if(typeof this.state.VotesArray[keyVote] != "undefined" && this.state.VotesArray[keyVote] == answer.id) {
      return (this.colors_btn[answer.title] ? this.colors_btn[answer.title] : "info");
    }
    return "default";
  }

  openModal() {
    this.setState({
        visible : true
    });
  }

  closeModal() {
      this.setState({
          visible : false
      });
  }
  

  render() {


    return (

          <div>
              <Button className="btn-absolute-bottom btn btn-fill btn-success" fill onClick={() => this.openModal()}>
                GERENCIAR QUIZ
              </Button> 


            <Modal 
                    visible={this.state.visible}
                    width="80%"
                    height="90%"
                    effect="fadeInUp"
                    onClickAway={() => this.closeModal()}
                >
                   

              <div className="content content-modal">

                  <Row>
                    <Col md={12}>
                    <Button bsStyle="danger" pullRight fill onClick={() => this.closeModal()}>
                      FECHAR
                    </Button>

                    

                    {
                      this.state.addQuiz &&
                      <div>
                        <h1>Adicionar Novo Quiz</h1>

                        <QuizAdminRegister lawsuit={this.props.lawsuit} handleSubmit={this.handleSubmit_addQuiz} />
                    
                        <Button bsStyle="warning" fill onClick={() => this.setState({
                          addQuiz: false
                        })}>
                          Cancelar
                        </Button>

                      </div>
                    }

                    {
                      !this.state.addQuiz &&
                      <div>
                        <h1>Quiz</h1>
                        <Button bsStyle="info" fill onClick={() => this.setState({
                          addQuiz: true
                        })}>
                          Adicionar Quiz
                        </Button>
                        <QuizAdminLis lawsuit={this.props.lawsuit} />

                        
                        
                      </div>
                    }


                    {
                      this.state.loading_page &&
                        <Alert bsStyle="info">
                          <div className="txt-center">
                            <ReactLoading type="spinningBubbles" color="#fff" />
                            <strong>Registrando...</strong>
                            <div className="loading_message">
                              Aguarde...
                            </div>
                          </div>
                        </Alert>
                    }
                      
                  
                      
                    </Col>
                    
                  </Row>
              </div>
            </Modal>

      </div>
      

      
    );
  }
}

export default QuizAdmin;
