import React, { Component } from "react";






export class Scorm extends Component {
  

  

  render() {
    
    
    return (
      <div>
        <div id="twig-scorm-data"
          student-id="17305"
          sco-id="251"
          student-name="Vitor, Samba"
          score-raw="0"
          score-min="0"
          score-max="0"
          total-time="0"
          credit="no-credit"
          lesson-status="incomplete"
          lesson-location="0"
          exit=""
          entry=""
          lesson-mode="normal"
          suspend-data=""
          course-id="244"
          lesson-id="537"
          mastery-score=""
      >
      </div>
      <div className="embed-responsive embed-responsive-4by3 card shadow-sm">
          <iframe  className="embed-responsive-item" allowFullScreen webkitallowfullscreen="true" mozallowfullscreen="true" src={"/lms/assets/scorm/"+this.props.course.scorm} scrolling="no" frameBorder="0" allow="geolocation; microphone; camera; encrypted-media; midi"></iframe>
      </div>

      </div>
      
    );
  }
}


export default Scorm;
