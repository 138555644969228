import React, { Component } from "react";
import ReactHtmlParser from 'react-html-parser';


export class Message extends Component {


  render() {

    var time = "";
    if(typeof this.props.time_insert !== "undefined") {
      var date_time_insert = new Date(this.props.time_insert * 1000);
      time = date_time_insert.getHours()+":"+date_time_insert.getMinutes();
    }

    var time_response_from = "";
    if(this.props.response_from && this.props.response_from.time_insert  !== "undefined") {
      var date_time_insert_response_from = new Date(this.props.response_from.time_insert * 1000);
      time_response_from = date_time_insert_response_from.getHours()+":"+date_time_insert_response_from.getMinutes();
    }

    return (
      <div className={(this.props.is_admin ? "chat_message_admin" : "chat_message_user") + (this.props.is_fixed ? " chat_message_fixed" : "") }>
        <div className={this.props.response_from ? "chat_message chat_response" : "chat_message"}>
          {this.props.response_from ?
          <div className="chat_answered_question">
            <b>{this.props.response_from.user_name}</b>
            <p>{ReactHtmlParser(this.props.response_from.message)}</p>
            {
              time_response_from !== "" &&
              <div className="chat_time">{time_response_from}</div>
            }
          </div>
          :
          <div></div>
          }
          <div className="chat_answer">
            <div className="chat_thumb" title={this.props.user_name}>
              {this.props.user_name.charAt(0)}
            </div>
              <b>{this.props.user_name}</b>
            <p>{ReactHtmlParser(this.props.message)}</p>
            {
              time !== "" &&
              <div className="chat_time">{time}</div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default Message;
