import React, { Component } from "react";
import ForumList from "./List.jsx";
import ForumMessages from "./Messages.jsx";



export class Forum extends Component {



  constructor() {
    super();
    this.state = {
      forum_id: false, // ID do fórum que o usuário clicou para abrir
    };
  }


  onChange_forum_id = (forum_id) => {
    this.setState({
      forum_id: forum_id
    });
  }


  render() {


    return (
      <div className="">
        <div className="content">
          <div className="container">
            <div className="">
              <h3 name="forum">Comentários</h3>
              <p>Este é um espaço em que você pode interagir com os demais participantes do Curso. As dúvidas sobre o conteúdo das aulas devem ser enviadas na aula ao vivo, via chat.
              </p>
              <p>Obs: Este espaço tem a finalidade acima especificada.
                Manifestações diversas ou inoportunas serão excluídas pelo administrador.</p>
              {
                !this.state.forum_id &&
                <ForumList module_name={this.props.module_name} external_parent_id={this.props.external_parent_id} chageForumId={this.onChange_forum_id} />
              }
              {
                this.state.forum_id &&
                <ForumMessages forum_id={this.state.forum_id} module_name={this.props.module_name} external_parent_id={this.props.external_parent_id} chageForumId={this.onChange_forum_id} />
              }

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Forum;
