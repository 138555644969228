import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import { Grid, Row, Col } from "react-bootstrap";


import { NavItem, Nav, NavDropdown, MenuItem } from "react-bootstrap";


import { Query } from "react-apollo";
import gql from "graphql-tag";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';


import { Card } from "../../components/Card/Card.jsx";
import { StatsCard } from "../../components/StatsCard/StatsCard.jsx";
import { Tasks } from "../../components/Tasks/Tasks.jsx";
import banner_default from "../../assets/img/banner-medias.png";

const restLink_dam = new HttpLink({ 
  uri: "/api/v1/dam/",
  credentials: 'include'
});
const dam_client = new ApolloClient({
  link: restLink_dam,
  cache: new InMemoryCache(),
});


const restLink_lms = new HttpLink({
   uri: "/api/v1/lms/",
   credentials: 'include'
   });
const lms_client = new ApolloClient({
  link: restLink_lms,
  cache: new InMemoryCache(),
});


const GET_CURSES = gql`
query getCourses($category_id:Int!){
  courses_collections(type:"online",category_id:$category_id){
    courses{
      id,title,thumbnail
    }
  }
}
`;

const GET_CATEGORIES = gql`{
  category_collections(parent:null){
    categories{
      id,title
    }
  }
}
`;

const GET_SUBCATEGORIES = gql`
query getCagegories($parent_id:Int!){
    category_collections(parent:$parent_id){
      categories{
        id,title
      }
    }
  }
`;

const GET_CATEGORY = gql`
  query getCategory($category_id: Int!){
    category(id:$category_id){
      banner
    }
  }
`;



class Courses extends Component {

  
  createLegend(json) {
    var legend = [];
    for (var i = 0; i < json["names"].length; i++) {
      var type = "fa fa-circle text-" + json["types"][i];
      legend.push(<i className={type} key={i} />);
      legend.push(" ");
      legend.push(json["names"][i]);
    }
    return legend;
  }

  render() {

    this.category_id = (this.props.match.params.category_id ? this.props.match.params.category_id : 0);
    
   
    return (
        <div>
          <Query
        client={dam_client}
        query={GET_CATEGORY}
        variables={{category_id:this.category_id}}
        >
        {({ loading, error, data }) => {
          if (loading) return <div>Carregando...</div>;
          if (error) return <div>Erro :(</div>;
          
          return (
            <img className="banner_content" src={data.category && data.category.banner ? "/dam/assets/"+data.category.banner : banner_default} />
            );
          }}
        </Query>

<div className="content">
<div className="container">
<Grid fluid>
          <Row>
<div className="contentMenu">
        
        <Nav>
        <NavItem 
        data-gtm-type="click" 
        data-gtm-clicktype="submenu" 
        data-gtm-name="Todos"
        href={"/#/cursos-online/"}>Todos</NavItem>
        <Query
          client={dam_client}
          query={GET_CATEGORIES}
        >
          {({ loading, error, data }) => {
            if (loading) return <div>Carregando...</div>;
            if (error) return <div>Erro :(</div>;
              
              if(!data.category_collections.categories) {
                return (<div>(Vazio)</div>);
              } 

            return data.category_collections.categories.map((categ) => (
              
              <NavDropdown
                eventKey={2}
                title={categ.title}
                id="basic-nav-dropdown-right"
              >
                    <Query
                          client={dam_client}
                          query={GET_SUBCATEGORIES}
                          variables={{parent_id:categ.id}}
                        >
                          {({ loading, error, data }) => {
                            if (loading) return <div>Carregando...</div>;
                            if (error) return <div>Erro :(</div>;
                              
                              if(!data.category_collections) {
                                return (<div>(Vazio)</div>);
                              } 

                            return data.category_collections.categories.map((sub_categ) => (
                              <NavItem 
                              data-gtm-type="click" 
                              data-gtm-clicktype="submenu" 
                              data-gtm-name={sub_categ.title}
                              eventKey={sub_categ.id} href={"/#/cursos-online/"+sub_categ.id}>{sub_categ.title}</NavItem>
                            ));
                        }}
                      </Query>
              </NavDropdown>
          ));
        }}
      </Query>
        </Nav>
      </div>
      </Row>
      </Grid>

        <Grid fluid>
          <Row>
            

              <Query
                client={lms_client}
                query={GET_CURSES}
                variables={{category_id:this.category_id}}
              >
                {({ loading, error, data }) => {
                  if (loading) return <div>Carregando...</div>;
                  if (error) return <div>Erro :(</div>;
                    
                  return data.courses_collections.courses.map((item) => (
                    
                    <Col lg={4} sm={6} className="col">
                    <div className="media">
                      <a 
                      data-gtm-type="click" 
                      data-gtm-clicktype="link" 
                      data-gtm-name={item.title}
                      href={"/#/curso/"+item.id} >
                      <img src={"/lms/assets/"+item.thumbnail} />
                      <div>{item.title}</div>
                      </a>
                    </div>
                    </Col>
      ));
      }}
      </Query>

      </Row>
      </Grid>
      </div>
      </div>
    </div>
    );
  }
}

export default Courses;
