import React, { Component } from "react";
import logo from "../../assets/img/Zenite_Branco.svg";

class HeaderM extends Component {
  
  
  render() {


    return (
      <div className="header-banner">
        <img src={logo} className="" width="90"/>
      </div>
    );
  }
}

export default HeaderM;
