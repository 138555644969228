import React, { Component, useState } from "react";
import {
  Grid,
  Row,
  Col,
  Alert,
  Table,
  FormGroup,
  ControlLabel
} from "react-bootstrap";

import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

import ReactLoading from 'react-loading';

import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; //

import Modal from 'react-awesome-modal';


import ReactMarkdown from 'react-markdown';
import htmlParser from 'react-markdown/plugins/html-parser';



class CertificateList extends Component {


  render() {

    const parseHtml = htmlParser({
      isValidNode: node => node.type !== 'script',
      processingInstructions: [/* ... */]
    });

    return (


      <div className="content certificate-page">
      <div className="container">  
            <div className="">        
        <div>
          <h3 name="certificate">Certificado</h3>

            <Row className="border-bottom">
              <Col md={12}>
                
                  {
                    this.props.lawsuit && this.props.lawsuit.certificate_description
                    ?
                    <p>
                    <ReactMarkdown
                        source={this.props.lawsuit.certificate_description}
                        escapeHtml={false}
                        astPlugins={[parseHtml]}
                        linkTarget="_blank"
                      />
                    </p>
                    :
                    <p>
                        O certificado será emitido em até 15 dias após o término do evento e enviado ao e-mail indicado na inscrição. Esse envio será realizado por meio da ferramenta E-certificado. O percentual da frequência que constará no certificado será calculado de acordo com presença nas aulas <i>online</i> (<strong>ao vivo</strong>) aferida pelo acesso com o login e senha no sistema. A audiência aos <i>replays</i> não é considerada para fins de certificação.
                    </p>
                  }
                
              </Col>
            </Row>
        </div>
                
            
        </div>
            </div>
      </div>
      
    );
  }
}

export default CertificateList;
