import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Navbar, Nav, NavItem, NavDropdown } from "react-bootstrap";

import HeaderLinks from "../Header/HeaderLinks.jsx";

import imagine from "../../assets/img/sidebar-4.jpg";

import dashboardRoutes from "../../routes/dashboard.jsx";
import logo from "../../assets/img/Zenite_Branco.svg";


class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth
    };
  }
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }
  render() {
    const sidebarBackground = {
      backgroundImage: "url(" + imagine + ")"
    };
    
    if(window.location.hash.indexOf("#/event") === 0) {
      return (
        <div></div>
          );
    }

    return (
      <div
        id="sidebar"
        className="sidebar"
        data-color="black"
        data-image={imagine}
      >
        {/* <div className="sidebar-background" /> */}
        <div className="logo">
          <a
            href="/"
          >
            <div>
              <img src={logo} /> 
            </div>
          </a>
        </div>
        <div className="sidebar-wrapper">
          <ul className="nav">
            {this.state.width <= 991 ? <HeaderLinks /> : null}
            {dashboardRoutes.map((prop, key) => {
              if (!prop.redirect && prop.showSidebar)
              if(prop.children) {
                return (
                  <li
                    className={
                      prop.upgrade
                        ? "active active-pro"
                        : this.activeRoute(prop.path)
                    }
                    key={key}
                  >
                    <NavDropdown
                      title={prop.name}
                      className="nav-link"
                      //activeClassName="active"
                    >
                      {prop.children.map((prop, key) => {
                      return(
                        <NavLink
                        to={prop.path}
                        className="nav-link" 
                        //activeClassName="active"
                      >
                        <p>{prop.name}</p>
                      </NavLink>
                      );
                    })}
                    </NavDropdown>
                  </li>
                );
              } else {

                return (
                  <li
                    className={
                      prop.upgrade
                        ? "active active-pro"
                        : this.activeRoute(prop.path)
                    }
                    key={key}
                  >
                    <NavLink
                      to={prop.path}
                      className="nav-link"
                      //activeClassName="active"
                    >
                      <p>{prop.name}</p>
                    </NavLink>
                  </li>
                );
              }
              return null;
            })}
          </ul>
        </div>
      </div>
    );
  }
}

export default Sidebar;
