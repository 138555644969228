import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import { Grid, Row, Col } from "react-bootstrap";


import { NavItem, Nav, NavDropdown, MenuItem } from "react-bootstrap";


import { Query } from "react-apollo";
import gql from "graphql-tag";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';


import { Card } from "../../components/Card/Card.jsx";
import { StatsCard } from "../../components/StatsCard/StatsCard.jsx";
import { Tasks } from "../../components/Tasks/Tasks.jsx";





class Courses extends Component {

  
  createLegend(json) {
    var legend = [];
    for (var i = 0; i < json["names"].length; i++) {
      var type = "fa fa-circle text-" + json["types"][i];
      legend.push(<i className={type} key={i} />);
      legend.push(" ");
      legend.push(json["names"][i]);
    }
    return legend;
  }

  render() {
    
   
    return (
        

<div className="content">
<div className="container">
        

        <Grid fluid>
          <Row>
                  
          <Col lg={4} sm={6} className="col">
          (Vazio)
          </Col>
     
      </Row>
      </Grid>
      </div>
      </div>
    );
  }
}

export default Courses;
