import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import { Grid, Row, Col } from "react-bootstrap";

import Slider from "react-slick";


import { Query } from "react-apollo";
import gql from "graphql-tag";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';



import { StatsCard } from "../../components/StatsCard/StatsCard.jsx";


const restLink_dam = new HttpLink({ 
  uri: "/api/v1/dam/",
  credentials: 'include'
});
const dam_client = new ApolloClient({
  link: restLink_dam,
  cache: new InMemoryCache(),
});

const restLink_lms = new HttpLink({ 
  uri: "/api/v1/lms/",
  credentials: 'include'
});
const lms_client = new ApolloClient({
  link: restLink_lms,
  cache: new InMemoryCache(),
});

const restLink_cms = new HttpLink({ 
  uri: "/api/v1/cms/",
  credentials: 'include'
});
const cms_client = new ApolloClient({
  link: restLink_cms,
  cache: new InMemoryCache(),
});

const restLink_analytics = new HttpLink({ 
  uri: "/api/v1/analytics/",
  credentials: 'include'
});
const analytics_client = new ApolloClient({
  link: restLink_analytics,
  cache: new InMemoryCache(),
});



const GET_DAM = gql`
  query getDam($module_name: String!){
    assets_collections(limit:5,order:"DESC",module_name:$module_name) {
      assets{
        id, title, asset, directlink
      }
    }
  }`;


const GET_CURSES = gql`{
    courses_collections(type:"online"){
      courses{
        id,title,thumbnail
      }
    }
  }
`;

const GET_VIDEOS_TRENDING = gql`{
  videos_trending{
    videos{
      id, title, thumbnail
    }
  }
}
`;

const GET_TESTIMONY = gql`
  {
  contents_collections(limit:4,order:"DESC",module_name:"Testimony") {
      contents{
        id, title, content, thumbnail, directlink
      }
    }
  }
`;


const ANALYTICS_INSERT_BANNER_CLICK = gql`
  mutation createBanner($banner_id: Int!, $banner_title: String!) {
    createBanner(input:{banner_id: $banner_id, banner_title: $banner_title}) {
    id
    }
  }
`;



class Dashboard extends Component {
  createLegend(json) {
    var legend = [];
    for (var i = 0; i < json["names"].length; i++) {
      var type = "fa fa-circle text-" + json["types"][i];
      legend.push(<i className={type} key={i} />);
      legend.push(" ");
      legend.push(json["names"][i]);
    }
    return legend;
  }

  

  render() {

    const windowWidth = window.innerWidth;

    var settings_banner = {
      autoplaySpeed: 5000,
      autoplay: true,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    var settings_courses = {
      autoplay: false,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: (windowWidth <= 987 ? 2 : 4),
      slidesToScroll: (windowWidth <= 987 ? 2 : 4)
    };
    return (
        
      <div>
      
      <div className="banner">
        <Query
            client={dam_client}
            variables={{module_name:"Banner"}}
            query={GET_DAM}
          >
            {({ loading, error, data }) => {
              if (loading) return <div>Carregando...</div>;
              if (error) return <div>Erro :(</div>;
                
                if(!data.assets_collections) {
                  return (<div>(Vazio)</div>);
                } 
                return (
                  <Slider {...settings_banner}>
                        {data.assets_collections.assets.map((item, i) => (
                          <div key={item.id}>
                            <a 
                            data-gtm-type="click" 
                            data-gtm-clicktype="banner" 
                            data-gtm-banner-index={i}
                            onClick={
                              // Registra que o usuário clicou no banner
                              () => analytics_client.mutate({
                                    variables:{banner_id:item.id, banner_title:item.title },
                                    mutation: ANALYTICS_INSERT_BANNER_CLICK
                                })
                            } href={item.directlink} target="_blank">
                              <img src={"/dam/assets/"+item.asset} />
                            </a>
                          </div>
                        ))}
                  </Slider>
                )
          }}
          </Query>
      </div>
      
      <div className="content">
      
      <div className="container box-home">
        <Grid fluid>
          <Row>
            <Col lg={4} sm={6}>
              <a
              data-gtm-type="click" 
              data-gtm-clicktype="card" 
              data-gtm-name="Agenda sala virtual"
              href="/#/sala-virtual" >
              <StatsCard
                bigIcon={<i className="ps-ico ps-glyph ps-ico-calendar" />}
                statsTitle="Agenda sala virtual"
                statsText="Acompanhe a agenda das transmissões ao vivo"
              />
              </a>
            </Col>
            <Col lg={4} sm={6}>
              <a 
              data-gtm-type="click" 
              data-gtm-clicktype="card" 
              data-gtm-name="Certificados"
              href="/#/certificados">
              <StatsCard
                bigIcon={<i className="pe-7s-medal" />}
                statsTitle="Certificados"
                statsText="Agora é a hora de ver seus certificados. Quanto mais, melhor!"
              />
              </a>
            </Col>
            <Col lg={4} sm={6}>
              <a 
              data-gtm-type="click" 
              data-gtm-clicktype="card" 
              data-gtm-name="Cursos"
              href="/#/cursos-online">
              <StatsCard
                bigIcon={<i className="pe-7s-study" />}
                statsTitle="Cursos"
                statsText="Veja cursos disponíveis para conseguir seu certificado."
              />
              </a>
            </Col>
          </Row>
    </Grid>
    </div>
    </div>
      
      <div className="bg-gray">
      <div className="content">

      <div className="container">
    <Grid className="rowCarousel">
          <Row>
            <Col md={12}>
                <h2>
                  Recomendados para você
                </h2>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
                <Query
                client={lms_client}
                query={GET_CURSES}
                >
                {({ loading, error, data }) => {
                  if (loading) return <div>Carregando...</div>;
                  if (error) return <div>Erro :(</div>;
                    
                    return (
                      <Slider {...settings_courses}>
                            {data.courses_collections.courses.map((item) => (
                              <div key={item.id} className="course-item">
                                <a 
                                data-gtm-type="click" 
                                data-gtm-clicktype="link" 
                                data-gtm-name={item.title}
                                href={"/#/curso/"+item.id}>
                                  <img src={"/lms/assets/"+item.thumbnail} />
                                  <div className="title">{item.title}</div>
                                </a>
                              </div>
                            ))}
                      </Slider>
                    )
              }}
              </Query>
            </Col>
          </Row>

        </Grid>
        </div>
        </div>
        </div>
      
      <div className="content">

      <div className="container">
        <Grid  className="rowCarousel">

        <Row>
            <Col md={12}>
                <h2>
                  Mais assistidos
                </h2>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
                <Query
                client={lms_client}
                query={GET_VIDEOS_TRENDING}
                >
                {({ loading, error, data }) => {
                  if (loading) return <div>Carregando...</div>;
                  if (error) return <div>Erro :(</div>;
                    
                    return (
                      <Slider {...settings_courses}>
                            {data.videos_trending.videos.map((item) => (
                              <div key={item.id} className="course-item">
                                <a 
                                data-gtm-type="click" 
                                data-gtm-clicktype="video" 
                                data-gtm-name={item.title}
                                href={"/#/video/"+item.id}>
                                <img src={item.thumbnail} />
                                <div className="title">{item.title}</div>
                                </a>
                              </div>
                            ))}
                      </Slider>
                    )
              }}
              </Query>
            </Col>
          </Row>
        </Grid>
        </div>
      </div>


<div className="bg-gray">
<div className="content">

      <div className="container">
      <Row>
            <Col md={12}>
                <h2>
                 Destaques
                </h2>
            </Col>
          </Row>  
          <Row>
            <Query
                client={cms_client}
                query={GET_TESTIMONY}
                >
                {({ loading, error, data }) => {
                  if (loading) return <div>Carregando...</div>;
                  if (error) return <div>Erro :(</div>;
                    
                   return data.contents_collections.contents.map((item) => (
                      <Col md={3}>
                        <a
                        data-gtm-type="click" 
                        data-gtm-clicktype="link" 
                        data-gtm-name={item.title}
                        href={item.directlink} target="_blank">
                          <div className="depoiment">
                            <img src={"/cms/assets/"+item.thumbnail} />
                            <div><br />{item.content}</div>
                            <div><br /><b>{item.title}</b></div>
                          </div>
                        </a>
                      </Col>
                    ))
              }}
              </Query>
            
            </Row>
      </div>
      </div>
      </div>
      </div>

    );
  }
}

export default Dashboard;
