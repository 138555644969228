import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  Alert,
  Table,
  FormGroup,
  ControlLabel
} from "react-bootstrap";

import { ApolloClient } from 'apollo-client';
import { Query } from "react-apollo";
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';
import { FormInputs } from "../FormInputs/FormInputs.jsx";
import Button from "../CustomButton/CustomButton.jsx";


const restLink_forum = new HttpLink({
   uri: "/api/v1/forum/",
   credentials: 'include'
   });
const forum_client = new ApolloClient({
  link: restLink_forum,
  cache: new InMemoryCache(),
});



const CREATE_FORUM = gql`
mutation createForumByUser($title: String!, $module_name: String!, $external_parent_id: Int!, $first_message: String!){
  createForumByUser(input:{
    title: $title,
    module_name: $module_name,
    external_parent_id: $external_parent_id,
		first_message: $first_message
  }) {
    id
  }
}
`;


const GET_FORUM_COLLECTION = gql`
query forum_collections($module_name: String!, $external_parent_id: Int!){
  forum_collections(module_name: $module_name, external_parent_id:$external_parent_id) {
    forums {
      id
      title
    }
  }
}
`;



export class ForumList extends Component {



  constructor() {
    super();
    this.state = {

      forum_collections: false,

      loading: false, // Loading de quando estiver cadastrando algo
      msg_error: "", // Mensagem de erro
      msg: "", // Mensagem de sucesso
      new_forum: false, // Abre o formulário para cadastrar um novo fórum

      // Cadastro de novo forum
      title: "", // Título do forum
      first_message: "" // A descrição é inserida como a primeira mensagem do fórum
    };
  }

  onChange_title = (event) => {
    this.setState({
      title: event.target.value
    });
  }
  onChange_first_message = (event) => {
    this.setState({
      first_message: event.target.value
    });
  }

  async createForum() {

    if(this.state.title === "" || this.state.first_message === "") {
      this.setState({
        msg_error: "Preencha todos os campos"
      });
    }
    else {
      this.setState({
        msg_error: "",
        loading: true
      });
      await forum_client.mutate({
        mutation: CREATE_FORUM,
        variables: {
          title: this.state.title,
          first_message: this.state.first_message,
          module_name: this.props.module_name,
          external_parent_id: this.props.external_parent_id
        }
      });

      
      setTimeout(() => {
        this.setState({
          loading: false,
          new_forum: false
        });
        this.getVars();
      }, 2000);
    }
    

  }


  async getVars() {

    var forum_collections = (await forum_client.query({
      fetchPolicy: 'no-cache',
      query: GET_FORUM_COLLECTION,
      variables: {
        module_name: this.props.module_name,
        external_parent_id: this.props.external_parent_id
      }
    })).data.forum_collections.forums;

    this.setState({
      forum_collections
    });
  }


  componentDidMount() {
    this.getVars();
  }
  
  render() {
   
    if(!this.state.forum_collections) {
      return <h2>Carregando...</h2>
    }

    return (
        <div>

          {
            //---------- LISTA OS FÓRUNS ----------//
            !this.state.new_forum &&
            <div>
              <Button bsStyle="info btn-zenite-text btn-zenite-text-round" fill onClick={() => this.setState({ new_forum: true })}>
                Inserir comentário
              </Button>
              <hr />
              {
                this.state.forum_collections.map((item) => ( 
                <Row className="border-bottom d-flex justify-center align-center"  key={item.id}>
                  <Col md={9}>
                    <p><strong>{item.title}</strong></p>
                  </Col>
                  <Col md={3} className="text-right">
                    <Button bsStyle="info btn-zenite-text btn-zenite-text-round mb-1" fill pullRight onClick={() => this.props.chageForumId(item.id)}>
                      Entrar
                    </Button>
                  </Col>
                </Row>
                ))
              }
              </div>
          }

          { 
            //---------- FORMULÁRIO PARA CADASTRAR NOVO FÓRUM ----------// 
            this.state.new_forum &&
            <div>
              
              <div className="margin-10">
                <FormInputs
                  ncols={["col-md-12"]}
                  proprieties={[
                    {
                      name: "title",
                      label: "Assunto",
                      type: "text",
                      bsClass: "form-control",
                      placeholder: "Escreva o assunto com poucas palavras",
                      defaultValue: this.state.title,
                      onChange: this.onChange_title
                    }
                  ]}
                />
                <FormInputs
                  ncols={["col-md-12"]}
                  proprieties={[
                    {
                      name: "first_message",
                      label: "Mensagem",
                      type: "text",
                      bsClass: "form-control",
                      placeholder: "Escreva sua mensagem para iniciar o grupo de discussão",
                      defaultValue: this.state.first_message,
                      onChange: this.onChange_first_message
                    }
                  ]}
                />
                <Button bsStyle="danger" fill onClick={() => this.setState({ new_forum: false })}>
                  Cancelar
                </Button>
                <Button bsStyle="info" fill pullRight onClick={() => this.createForum()}>
                Inserir comentário
                </Button>
                {
                  this.state.msg_error !== "" &&
                  <Alert bsStyle="danger">
                    Preencha todos os campos
                  </Alert>
                }
                {
                  this.state.loading &&
                  <Alert bsStyle="info">
                  <div className="txt-center">
                    <strong>Registrando...</strong>
                    <div className="loading_message">
                      Aguarde...
                    </div>
                  </div>
                </Alert>
                }
                
              </div>
            </div>
          }

                  
        </div>
        
    );
  }
}

export default ForumList;
