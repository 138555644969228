import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import { Grid, Row, Col } from "react-bootstrap";


import { NavItem, Nav, NavDropdown, MenuItem } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import { Query } from "react-apollo";
import gql from "graphql-tag";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';

const restLink_account = new HttpLink({ credentials: 'include', uri: "/api/v1/account/" });
const account_client = new ApolloClient({
  link: restLink_account,
  cache: new InMemoryCache(),
});


const restLink_form = new HttpLink({ 
  uri: "/api/v1/forms/",
  credentials: 'include'
 });
const form_client = new ApolloClient({
  link: restLink_form,
  cache: new InMemoryCache(),
});

const months = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ']


const GET_FORM = gql`
  query getForms($search: String!, $limit: Int!, $page: Int!, $order: String!, $orderby: String!){
    forms_collections( search: $search, limit: $limit, page: $page, order: $order, orderby: $orderby){
      forms{
        id
        title
        lawsuit_id
        datetimeStartFormat
        datetimeEndFormat
      }
    }
  }
`;

const GET_AUTH_USER = gql`
{
  get_authenticated_user{
    id
    first_name
    type
    is_absent
  }
}
`;


class Courses extends Component {

  state = {
    forms_collections: []
  }
  
  createLegend(json) {
    var legend = [];
    for (var i = 0; i < json["names"].length; i++) {
      var type = "fa fa-circle text-" + json["types"][i];
      legend.push(<i className={type} key={i} />);
      legend.push(" ");
      legend.push(json["names"][i]);
    }
    return legend;
  }

  minutes_with_leading_zeros(dt) 
  { 
    return (dt.getMinutes() < 10 ? '0' : '') + dt.getMinutes();
  }

  getQuestionCurrent = async () => {
    try {
      const forms_collections = (await form_client.query({
        variables: {
          search: "", 
          limit: 2000, 
          page: 1, 
          order: "DESC", 
          orderby: "id"
        },
        query: GET_FORM,
        fetchPolicy: 'no-cache'
      })).data.forms_collections.forms;

      this.setState({forms_collections});
      
    } catch(err) {
      console.error(err)
    }

  }

  componentDidMount() {
    this.getQuestionCurrent();
    this.loggedQuestionInterval = setInterval(this.getQuestionCurrent, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.loggedQuestionInterval)
  }

  render() {
  
    return (
        
      <div>
       

<div className="content">

    
<div className="container">
        <Grid fluid>

        <Query
            fetchPolicy='no-cache'
            client={account_client}
            query={GET_AUTH_USER}
            >
            {({ loading, error, data }) => {
              if (loading) return <div>Carregando...</div>;
              if (error) return <div>Erro :(</div>;


              return (

                <div>

                  {!this.state.forms_collections.length &&
                    <Row>
                        <Col lg={12}>
                          <h1>Carregando...</h1>
                          <h4>Aguarde...</h4>
                        </Col>
                    </Row>
                  }

                  {this.state.forms_collections.length !== 0 &&
                    <Row className="border-bottom">
                      <Col lg={12}><h1>Clique no botão "Abrir" para acessar a votação.</h1></Col>
                    </Row>
                  }
            
                  {this.state.forms_collections.map((item) => (
                        
                        <Row className="border-bottom">
                            <Col lg={2} sm={3} className="col">
                              <div>{new Date(item.datetimeStartFormat).toLocaleDateString("pt-BR")}</div>
                              <div>Início: {new Date(item.datetimeStartFormat).getHours()}:{this.minutes_with_leading_zeros(new Date(item.datetimeStartFormat))}</div>
                              <div>Fim: {new Date(item.datetimeEndFormat).getHours()}:{this.minutes_with_leading_zeros(new Date(item.datetimeEndFormat))}</div>
                            </Col>
                            <Col lg={6} md={2}  sm={7} className="col">
                              <div><p><strong>{item.title}</strong></p></div>
                            </Col>
                            <Col lg={2} sm={3} className="col">
                              <div className="live_item_calendar">
                                  {months[new Date(item.datetimeStartFormat).getMonth()]}
                                  <span>{new Date(item.datetimeStartFormat).getDate()}</span>
                              </div>
                            </Col>
                            <Col lg={2} sm={2} className="col">
                                <a className="btn-fill btn btn-info" href={"/#/votacao/"+item.lawsuit_id+"/"+item.id}>
                                  Abrir
                                </a>
                            </Col>
                        </Row>
                  ))}

                </div>
              );
            }}
          </Query>

      
      </Grid>
      </div>
      </div>


      </div>
    );
  }
}

export default Courses;
