import React, { Component } from "react";

import { ApolloClient } from "apollo-client";
import { Query } from "react-apollo";
import { InMemoryCache } from "apollo-cache-inmemory";
import gql from "graphql-tag";
import { HttpLink } from "apollo-link-http";
import Button from "../CustomButton/CustomButton.jsx";
import Message from "./Message.jsx";
import Picker from "emoji-picker-react";

import getCookie from "../Cookie/Cookie.jsx";

const restLink_account = new HttpLink({
  uri: "/api/v1/account/",
  credentials: "include",
});
const account_client = new ApolloClient({
  link: restLink_account,
  cache: new InMemoryCache(),
});

const restLink_chat = new HttpLink({
  uri:
    window.location.host === "zenite.local"
      ? "http://pluzchat.local/api/v1/"
      : "https://pluzchat.pluz.app/api/v1/",
  credentials: "include",
});
const chat_client = new ApolloClient({
  link: restLink_chat,
  cache: new InMemoryCache(),
});

const SAVE_CHAT_MSG = gql`
  mutation AddTodo(
    $user_id: Int!
    $user_name: String!
    $chat_id: Int!
    $message: String!
    $response_to_user_id: Int!
  ) {
    createChatMessage(
      input: {
        user_id: $user_id
        user_name: $user_name
        chat_id: $chat_id
        message: $message
        response_to_user_id: $response_to_user_id
      }
    ) {
      id
    }
  }
`;

const GET_CHAT_MSG = gql`
  query getChatMgs(
    $user_id: Int!
    $chat_id: Int!
    $limit: Int!
    $offset: Int!
    $show_my_msgs: Int!
    $first_request: Int!
  ) {
    chat_message_collections_by_user(
      user_id: $user_id
      chat_id: $chat_id
      limit: $limit
      offset: $offset
      show_my_msgs: $show_my_msgs
      first_request: $first_request
    ) {
      messages {
        is_admin
        user_name
        message
        time_insert
        response_from {
          user_name
          message
          time_insert
        }
      }
    }
  }
`;

const GET_CHAT_MSG_FIXED = gql`
  query chat_message_fixed($chat_id: Int!) {
    chat_message_fixed(chat_id: $chat_id) {
      is_admin
      user_name
      message
      time_insert
      response_from {
        user_name
        message
        time_insert
      }
    }
  }
`;

const GET_AUTH_USER = gql`
  {
    get_authenticated_user {
      id
      first_and_last_name
      nickname
    }
  }
`;

var scroll_pos = 0;

export class Chat extends Component {
  user_auth;
  start_msgs;
  offset_messages;

  constructor() {
    super();
    this.state = {
      messages: [],
      message_temp: [],
      message_fixed: null,
      active: false,
      text: "",
      loading: true,
    };

    this.start_msgs = true;
  }
  toggleClass = () => {
    const currentState = this.state.active;
    this.setState({ active: !currentState });
  };

  handleChange = (e) => {
    this.setState({ text: e.target.value });
  };

  addEmoji = (e, emojiObject) => {
    let emoji = emojiObject.emoji;
    //console.log(emojiObject);
    this.setState({
      text: this.state.text + emoji,
    });
  };

  scrollMessages() {
    setTimeout(function() {
      var chat_body = document.getElementById("chat_body");

      //console.log("------------------");
      //console.log(chat_body.scrollTop);
      //console.log(scroll_pos);

      if (chat_body) {
        chat_body.scrollTop = chat_body.scrollHeight;
        scroll_pos = chat_body.scrollTop;
      }
    }, 100);
  }

  newUserMessage = async (newMessage, user_name = null) => {
    var $chat_id = parseInt(this.props.chat_id);

    var user_nickname =
      this.user_auth.nickname !== ""
        ? this.user_auth.nickname
        : this.user_auth.first_and_last_name;

    // Se não for passado o nome do usuário no param, pega o o nome do usuário logado
    var $user_name = user_name !== null ? user_name : user_nickname;

    /*
    var all_messages = this.state.messages;
    all_messages.push({user_name:this.user_auth.first_and_last_name,  message:newMessage});

    this.offset_messages++;
    this.setState(state => ({
      ...state,
      messages: all_messages
    }));
    */

    var new_messages = this.state.message_temp;
    new_messages.push(newMessage);

    this.setState((state) => ({
      ...state,
      message_temp: new_messages,
      text: "",
    }));

    //console.log("newUserMessage:");
    this.scrollMessages();

    // Salva a mensagem no banco
    await chat_client.mutate({
      mutation: SAVE_CHAT_MSG,
      variables: {
        user_id: this.user_auth.id,
        user_name: $user_name,
        chat_id: $chat_id,
        message: newMessage,
        response_to_user_id: 0,
      },
    });
  };

  previous_messages = async () => {
    var messages = (
      await chat_client.query({
        query: GET_CHAT_MSG,
        fetchPolicy: "no-cache",
        variables: {
          user_id: this.user_auth.id,
          chat_id: this.chat_id,
          limit: 2000,
          offset: 0,
          show_my_msgs: 1,
          first_request: 1,
        },
      })
    ).data.chat_message_collections_by_user.messages;

    this.setState((state) => ({
      ...state,
      messages: messages,
      loading: false,
    }));
    this.offset_messages = messages.length;
    //console.log("previous_messages:");
    this.scrollMessages();
    this.next_messages();
  };

  next_messages = async () => {
    var new_messages = (
      await chat_client.query({
        query: GET_CHAT_MSG,
        fetchPolicy: "no-cache",
        variables: {
          user_id: this.user_auth.id,
          chat_id: this.chat_id,
          limit: 2000,
          offset: this.offset_messages,
          show_my_msgs: 1,
          first_request: 0,
        },
      })
    ).data.chat_message_collections_by_user.messages;

    // Verifica se tem alguma mensagem fixa
    var message_fixed = (
      await chat_client.query({
        query: GET_CHAT_MSG_FIXED,
        fetchPolicy: "no-cache",
        variables: {
          chat_id: this.chat_id,
        },
      })
    ).data.chat_message_fixed;

    if (new_messages.length) {
      var messages_concat = this.state.messages.concat(new_messages);
      this.setState((state) => ({
        ...state,
        message_temp: [],
        messages: messages_concat,
        message_fixed,
      }));
      this.offset_messages = this.state.messages.length;
      console.log("next_messages:");
      this.scrollMessages();
    } else {
      this.setState((state) => ({
        ...state,
        message_fixed,
      }));
    }

    // tenta pegar mais mensagens após alguns segundos
    var self = this;
    setTimeout(function() {
      self.next_messages();
    }, 33000);
  };

  _handleKeyDown = (e) => {
    if (e.key == "Enter") {
      var message = document.getElementById("input_message").value;
      if (message) {
        this.newUserMessage(message);
        document.getElementById("input_message").value = "";
      }
    }
  };

  start = async () => {
    this.user_auth = (
      await account_client.query({
        query: GET_AUTH_USER,
      })
    ).data.get_authenticated_user;

    this.previous_messages();
  };

  componentDidMount() {
    this.start();
  }

  render() {
    this.chat_id = parseInt(this.props.chat_id);

    if (!this.user_auth) {
      return (
        <div id="chat_body">
          <div className="chat_message">
            <strong>Carregando mensagens...</strong>
          </div>
        </div>
      );
    }

    var user_nickname = "";
    user_nickname =
      this.user_auth.nickname !== ""
        ? this.user_auth.nickname
        : this.user_auth.first_and_last_name;

    if (!this.props.enable) {
      return <div id="chat_body"></div>;
    }

    //if(this.state.loading) {
    //  return <div id="chat_body"><div className="chat_message"><strong>Carregando mensagens...</strong></div></div>;
    //}

    return (
      <div className="chat">
        <div className="chat_header">
          {/* {this.user_auth.first_and_last_name} */}
          Chat
          {/* <div className="chat_label">ONLINE</div> */}
        </div>
        {this.state.message_fixed && (
          <Message
            is_fixed={1}
            user_name={this.state.message_fixed.user_name}
            time_insert={this.state.message_fixed.time_insert}
            message={this.state.message_fixed.message}
            response_from={
              this.state.message_fixed.response_from
                ? this.state.message_fixed.response_from
                : null
            }
            is_admin={
              this.state.message_fixed.is_admin
                ? this.state.message_fixed.is_admin
                : null
            }
          />
        )}
        <div
          id="chat_body"
          className={
            "chat_body" + (this.state.message_fixed ? " chat_is_msg_fixed" : "")
          }
        >
          <div id="chat_messages">
            {this.state.messages.map((message, i) => (
              <Message
                key={i}
                user_name={message.user_name}
                time_insert={message.time_insert}
                message={message.message}
                response_from={
                  message.response_from ? message.response_from : null
                }
                is_admin={message.is_admin ? message.is_admin : null}
              />
            ))}

            {this.state.message_temp.map((message, i) => (
              <Message
                key={1000000 + i}
                user_name={user_nickname}
                message={message}
                response_from={null}
                is_admin={null}
              />
            ))}
          </div>
        </div>
        <div className="chat_footer">
          <input
            type="text"
            value={this.state.text}
            onChange={this.handleChange}
            placeholder="Participe! Envie aqui a sua mensagem."
            id="input_message"
            onKeyDown={this._handleKeyDown}
          />
          <a
            className="chatSendMessage"
            onClick={() => {
              var message = document.getElementById("input_message").value;
              if (message) {
                this.newUserMessage(message);
                document.getElementById("input_message").value = "";
              }
            }}
          >
            <i className="pe-7s-paper-plane btn-chat-send-message"></i>
          </a>
          {
            <i
              onClick={this.toggleClass}
              className="pe-7s-smile emoji-pluz"
            ></i>
          }
        </div>
        <div className={this.state.active ? "display-block" : "display-none"}>
          {
            <Picker
              groupVisibility={{ recently_used: false }}
              pickerStyle={{ width: "100%" }}
              onEmojiClick={this.addEmoji}
            />
          }
        </div>
      </div>
    );
  }
}

export default Chat;
