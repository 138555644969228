import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";

import { ApolloClient } from 'apollo-client';
import { Query } from "react-apollo";
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';
import { Proof } from "../../components/Proof/Proof.jsx";

import Slider from "react-slick";


const restLink = new HttpLink({ 
  uri: "/api/v1/lms/",
  credentials: 'include'
 });
const lms_client = new ApolloClient({
  link: restLink,
  cache: new InMemoryCache(),
});

const restLink_dam = new HttpLink({
  uri: "/api/v1/dam/",
  credentials: 'include'
});
const dam_client = new ApolloClient({
  link: restLink_dam,
  cache: new InMemoryCache(),
});

const GET_TRAILITEMS = gql`
  query getItens($trail_id: Int!){
    trailitems(trail_id:$trail_id) {
      trailitems{
        item_id, item_type
      }
    }
  }
`;

const GET_CURSES = gql`{
  courses_collections{
    courses{
      id,title,thumbnail
    }
  }
}
`;


const GET_CURSE = gql`
  query getCourse($course_id: Int!){
    course(id:$course_id){
      id, title, thumbnail
    }
  }
`;

const GET_ASSET = gql`
  query getAsset($dam_id: Int!){
    asset(id:$dam_id){
      id, title, thumbnail
    }
  }
`;

const GET_TRAIL = gql`
  query getTrail($trail_id: Int!){
    trail(id:$trail_id){
      id, title, reactionevaluation{
        id, title
      }
    }
  }
`;

const USER_HAS_VIDEO = gql`
  mutation insert_user_has_video($video_id: String!){
    insert_user_has_video(video_id: $video_id){
      id
    }
  }
`;


const USER_HAS_VIDEO_FINISH = gql`
  mutation finish_user_has_video($video_id: String!){
    finish_user_has_video(video_id: $video_id) {
      id
    }
  }
`;


class Video extends Component {


  urlPlayer;
  videoOpen = false;

  constructor() {
    super();
    this.state = {
      like: false,
      favorite: false
    };

  }

  setLike() {
    this.setState(state => ({
      ...state,
      like: !this.state.like
    }));
  }
  setFavorite() {
    this.setState(state => ({
      ...state,
      favorite: !this.state.favorite
    }));
  }

  
  componentDidMount() {

    var videoId = this.props.match.params.video_id;
    var projectId = "";
    

    var player = new window.SambaPlayer("player", { //player é o ID do elemento html que ele vai inserir o iframe
      height: 360,
      width: 640,
      ph: projectId,//Player Hash do projeto
      m: videoId,//MidiaID
      playerParams: { //Veja a lista de Parâmetros suportados
          enableShare: false
      },
      events: { //Funcoes que escutam os eventos do player
          "onListen": this.onListen
      }
    });
  }


  onListen(player){
    if(typeof this.secsExec === "undefined") {
      this.secsExec = [];
    }
    if(typeof this.confirmView === "undefined") {
      this.confirmView = false;
    }
    if(player.duration && player.duration > 0 && player.event == "onListen"){
      var currentSecVideo = Math.floor(player.eventParam);
      if(!this.secsExec.includes(currentSecVideo)) {
        this.secsExec.push(currentSecVideo);
        var secsExecCount = this.secsExec.length;
        var totalSecs = player.duration/1000;
        if(secsExecCount/totalSecs >= 0.7 && !this.confirmView){
          this.confirmView = true;
          // Definir o vídeo como visto, após 70% de visualização
          lms_client.mutate({
              mutation: USER_HAS_VIDEO_FINISH,
              variables:{video_id: player.id }
          });
        }
      }
     
    }
  }


  render() {

    const windowWidth = window.innerWidth;

    var settings_courses = {
      autoplay: false,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: (windowWidth <= 987 ? 2 : 4),
      slidesToScroll: (windowWidth <= 987 ? 2 : 4)
    };

    // Salva o Item como Concluído para o usuário, só por ter aberto o item
    if(this.props.match.params.video_id && !this.videoOpen) {
      this.videoOpen = true;
      lms_client.mutate({
          variables:{video_id: this.props.match.params.video_id },
          mutation: USER_HAS_VIDEO
      });
    }
    

    return (
      
      <div id="CoursePage">

      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={1}></Col>
            <Col md={10}>
              <div>
                <div className="embed-responsive embed-responsive-16by9 card shadow-sm">
                  <div id="player"></div>
                </div>


                <Query
                  client={lms_client}
                  variables={{ video_id: this.props.match.params.video_id }}
                  query={gql`
                  query getVideo($video_id: String!) {
                    video(video_id:$video_id){
                      id,title,description
                    }
                  }
                  `}
                >
                  {({ loading, error, data }) => {
                    if (loading) return <div>Carregando...</div>;
                    if (error) return <div>Erro :(</div>;
                      
                    return (
                      <div>
                      <h1>{data.video.title}</h1>
                      <p>{data.video.description}</p>
                      </div>
                                  
                  );
                }}
              </Query>

            </div>
          </Col>
          <Col md={1}></Col>
          
        </Row>

      </Grid>
    </div>


      <div className="content content_bar">
        <Grid fluid>
          <Row>
              <Col md={12}>
                  <h2>
                    Mais assistidos
                  </h2>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                  <Query
                  client={lms_client}
                  query={GET_CURSES}
                  >
                  {({ loading, error, data }) => {
                    if (loading) return <div>Carregando...</div>;
                    if (error) return <div>Erro :(</div>;
                      
                      return (
                        <Slider {...settings_courses}>
                              {data.courses_collections.courses.map((item) => (
                                <div key={item.id} className="course-item">
                                  <a 
                                  data-gtm-type="click" 
                                  data-gtm-clicktype="link" 
                                  data-gtm-name={item.title}
                                  href={"/#/curso/"+item.id}>
                                  <img src={"/lms/assets/"+item.thumbnail} />
                                  <div className="title">{item.title}</div>
                                  </a>
                                </div>
                              ))}
                        </Slider>
                      )
                }}
                </Query>
              </Col>
            </Row>
            </Grid>
          </div>

    </div>
    );
  }
}

export default Video;
