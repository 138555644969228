import React, { Component } from "react";
import YouTube from 'react-youtube';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import Vimeo from '@u-wave/react-vimeo';

const restLink = new HttpLink({
  uri: "/api/v1/lms/",
  credentials: 'include'
});
const lms_client = new ApolloClient({
  link: restLink,
  cache: new InMemoryCache(),
});

const VIDEO_HAS_USER = gql`
  mutation createOrUpdateVideoHasUser($user_id: Int!, $video_id: String!, $time: Int!, $time_reproduced: Int!, $time_seconds: String!){
    createOrUpdateVideoHasUser( input:{user_id: $user_id, video_id:$video_id, time:$time, time_reproduced:$time_reproduced, time_seconds:$time_seconds}) {
      id
    }
  }
`;

var player = false;
var jsonObj = [];

const opts = {
  height: '390',
  width: '640',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 0,
  },
};

export class VideoYouTube extends Component {
  curTime = 0;
//   constructor(props) {
//     super(props);
// }
  

  _onReady = async (event) => {
    player = event.target;
    setInterval(this.getCurTime, 900); // pega 0.9 segundos, para evitar pular algun segundo por delay
  }
  

  render() {
    
    return (
      <div className="embed-responsive embed-responsive-16by9 card shadow-sm">

      {this.props.settings === "vimeo" &&
        <Vimeo
          loop="0"
          key={this.props.key}
          video={this.props.videoId}
          onPlay={this.props.onPlay}
          onReady={this.props.onReady}
          onEnd={this.props.onEnd}
          start={this.props.videoTime}
        /> 
      }


      {this.props.settings === "youtube" &&
        <YouTube
          key={this.props.key}
          videoId={this.props.videoId}
          opts={opts}
          onPlay={this.props.onPlay}
          onReady={this.props.onReady}          
        />
      }
      </div>
    );
  }
}

export default VideoYouTube;
