import React, { Component } from "react";
import { Navbar, Nav, NavItem, NavDropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import { ApolloClient } from 'apollo-client';
import { Query } from "react-apollo";
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

import HeaderLinks from "./HeaderLinks.jsx";
import dashboardRoutes from "../../routes/dashboard.jsx";

import * as Scroll from 'react-scroll';
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

import logo from "../../assets/img/Zenite_Branco.svg";


const restLink_account = new HttpLink({ credentials: 'include', uri: "/api/v1/account/" });
const account_client = new ApolloClient({
  link: restLink_account,
  cache: new InMemoryCache(),
});


class Header extends Component {
  constructor(props) {
    super(props);
    this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this);
    this.state = {
      sidebarExists: false
    };
  }
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }
  mobileSidebarToggle(e) {
    if (this.state.sidebarExists === false) {
      this.setState({
        sidebarExists: true
      });
    }
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    node.onclick = function() {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle("nav-open");
    };
    document.body.appendChild(node);
  }
  
  render() {

    

    var adicionalClassName = "";
    var adicionalClassName_2 = "";
    if(this.props.location.pathname.indexOf("/event/") === 0) {
      adicionalClassName = "top-new-event";
      adicionalClassName_2 = "hide-d";
    }
    else if(this.props.location.pathname.indexOf("/meeting/") === 0) {
      adicionalClassName = "top-new-meeting";
      adicionalClassName_2 = "hide-d";
    }

    return (
      <Query client={account_client}
      query={gql`{
        get_authenticated_user{
          id
          first_name
          profile{
            is_admin
          }
        }
      }
      `}
    >
      {({ loading, error, data }) => {
        if (loading) return <div></div>;
        if (error) return <div></div>;

        var user = data.get_authenticated_user;

        
      return  (

                <div className={adicionalClassName_2}>
                  <div className="new-header hide-d">
                    <p >Bem vindo, <strong>{user.first_name}</strong></p>
                    <Navbar fluid> <Navbar.Toggle onClick={this.mobileSidebarToggle} /></Navbar>
                  </div>
                  <div className={"top-new "+adicionalClassName}>
                    <Navbar fluid>
                      <Navbar.Header className="flex-1">
                        <Navbar.Brand>
                          
                          <a 
                          data-gtm-type="click" 
                          data-gtm-clicktype="menu" 
                          data-gtm-name="Início"
                          href="/#/home"
                          >
                            <img id="logo_top" src={logo} /> 
                          </a>
                        </Navbar.Brand>
                        <Navbar.Toggle onClick={this.mobileSidebarToggle} />
                      </Navbar.Header>
                      
                      <div className="flex-1 text-center text-header-zenite">Bem vindo, <strong>{user.first_name}</strong></div>
                      <Navbar.Collapse className="flex-1">
                        
                        <HeaderLinks />
                        <Nav>
                        
                          {dashboardRoutes.map((prop, key) => {
                              if (!prop.redirect && prop.showSidebar)
                                if(prop.children) {
                                  return (
                                  <li
                                    className={
                                        this.activeRoute(prop.path)
                                    }
                                    key={key}
                                  >
                                  <a>
                                  <NavDropdown
                                    title={prop.name}
                                    id="basic-nav-dropdown-right"
                                  >
                                  {prop.children.map((prop, key) => {
                                    return(
                                    <NavItem 
                                      key={key} 
                                      to={prop.path}
                                      className="nav-link"
                                      //activeClassName="active"
                                      href={"/#"+prop.path}>
                                        {prop.name}
                                      </NavItem>
                                    );
                                  })}
                                  </NavDropdown>
                                  </a>
                                  </li>
                                  );
                                } else {
                                return (
                                  <li
                                    className={
                                        this.activeRoute(prop.path)
                                    }
                                    key={key}
                                  >
                                    <NavLink
                                      to={prop.path}
                                      className="nav-link"
                                      //activeClassName="active"
                                    >
                                      {prop.name == "Início" ? <i className={"ps-ico ps-ico-home"} /> : prop.name}
                                    </NavLink>
                                  </li>
                                );
                              }
                              return null;
                          })}
                          
                        </Nav>
                      </Navbar.Collapse>
                    </Navbar>
                    <div className="both"></div>
                  </div>
                  <div className="both"></div>
                </div>
         
      );
    }}
      </Query>
    );
  }
}

export default Header;
