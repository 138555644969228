import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import { Grid, Row, Col } from "react-bootstrap";


import { NavItem, Nav, NavDropdown, MenuItem } from "react-bootstrap";


import { Query } from "react-apollo";
import gql from "graphql-tag";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';


import { Card } from "../../components/Card/Card.jsx";
import { StatsCard } from "../../components/StatsCard/StatsCard.jsx";
import { Tasks } from "../../components/Tasks/Tasks.jsx";



const restLink_lms = new HttpLink({ 
  uri: "/api/v1/lms/",
  credentials: 'include'
});
const lms_client = new ApolloClient({
  link: restLink_lms,
  cache: new InMemoryCache(),
});


const GET_VIDEO = gql`
query getVideos($category_id: Int!){
  videos_collections_by_category(category_id: $category_id){
    videos{
      id, title, thumbnail
    }
  }
}
`;

const GET_CATEGORIES = gql`{
  video_categories_collections(parent_name:"Videoteca") {
    categories {
      id
      name
      children {
        id
        name
      }
    }
  }
}
`;


class Video extends Component {

  constructor() {
    super();
    this.category_id = 0;
  }
  
  createLegend(json) {
    var legend = [];
    for (var i = 0; i < json["names"].length; i++) {
      var type = "fa fa-circle text-" + json["types"][i];
      legend.push(<i className={type} key={i} />);
      legend.push(" ");
      legend.push(json["names"][i]);
    }
    return legend;
  }

  render() {
    
    return (
        

  <div className="">
  <div className="container">
  <Grid fluid>
          <Row>
<div className="contentMenu">
        
        <Nav>
        <NavItem
        data-gtm-type="click" 
        data-gtm-clicktype="submenu" 
        data-gtm-name="Todos"
        href={"/#/videoteca/"}>Todos</NavItem>
        <Query
          client={lms_client}
          query={GET_CATEGORIES}
        >
          {({ loading, error, data }) => {
            if (loading) return <div>Carregando...</div>;
            if (error) return <div>Erro :(</div>;
              
              if(!data.video_categories_collections.categories) {
                return (<div>(Vazio)</div>);
              } 

            return data.video_categories_collections.categories.map((categ) => (
              
              <NavDropdown
                eventKey={2}
                title={categ.name}
                id="basic-nav-dropdown-right"
              >
                    {categ.children.map((sub_categ) => (
                              <NavItem 
                              data-gtm-type="click" 
                              data-gtm-clicktype="submenu" 
                              data-gtm-name={sub_categ.name}
                              eventKey={sub_categ.id} href={"/#/videoteca-categoria/"+sub_categ.id}>{sub_categ.name}</NavItem>
                      ))
                     }
              </NavDropdown>
          ));
        }}
      </Query>
        </Nav>
      </div>
      </Row>
      </Grid>
        <Grid fluid>
          <Row>
            

              <Query
                client={lms_client}
                variables={{ category_id: (this.props.match.params.category_id ? parseInt(this.props.match.params.category_id) : 0) }}
                query={GET_VIDEO}
              >
                {({ loading, error, data }) => {
                  if (loading) return <div>Carregando...</div>;
                  if (error) return <div>Erro :(</div>;
                    
                    if(!data.videos_collections_by_category.videos) {
                      return (<div>(Vazio)</div>);
                    } 

                  return data.videos_collections_by_category.videos.map((item) => (
                    
                    <Col lg={4} sm={6}>
                    <div className="media">
                      <a 
                      data-gtm-type="click" 
                      data-gtm-clicktype="video" 
                      data-gtm-name={item.title}
                      href={"/#/video/"+item.id}>
                      <img src={item.thumbnail} />
                      <div>{item.title}</div>
                      </a>
                    </div>
                    </Col>
      ));
      }}
      </Query>

      </Row>
      </Grid>
      </div>
      </div>
    );
  }
}

export default Video;
