import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import { NavLink } from "react-router-dom";
import { Query } from "react-apollo";
import gql from "graphql-tag";

function chatLink($link) {
  return "/chatview?c=" + $link;
}


class ChatList extends Component {


  render() {


    return (

      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Chats ativos"
                category="Gerenciar chats ativos"
                ctTableFullWidth
                ctTableResponsive
                content={
                  
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Título</th>
                        <th>
                          <NavLink
                              to='/chatitem'
                              className=""
                              //activeClassName=""
                            >
                            <p>Novo Chat</p>
                          </NavLink>
                        </th>
                      </tr>
                    </thead>
                    <tbody>

                        <Query
                          query={gql`
                            {
                              chats_collections(limit:20){
                                chats{
                                  id,
                                  title
                                }
                              }
                            }
                          `}
                        >
                          {({ loading, error, data }) => {
                            if (loading) return <tr><td>Carregando...</td></tr>;
                            if (error) return <tr><td>Erro :(</td></tr>;
                              
                            return data.chats_collections.chats.map((item) => (
                              
                              <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.title}</td>
                                <td>
                                  <a target="_blank" href={chatLink(item.id)}>Abrir</a>
                                </td>
                              </tr>
                            ));
                          }}
                        </Query>
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default ChatList;
