import Auth from "../views/Auth/Auth";
import Login from "../views/Auth/Login";
import Register from "../views/Auth/Register";
import ResetPassword from "../views/Auth/ResetPassword";


import Suport from "../views/Suport/Suport";

import Home from "../views/Home/Home";
import Trail from "../views/Trail/Trail";
import MediaCenter from "../views/MediaCenter/MediaCenter";

import Search from "../views/Search/Search";
import Video from "../views/Videos/Video";
import Videos from "../views/Videos/Videos";
import VideosLivetovod from "../views/Videos/VideosLivetovod";

import CoursePage from "../views/CoursePage/CoursePage";
import PresentialPage from "../views/CoursePage/PresentialPage";
import Courses from "../views/Courses/Courses";
import CoursesFavorites from "../views/Courses/Favorites";
import PresentialList from "../views/Courses/PresentialList";

import Dashboard from "../views/Dashboard/Dashboard";
import UserProfile from "../views/UserProfile/UserProfile";
import Chat from "../views/Chat/Chat";
import ChatItem from "../views/Chat/ChatItem";
import TableList from "../views/TableList/TableList";
import Typography from "../views/Typography/Typography";
import Icons from "../views/Icons/Icons";
import Maps from "../views/Maps/Maps";
import Notifications from "../views/Notifications/Notifications";

import Live from "../views/Live/Live";
import Lives from "../views/Live/Lives";
import LivesNow from "../views/Live/LivesNow";
import LivesToVOD from "../views/Live/LivesToVOD";

import CertificateList from "../views/Certificate/CertificateList";
import CertificateTrailList from "../views/Certificate/CertificateTrailList";

// Como cada usuário só pode ser vinculado à um processo, não lista mais os processos
//import Lawsuits from "../views/Lawsuit/Lawsuits";

import Forms from "../views/Form/Forms";
import Form from "../views/Form/Form";


import Accreditation from "../views/Accreditation/Accreditation";
import LawsuitList from "../views/Lawsuit/LawsuitList";


const dashboardRoutes = [
  
  {
    showSidebar: false,
    path: "/login",
    name: "Login",
    icon: "pe-7s-users",
    component: Login
  },
  {
    showSidebar: false,
    path: "/home",
    name: "Primeira página",
    icon: "pe-7s-home",
    component: LawsuitList
  },

  {
    showSidebar: false,
    path: "/registro/:ref",
    name: "Register",
    icon: "pe-7s-users",
    component: Register
  },

  {
    showSidebar: false,
    path: "/recupear-senha/",
    name: "ResetPassword",
    icon: "pe-7s-users",
    component: ResetPassword
  },
  

  {
    showSidebar: false,
    path: "/meeting/:lawsuit_id/:show_room",
    name: "Meeting",
    icon: "pe-7s-home",
    component: Accreditation
  },
  {
    showSidebar: false,
    path: "/meeting/:lawsuit_id/:show_room",
    name: "Meeting",
    icon: "pe-7s-home",
    component: Accreditation
  },
  {
    showSidebar: false,
    path: "/event/:lawsuit_id",
    name: "Meeting",
    icon: "pe-7s-home",
    component: Accreditation
  },

  {
    showSidebar: false,
    path: "/forms",
    name: "Votações",
    icon: "pe-7s-home",
    component: Forms
  },

  {
    showSidebar: false,
    path: "/votacao/:lawsuit_id/:id",
    name: "Votação",
    icon: "pe-7s-home",
    component: Form
  },
  // 
  
  {
    showSidebar: false,
    path: "/auth/:userid",
    name: "Auth",
    icon: "pe-7s-graph",
    component: Auth
  },
  {
    showSidebar: false,
    path: "/trail/:id/:type/:trail_id",
    name: "Curso",
    icon: "pe-7s-chat",
    component: CoursePage
  },

  {
    showSidebar: false,
    path: "/conteudos-digitais/:category_id",
    name: "Conteúdos Digitais",
    icon: "pe-7s-photo",
    component: MediaCenter
  },
  {
    showSidebar: false,
    path: "/videoteca",
    name: "Videoteca",
    icon: "pe-7s-chat",
    component: Videos
  },{
    showSidebar: false,
    path: "/videoteca-categoria/:category_id",
    name: "Videoteca",
    icon: "pe-7s-chat",
    component: Videos
  },
  {
    showSidebar: false,
    path: "/video/:video_id",
    name: "Video",
    icon: "pe-7s-chat",
    component: Video
  },
  {
    showSidebar: false,
    path: "/conteudos-digitais",
    name: "Conteúdos Digitais",
    icon: "pe-7s-photo",
    component: MediaCenter,
    children:[
      {
        showSidebar: false,
        path: "/conteudos-digitais",
        name: "Biblioteca",
        icon: "pe-7s-photo",
        component: MediaCenter
      },
      {
        showSidebar: false,
        path: "/videoteca",
        name: "Videoteca",
        icon: "pe-7s-chat",
        component: LivesToVOD
      }
    ]
  },


  /* SEARCH */
  {
    showSidebar: false,
    path: "/search",
    name: "Busca",
    icon: "pe-7s-chat",
    component: Search
  },

  /* CURSOS */
  {
    showSidebar: false,
    path: "/curso/:id/:course_proof_type/:course_proof_id",
    name: "Curso",
    icon: "pe-7s-chat",
    component: CoursePage
  },
  {
    showSidebar: false,
    path: "/curso/:id",
    name: "Curso",
    icon: "pe-7s-chat",
    component: CoursePage
  },
  {
    showSidebar: false,
    path: "/curso-presencial/:id",
    name: "Curso presencial",
    icon: "pe-7s-chat",
    component: PresentialPage
  },
  {
    showSidebar: false,
    path: "/cursos-online/:category_id",
    name: "On-line",
    icon: "pe-7s-study",
    component: Courses
  },
  {
    showSidebar: false,
    path: "/cursos-online",
    name: "On-line",
    icon: "pe-7s-study",
    component: Courses
  },
  {
    showSidebar: false,
    path: "/cursos-favoritos",
    name: "On-line",
    icon: "pe-7s-study",
    component: CoursesFavorites
  },
  {
    showSidebar: false,
    path: "/cursos-presenciais",
    name: "Presenciais",
    icon: "pe-7s-study",
    component: PresentialList
  },
  {
    showSidebar: false,
    path: "/cursos-online",
    name: "Cursos",
    icon: "pe-7s-study",
    component: Courses,
    children: [
      {
        showSidebar: false,
        path: "/cursos-online",
        name: "On-line",
        icon: "pe-7s-study",
        component: Courses
      },
      {
        showSidebar: false,
        path: "/cursos-presenciais",
        name: "Presenciais",
        icon: "pe-7s-study",
        component: PresentialList
      },
      {
        showSidebar: false,
        path: "/certificados",
        name: "Certificados dos cursos",
        icon: "pe-7s-chat",
        component: CertificateList
      },
      {
        showSidebar: false,
        path: "/trilhas-certificados",
        name: "Certificados das trilhas",
        icon: "pe-7s-chat",
        component: CertificateTrailList
      },
      {
        showSidebar: false,
        path: "/cursos-favoritos",
        name: "Favoritos",
        icon: "pe-7s-study",
        component: CoursesFavorites
      }
  ]
  },

  /* Certificdos */
  {
    showSidebar: false,
    path: "/certificados",
    name: "Certificados",
    icon: "pe-7s-chat",
    component: CertificateList
  },
  {
    showSidebar: false,
    path: "/trilhas-certificados",
    name: "Certificados das trilhas",
    icon: "pe-7s-chat",
    component: CertificateTrailList
  },

  /* Lives */
  
  {
    showSidebar: false,
    path: "/lives-gravadas/:category_id",
    name: "Aulas Gravadas",
    icon: "pe-7s-chat",
    component: VideosLivetovod
  },
  {
    showSidebar: false,
    path: "/lives-gravadas",
    name: "Aulas Gravadas",
    icon: "pe-7s-chat",
    component: VideosLivetovod
  },
  {
    showSidebar: false,
    path: "/sala-virtual-do-dia",
    name: "Transmissões",
    icon: "pe-7s-chat",
    component: LivesNow
  },
  {
    showSidebar: false,
    path: "/sala-virtual",
    name: "Sala Virtual",
    icon: "pe-7s-chat",
    component: Lives,
    children:[
      {
        showSidebar: false,
        path: "/sala-virtual",
        name: "Agenda",
        icon: "pe-7s-chat",
        component: Lives
      },
      /*
      {
        showSidebar: false,
        path: "/sala-virtual-do-dia",
        name: "Transmissão do dia",
        icon: "pe-7s-chat",
        component: LivesNow
      },*/
      {
        showSidebar: false,
        path: "/lives-gravadas",
        name: "Aulas Gravadas",
        icon: "pe-7s-chat",
        component: LivesToVOD
      }
    ]
  },
  {
    showSidebar: false,
    path: "/live/:id",
    name: "Sala Virtual",
    icon: "pe-7s-chat",
    component: Live
  },
  {
    showSidebar: false,
    path: "/trilhas/:category_id",
    name: "Trilhas",
    icon: "pe-7s-next-2",
    component: Trail
  },
  {
    showSidebar: false,
    path: "/trilhas",
    name: "Trilhas",
    icon: "pe-7s-next-2",
    component: Trail
  },
  {
    showSidebar: false,
    path: "/suport",
    name: "Suporte",
    icon: "pe-7s-next-2",
    component: Suport
  },
  {
    showSidebar: false,
    path: "/dashboard",
    name: "Dashboard",
    icon: "pe-7s-graph",
    component: Dashboard
  },
  {
    showSidebar: false,
    path: "/user",
    name: "User Profile",
    icon: "pe-7s-user",
    component: UserProfile
  },
  {
    showSidebar: false,
    path: "/chat",
    name: "Chat",
    icon: "pe-7s-chat",
    component: Chat
  },
  {
    showSidebar: false,
    path: "/chatitem",
    name: "Chat Item",
    icon: "pe-7s-chat",
    component: ChatItem
  },
  {
    showSidebar: false,
    path: "/table",
    name: "Table List",
    icon: "pe-7s-note2",
    component: TableList
  },
  {
    showSidebar: false,
    path: "/typography",
    name: "Typography",
    icon: "pe-7s-news-paper",
    component: Typography
  },
  { showSidebar: false, path: "/icons", name: "Icons", icon: "pe-7s-science", component: Icons },
  { showSidebar: false, path: "/maps", name: "Maps", icon: "pe-7s-map-marker", component: Maps },
  {
    showSidebar: false, 
    path: "/notifications",
    name: "Notifications",
    icon: "pe-7s-bell",
    component: Notifications
  },
  { redirect: true, path: "/", to: "/home", name: "Login" }
];

export default dashboardRoutes;

