import React, { Component } from "react";

import { ApolloClient } from 'apollo-client';
import { Query } from "react-apollo";
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';
import Button from "../CustomButton/CustomButton.jsx";


const restLink_lms = new HttpLink({
   uri: "/api/v1/lms/",
   credentials: 'include'
  });
const lms_client = new ApolloClient({
  link: restLink_lms,
  cache: new InMemoryCache(),
});

const GET_PROOF = gql`
  query getProof($proof_id: Int!){
    proof(id: $proof_id) {
      questions {
        id
        title
        answers {
          id
          title
        }
      }
    }
  }`;

  const GET_ANSWER_QUESTION = gql`
  query answer_question_collections($proof_id: Int!, $item_id: Int!, $item_type: String!){
    answer_question_collections(proof_id: $proof_id, item_id: $item_id, item_type: $item_type){
      answers_question{
        id
      }
    }
  }`;

const ANSWER_QUESTION = gql`
  mutation addAnswerQuestion($answer_id: Int!, $question_id: Int!, $item_id: Int!, $item_type: String!){
    createAnswerQuestion(input: {answer_id: $answer_id, question_id: $question_id, item_id: $item_id, item_type: $item_type}) {
      id
    }
  }`;
  

export class ExamEvaluative extends Component {
  
  constructor() {
    super();
    this.state = {
      sendAnswers: false
    };
    
  }


  render() {

    return (
      <Query
        client={lms_client}
        query={GET_PROOF}
        variables={{proof_id: this.props.proof_id}}
        >
        {({ loading, error, data }) => {
          if (loading) return <div>Carregando...</div>;
          if (error) return <div>Erro :(</div>;
          
            var proof = data.proof;
          return (
            <Query // Veririca se o usuário já respondeu essa prova
              client={lms_client}
              fetchPolicy='no-cache'
              query={GET_ANSWER_QUESTION}
              variables={{proof_id: this.props.proof_id, item_id: this.props.item_id, item_type: this.props.item_type}}
              >
              {({ loading, error, data }) => {
                if (loading) return <div>Carregando...</div>;
                if (error) return <div>Erro :(</div>;

                  var sendAnswers = false;
                  // Verifica se o usuário já respondeu essa prova
                  if(data.answer_question_collections && data.answer_question_collections.answers_question.length) {
                    sendAnswers = true;
                  }
                return (
                  <div className="proof">
                    
                    {this.state.sendAnswers || sendAnswers ?
                    
                      <h2>Obrigado pela sua resposta!</h2>

                    :
                  
                    <div>
                      {
                        proof.questions.map((question, index) => (
                          <div className="proof_question" key={question.id}>
                            <b>{index+1}. {question.title}</b>
                            
                            {
                              question.answers.map((answer) => (
                                <div className="proof_answer" key={answer.id}>
                                  <input className="answer_item" type="radio" name={question.id} value={answer.id} /> {answer.title}
                                </div>
                              ))
                            }
                          </div>
                        ))
                      }
                      
                      <Button bsStyle="info" onClick={() => {

                        var answers = document.getElementsByClassName("answer_item");
                        for (var i = 0; i < answers.length; i++) {
                          var answer = answers[i];
                          if(answer.checked) {

                            var $question_id = answer.getAttribute("name");
                            var $answer_id = answer.getAttribute("value");

                            lms_client.mutate({
                              mutation: ANSWER_QUESTION,
                              variables: {
                                question_id: $question_id,
                                answer_id: $answer_id,
                                item_id: this.props.item_id,
                                item_type: this.props.item_type
                              }
                            }).then(
                              result => {
                                this.props.setCourseFinish();
                                this.setState(state => ({
                                  ...state,
                                  sendAnswers: true
                                }));
                            });

                          };
                        } 
                        
                      }}>
                      Confirmar
                    </Button>
                    </div>
                  }
                </div>
                );
              }}
              
              </Query>
          );
        }}
        
        </Query>
    );
  }
}

export default ExamEvaluative;
