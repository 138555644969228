import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import {
  Grid,
  Row,
  Col,
  Alert,
  FormGroup,
  ControlLabel,
  Table,
} from "react-bootstrap";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";
import InputMask from "react-input-mask";
import { Card } from "../../components/Card/Card.jsx";
import FooterZ from "../../components/Footer/FooterZ.jsx";

import { Query } from "react-apollo";
import gql from "graphql-tag";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";

const restLink_account = new HttpLink({
  uri: "/api/v1/account/",
  credentials: "include",
});
const account_client = new ApolloClient({
  link: restLink_account,
  cache: new InMemoryCache(),
});

const REGISTER_UPDATE = gql`
  mutation updateByUser(
    $name: String!
    $nickname: String!
    $mail: String!
    $phone: String!
  ) {
    updateByUser(name: $name, nickname: $nickname, mail: $mail, phone: $phone) {
      id
      token
      msg
      msg_error
    }
  }
`;

const REGISTER_UPDATE_PASS = gql`
  mutation updateByUser($pass: String!, $repass: String!) {
    updateByUser(pass: $pass, repass: $repass) {
      id
      token
      msg
      msg_error
    }
  }
`;

class UserProfile extends Component {
  constructor() {
    super();
    this.state = {
      start_state: true,
      accepted_news: false,
      accepted_terms: false,
      name: "",
      nickname: "",
      mail: "",
      phone: "",
      pass: "",
      repass: "",
      msg: "",
      msg_pass: "",
      msg_type: "info",
    };
  }

  updateByUser = async () => {
    const mutationReturn = (
      await account_client.mutate({
        variables: {
          name: this.state.name,
          nickname: this.state.nickname,
          mail: this.state.mail,
          phone: this.state.phone,
        },
        mutation: REGISTER_UPDATE,
        fetchPolicy: "no-cache",
      })
    ).data.updateByUser;

    if (
      mutationReturn &&
      typeof mutationReturn.token != "undefined" &&
      mutationReturn.token !== null &&
      mutationReturn.token !== ""
    ) {
      document.cookie = "token_user=" + mutationReturn.token; // Grava o cookie
      this.setState({
        msg_type: "info",
        msg: "Dados atualizados com sucesso.",
      });
    } else if (
      mutationReturn &&
      typeof mutationReturn.msg_error != "undefined"
    ) {
      this.setState({
        msg_type: "warning",
        msg: mutationReturn.msg_error,
      });
    }
  };
  updateByUser_pass = async () => {
    const mutationReturn = (
      await account_client.mutate({
        variables: {
          pass: this.state.pass,
          repass: this.state.repass,
        },
        mutation: REGISTER_UPDATE_PASS,
        fetchPolicy: "no-cache",
      })
    ).data.updateByUser;

    if (
      mutationReturn &&
      typeof mutationReturn.token != "undefined" &&
      mutationReturn.token !== null &&
      mutationReturn.token !== ""
    ) {
      document.cookie = "token_user=" + mutationReturn.token; // Grava o cookie
      this.setState({
        msg_type: "info",
        msg_pass: "Dados atualizados com sucesso.",
      });
    } else if (
      mutationReturn &&
      typeof mutationReturn.msg_error != "undefined"
    ) {
      this.setState({
        msg_type: "warning",
        msg_pass: mutationReturn.msg_error,
      });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.updateByUser();
  };

  handleSubmit_pass = (event) => {
    event.preventDefault();
    this.updateByUser_pass();
  };

  onChange_name = (event) => {
    this.setState({ name: event.target.value });
  };
  onChange_nickname = (event) => {
    this.setState({ nickname: event.target.value });
  };
  onChange_mail = (event) => {
    this.setState({ mail: event.target.value });
  };
  onChange_phone = (event) => {
    this.setState({ phone: event.target.value });
  };
  onChange_pass = (event) => {
    this.setState({ pass: event.target.value });
  };
  onChange_repass = (event) => {
    this.setState({ repass: event.target.value });
  };

  handleCheckboxChange = (e) => {
    this.setState((state) => ({
      ...state,
      accepted_terms: !this.state.accepted_terms,
    }));
  };

  handleCheckboxChange_news = (e) => {
    this.setState((state) => ({
      ...state,
      accepted_news: !this.state.accepted_news,
    }));
  };

  createLegend(json) {
    var legend = [];
    for (var i = 0; i < json["names"].length; i++) {
      var type = "fa fa-circle text-" + json["types"][i];
      legend.push(<i className={type} key={i} />);
      legend.push(" ");
      legend.push(json["names"][i]);
    }
    return legend;
  }

  minutes_with_leading_zeros(dt) {
    return (dt.getMinutes() < 10 ? "0" : "") + dt.getMinutes();
  }

  render() {
    return (
      <div>
        <div className="content">
          <div className="container">
            <Grid fluid>
              <h1 className="titleTop">Atualizar Dados</h1>

              <Row>
                <Col md={6}>
                  <div>
                    <h2 className="title-profile-zenite">Dados pessoais</h2>
                    <Query
                      client={account_client}
                      fetchPolicy="no-cache"
                      query={gql`
                        {
                          get_authenticated_user {
                            id
                            name
                            nickname
                            mail
                            uid
                            phone
                            is_confirm
                          }
                        }
                      `}
                    >
                      {({ loading, error, data }) => {
                        if (loading) return <div>Olá</div>;
                        if (error) return <div>Erro :(</div>;

                        var authenticated_user = data.get_authenticated_user;

                        if (this.state.start_state) {
                          this.setState({
                            start_state: false,
                            name: authenticated_user.name,
                            nickname: authenticated_user.nickname,
                            mail: authenticated_user.mail,
                            phone: authenticated_user.phone,
                          });
                        }

                        return (
                          <div>
                            <form
                              onSubmit={(e) => {
                                this.handleSubmit(e);
                                e.preventDefault();
                              }}
                            >
                              <div class="form-group">
                                <label class="control-label">E-mail</label>
                                <p>{this.state.mail}</p>
                                <br />
                              </div>

                              <FormInputs
                                ncols={["col-md-12 label-profile-zenite"]}
                                proprieties={[
                                  {
                                    onChange: this.onChange_name,
                                    name: "name",
                                    label: "Nome Completo",
                                    type: "text",
                                    bsClass: "form-control",
                                    placeholder: "Nome Completo",
                                    defaultValue: this.state.name,
                                  },
                                ]}
                              />

                              <FormInputs
                                ncols={["col-md-12 label-profile-zenite"]}
                                proprieties={[
                                  {
                                    onChange: this.onChange_nickname,
                                    name: "nickname",
                                    label: "Nome para o Chat",
                                    type: "text",
                                    bsClass: "form-control",
                                    placeholder: "Nome para o Chat",
                                    defaultValue: this.state.nickname,
                                  },
                                ]}
                              />

                              {/* <FormInputs
                                  ncols={["col-md-12 label-profile-zenite"]}
                                  proprieties={[
                                    {
                                      onChange: this.onChange_mail,
                                      name: "mail",
                                      label: "E-mail",
                                      type: "mail",
                                      bsClass: "form-control",
                                      placeholder: "E-mail",
                                      defaultValue: this.state.mail
                                    }
                                  ]}
                                /> */}

                              <FormGroup className="label-profile-zenite">
                                <ControlLabel>Celular</ControlLabel>
                                <div>
                                  <InputMask
                                    className="form-control"
                                    mask="(99) 9 9999-9999"
                                    value={this.state.phone}
                                    onChange={this.onChange_phone}
                                  />
                                </div>
                              </FormGroup>

                              <Row>
                                <Col md={12}>
                                  <Button
                                    bsStyle="info btn-zenite-text"
                                    fill
                                    type="submit"
                                  >
                                    Atualizar
                                  </Button>
                                </Col>
                                {this.state.msg && (
                                  <Col md={10}>
                                    <Alert bsStyle={this.state.msg_type}>
                                      {this.state.msg}
                                    </Alert>
                                  </Col>
                                )}
                              </Row>
                            </form>
                          </div>
                        );
                      }}
                    </Query>
                  </div>
                </Col>
                <Col md={6}>
                  <h2 className="title-profile-zenite">Alterar senha</h2>
                  <div>
                    <form
                      onSubmit={(e) => {
                        this.handleSubmit_pass(e);
                        e.preventDefault();
                      }}
                    >
                      <FormInputs
                        ncols={["col-md-12 label-profile-zenite"]}
                        proprieties={[
                          {
                            onChange: this.onChange_pass,
                            name: "pass",
                            label: "Senha",
                            type: "password",
                            bsClass: "form-control",
                            placeholder: "Senha",
                            defaultValue: "",
                          },
                        ]}
                      />

                      <FormInputs
                        ncols={["col-md-12 label-profile-zenite"]}
                        proprieties={[
                          {
                            onChange: this.onChange_repass,
                            name: "repass",
                            label: "Repetir Senha",
                            type: "password",
                            bsClass: "form-control",
                            placeholder: "Repetir Senha",
                            defaultValue: "",
                          },
                        ]}
                      />

                      <Row>
                        <Col md={12}>
                          <Button
                            bsStyle="info btn-zenite-text"
                            fill
                            type="submit"
                          >
                            Atualizar
                          </Button>
                        </Col>
                        {this.state.msg_pass && (
                          <Col md={10}>
                            <Alert bsStyle={this.state.msg_type}>
                              {this.state.msg_pass}
                            </Alert>
                          </Col>
                        )}
                      </Row>
                    </form>
                  </div>
                </Col>
              </Row>
            </Grid>
          </div>
        </div>

        <FooterZ />
      </div>
    );
  }
}

export default UserProfile;
