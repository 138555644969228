import React, { Component } from "react";
import ReactMarkdown from "react-markdown";
import htmlParser from "react-markdown/plugins/html-parser";
import FormQuestions from "../Form/Questions.jsx";

import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import gql from "graphql-tag";
import { HttpLink } from "apollo-link-http";

const restLink_form = new HttpLink({
  credentials: "include",
  uri: "/api/v1/forms/",
});
const form_client = new ApolloClient({
  link: restLink_form,
  cache: new InMemoryCache(),
});

const GET_FORMS = gql`
  query getForms(
    $lawsuit_id: Int!
    $type: String!
    $search: String!
    $limit: Int!
    $page: Int!
    $order: String!
    $orderby: String!
  ) {
    forms_collections(
      lawsuit_id: $lawsuit_id
      type: $type
      search: $search
      limit: $limit
      page: $page
      order: $order
      orderby: $orderby
    ) {
      forms {
        id
        title
        description
        lawsuit_id
        datetimeStartFormat
        datetimeEndFormat
      }
    }
  }
`;

class FormContentPage extends Component {
  state = {
    forms_collections: [],
  };

  componentDidMount() {
    this.getVars();
  }

  getVars = async () => {
    const forms_collections = (
      await form_client.query({
        variables: {
          lawsuit_id: this.props.lawsuit_id,
          type: "form",
          search: "",
          limit: 1000,
          page: 1,
          order: "DESC",
          orderby: "id",
        },
        query: GET_FORMS,
        fetchPolicy: "no-cache",
      })
    ).data.forms_collections.forms;

    this.setState({ forms_collections });
  };

  render() {
    if (
      !this.state.forms_collections ||
      this.state.forms_collections.length === 0
    ) {
      return <div></div>;
    }

    const parseHtml = htmlParser({
      isValidNode: (node) => node.type !== "script",
      processingInstructions: [
        /* ... */
      ],
    });

    return (
      <div className="form_page">
        <div className="content">
          <div className="container" name="form" id="form">
            <div className="">
              <h3>
                {typeof this.props.title !== "undefined"
                  ? this.props.title
                  : "Avaliação"}
              </h3>
              {this.state.forms_collections.map((form) => (
                <div key={form.id}>
                  <h4>{form.title}</h4>
                  <ReactMarkdown
                    source={form.description}
                    escapeHtml={false}
                    astPlugins={[parseHtml]}
                    linkTarget="_blank"
                  />
                  <FormQuestions title="Clique aqui" form={form} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FormContentPage;
