import React, { Component } from "react";

import { Grid, Row, Col } from "react-bootstrap";

import { ApolloClient } from 'apollo-client';
import { Query } from "react-apollo";
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';
import { Proof } from "../../components/Proof/Proof.jsx";
import { ProofEvaluative } from "../../components/Proof/ProofEvaluative.jsx";
import { Scorm } from "../../components/Course/Scorm.jsx";
import thumb_certificate from "../../assets/img/certificate-400x225.jpg";
import thumb_reactionevaluation from "../../assets/img/avaliacao-reacao-400x225.jpg";
import thumb_proof from "../../assets/img/avaliacao-aprendizagem-400x225.jpg";
import { SidebarCourse } from "../../components/Sidebar/SidebarCourse.jsx";

import Slider from "react-slick";

const restLink = new HttpLink({ 
  uri: "/api/v1/lms/",
  credentials: 'include'
 });
const lms_client = new ApolloClient({
  link: restLink,
  cache: new InMemoryCache(),
});

const GET_COURSE_HAS_USER_ITEM = gql`
  query course_has_user_item($course_id: Int!){
      course_has_user_item(course_id:$course_id) {
        id
      }
    }
`;


export class CourseCarousel extends Component {

  
  render() {

    const windowWidth = window.innerWidth;

    var settings_courses = {
      vertical: true,
      autoplay: false,
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: (windowWidth <= 987 ? 2 : 4),
      slidesToScroll: 2
    };

    return (
      <Query
              client={lms_client}
              variables={{ id: this.props.course_id }}
              query={gql`
              query getCourse($id: Int!) {
                course(id:$id){
                  id,
                  title,
                  description,
                  scorm,
                  hours,
                  minutes,
                  thumbnail,
                  code,
                  video{
                    id, thumbnail
                  },
                  proof{
                    id, title
                  }, 
                  reactionevaluation{
                    id, title
                  }
                }
              }
              `}
            >
              {({ loading, error, data }) => {
                if (loading) return <div>Carregando...</div>;
                if (error) return <div>Erro :(</div>;

                return (
                        <Row>
                          <Col md={12}>
                            <h2 className="title">Módulos</h2>
                            <div className="trail_bar vertical_carousel">
                              <Slider {...settings_courses}>
                                  <div key={data.course.id} className="course-item">
                                    <a href={"/#/curso/"+data.course.id}>
                                        <div className="icon-big">
                                          <i className={"text-info "+(!this.props.course_proof_type ? "pe-7s-look" :"pe-7s-unlock")} />
                                        </div>
                                        <div className="course_btn_play"></div>
                                        <img  src={(data.course.thumbnail ? "/lms/assets/"+data.course.thumbnail : (data.course.video && data.course.video.thumbnail ? data.course.video.thumbnail : "") )} />
                                        <div className="title">{data.course.title}</div>
                                    </a>
                                  </div>
                                  {
                                    data.course.proof &&
                                      <div key={data.course.proof.id} className="course-item">
                                          <a className="proof_slide" href={"/#/curso/"+data.course.id+"/proof/"+data.course.proof.id}>
                                            <div className="icon-big">
                                              <i className={"text-info "+(this.props.course_proof_type && this.props.course_proof_type == "proof" ? "pe-7s-look" :"pe-7s-unlock")} />
                                            </div>
                                            <img src={thumb_proof} />
                                          </a>
                                      </div>
                                  }

                                  {
                                    data.course.reactionevaluation &&
                                      <div key={data.course.reactionevaluation.id} className="course-item">
                                          <a className="proof_slide" href={"/#/curso/"+data.course.id+"/reactionevaluation/"+data.course.reactionevaluation.id}>
                                            <div className="icon-big">
                                              <i className={"text-info "+(this.props.course_proof_type && this.props.course_proof_type == "reactionevaluation" ? "pe-7s-look" :"pe-7s-unlock")} />
                                            </div>
                                            <img src={thumb_reactionevaluation} />
                                          </a>
                                      </div>
                                  }
                                  {
                                    data.course.proof &&
                                    <Query
                                      client={lms_client}
                                      fetchPolicy= 'no-cache'
                                      variables={{ course_id: this.props.course_id }}
                                      query={GET_COURSE_HAS_USER_ITEM}
                                      >
                                      {({ loading, error, data }) => {
                                        if (loading) return <div>Carregando...</div>;
                                        if (error) return <div>Erro :(</div>;

                                        if(this.props.courseFinish) {
                                          var courseFinish = true;
                                          var urlDownloadCertificate = ("/api/v1/lms/certified_generate/?u="+ this.props.courseFinish);
                                        }
                                        else if(this.props.course_has_user_item && this.props.course_has_user_item.id) {
                                          var courseFinish = true;
                                          var urlDownloadCertificate = ("/api/v1/lms/certified_generate/?u="+ this.props.course_has_user_item.id);
                                        } 
                                        else {
                                          var courseFinish = false;
                                          var urlDownloadCertificate = false;
                                        }
                                        return (
                                          <div key={1} className="course-item">
                                              <a className="proof_slide" href={urlDownloadCertificate}>
                                                <div className="icon-big">
                                                  <i className={"text-info "+(courseFinish? "pe-7s-unlock" :"pe-7s-lock")} />
                                                </div>
                                                <img src={thumb_certificate} />
                                              </a>
                                        </div>
                                        );
                                      }}
                                    </Query>
                                }
                              </Slider>
                            </div>
                          </Col>
                        </Row>
            );
          }}
        </Query>
    );
  }
}

export default CourseCarousel;
