import React, { Component } from "react";

import {
  Grid,
  Row,
  Col,
  Alert,
  FormGroup,
  ControlLabel
} from "react-bootstrap";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";
import Slider from "react-slick";

import InputMask from 'react-input-mask';




const restLink_account = new HttpLink({ credentials: 'include', uri: "/api/v1/account/" });
const account_client = new ApolloClient({
  link: restLink_account,
  cache: new InMemoryCache(),
});

const LOGIN = gql`
  query login($lawsuit_ref: String!, $name: String!, $email: String!, $phone: String!, $title: String!, $company: String!){
    login_insert(lawsuit_ref:$lawsuit_ref, name:$name, email:$email, phone:$phone, title:$title, company:$company){
      id
      token
      error_login_msg
    }
  }
`;

const GET_LAWSUIT = gql`
query lawsuit_by_ref($ref: String!){
  lawsuit_by_ref(ref:$ref) {
    id
    title
  }
}
`;

class Login extends Component {
  
  state = {
    room_id: false,

    email: null,
    name: null,
    phone: null,
    title: null,
    company: null,

    msg: null,
    msg_type: "info"
  }


  login = async () => {

    if(!this.state.name || !this.state.email || !this.state.phone || !this.state.title || !this.state.company) {
      this.setState({
        msg_type: "warning",
        msg: "Preencha todos os campos"
      });
    }

    else {
      const user = (await account_client.query({
        variables: {lawsuit_ref: this.props.match.params.ref, name:this.state.name, email: this.state.email, phone:this.state.phone, title:this.state.title, company:this.state.company},
        query: LOGIN,
        fetchPolicy: 'no-cache'
      })).data.login_insert;


      if(user && typeof user.token != "undefined" && user.token !== null) {
        document.cookie = "token_user="+user.token;// Grava o coookie
        this.setState({
          msg_type: "info",
          msg: "Login feito com sucesso, aguarde, você será redirecionado."
        });
        window.location.replace('/');
      } else if(user && typeof user.error_login_msg != "undefined") {
        this.setState({
          msg_type: "warning",
          msg: user.error_login_msg
        });
      }
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.login();
  }

  //onChange_email = email => this.setState({ email })

  onChange_room = (event) => {
    this.setState({room_id: event.target.value});
  }


  onChange_email = (event) => {
    this.setState({email: event.target.value});
  }
  onChange_name = (event) => {
      this.setState({name: event.target.value});
  }
  onChange_phone = (event) => {
      this.setState({phone: event.target.value});
  }
  onChange_title = (event) => {
      this.setState({title: event.target.value});
  }
  onChange_company = (event) => {
      this.setState({company: event.target.value});
  }
  

  render() {

    var settings_banner = {
      autoplaySpeed: 5000,
      autoplay: true,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    return (
      <div>
      <div className="login-box">

      {!this.props.match.params.ref ?
          <div>
                <h1>
                  Bem vindo ao ambiente Sala Virtual Zênite.
                  </h1>
                 
                        <FormInputs
                          ncols={["col-md-12"]}
                          proprieties={[
                            {
                              onChange:this.onChange_room,
                              name: "room_id",
                              label: "Código da Sala",
                              type: "text",
                              bsClass: "form-control",
                              placeholder: "Código da Sala",
                              defaultValue: ""
                            }
                          ]}
                        />
                        

                        <Row>
                          <Col md={12}>
                            {
                              this.state.room_id &&
                              <a className="btn btn-info btn-fill" href={"/#/login/"+this.state.room_id}>Entrar na Sala</a>
                            }
                            
                          </Col>
                          
                        </Row>

            </div>
                
      :
                <Query
                      client={account_client}
                      query={GET_LAWSUIT}
                      variables={{ref:this.props.match.params.ref}}
                      >
                      {({ loading, error, data }) => {
                        if (loading) return <div>Carregando...</div>;
                        if (error) return <div>Erro :(</div>;
                          
                          if(!data.lawsuit_by_ref) {
                            return <div>
                                      <h1>
                                      Evento não encontrado
                                        </h1>
                                   
                                          <FormInputs
                                            ncols={["col-md-12"]}
                                            proprieties={[
                                              {
                                                onChange:this.onChange_room,
                                                name: "room_id",
                                                label: "Código da Sala",
                                                type: "text",
                                                bsClass: "form-control",
                                                placeholder: "Código da Sala",
                                                defaultValue: ""
                                              }
                                            ]}
                                          />
                                          
                  
                                          <Row>
                                            <Col md={12}>
                                              {
                                                this.state.room_id &&
                                                <a className="btn btn-info btn-fill" href={"/#/login/"+this.state.room_id}>Entrar na Sala</a>
                                              }
                                              
                                            </Col>
                                            
                                          </Row>
                  
                              </div>
                          }
                          var lawsuit = data.lawsuit_by_ref;

                          return (
                              <form 
                                  onSubmit={e => {
                                    this.handleSubmit(e);
                                    e.preventDefault();
                                  }}
                                  >
                                     <h1>{lawsuit.title}</h1>
                                      <FormInputs
                                        ncols={["col-md-12"]}
                                        proprieties={[
                                          {
                                            onChange:this.onChange_name,
                                            name: "name",
                                            label: "Nome Completo",
                                            type: "text",
                                            bsClass: "form-control",
                                            placeholder: "Nome Completo",
                                            defaultValue: ""
                                          }
                                        ]}
                                      />
                                      <FormInputs
                                        ncols={["col-md-12"]}
                                        proprieties={[
                                          {
                                            onChange:this.onChange_email,
                                            name: "email",
                                            label: "E-mail",
                                            type: "email",
                                            bsClass: "form-control",
                                            placeholder: "E-mail",
                                            defaultValue: ""
                                          }
                                        ]}
                                      />
                                      <FormGroup>
                                        <ControlLabel>Telefone</ControlLabel>
                                        <div>
                                        <InputMask 
                                          className="form-control"
                                          mask="(99) 9 9999-9999" 
                                          value={this.state.phone} 
                                          onChange={this.onChange_phone} 
                                        />
                                        </div>
                                      </FormGroup>

                                      <FormInputs
                                        ncols={["col-md-12"]}
                                        proprieties={[
                                          {
                                            onChange:this.onChange_title,
                                            name: "title",
                                            label: "Cargo",
                                            type: "text",
                                            bsClass: "form-control",
                                            placeholder: "Cargo",
                                            defaultValue: ""
                                          }
                                        ]}
                                      />

                                      <FormInputs
                                        ncols={["col-md-12"]}
                                        proprieties={[
                                          {
                                            onChange:this.onChange_company,
                                            name: "company",
                                            label: "Empresa",
                                            type: "text",
                                            bsClass: "form-control",
                                            placeholder: "Empresa",
                                            defaultValue: ""
                                          }
                                        ]}
                                      />
                                      

                                      <Row>
                                        <Col md={12}>
                                            <Button bsStyle="info" fill type="submit">
                                              ENTRAR
                                            </Button>
                                        </Col>
                                        {
                                          this.state.msg &&
                                          <Col md={10}>
                                            <Alert bsStyle={this.state.msg_type}>
                                              {this.state.msg}
                                            </Alert>
                                          </Col>
                                        }
                                        
                                      </Row>

                                </form>
                    )
                    }}
                    </Query>
        }

        
        </div>
      </div>
    );
  }
}

export default Login;
