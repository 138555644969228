import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  Alert,
  Table,
  FormGroup,
  ControlLabel
} from "react-bootstrap";

import { ApolloClient } from 'apollo-client';
import { Query } from "react-apollo";
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';
import { FormInputs } from "../FormInputs/FormInputs.jsx";
import Button from "../CustomButton/CustomButton.jsx";
import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";

const restLink_forum = new HttpLink({
  uri: "/api/v1/forum/",
  credentials: 'include'
});
const forum_client = new ApolloClient({
  link: restLink_forum,
  cache: new InMemoryCache(),
});



const CREATE_FORUM_MESSAGE = gql`
mutation createOrUpdateForumMessage($id: Int!, $forum_id: Int!, $message: String!){
  createOrUpdateForumMessage(
    id: $id,
    input:{
      forum_id: $forum_id,
      message: $message
    }
  ){
    id
  }
}
`;

const SAVE_CHAT_MSG = gql`
mutation createOrUpdateForumMessage($id: Int!, $forum_id: Int!, $message: String!, $replay_to_id: Int!){
  createOrUpdateForumMessage(
    id: $id,
    input:{
      forum_id: $forum_id,
      replay_to_id: $replay_to_id,
      message: $message
    }
  ){
    id
  }
}
`;

const GET_FORUM = gql`
query forum($id: Int!){
    forum(id:$id){
      id
      title
      forumMessages{
        id
        message
        user_id
        replay_to{
          id
          user_id
          user_name
          message  
        }
        user_name
        datetime_format_br_text
      }
    }
  }
`;



export class ForumList extends Component {




  constructor() {
    super();
    this.state = {
      loading: false, // Loading de quando estiver cadastrando algo
      msg_error: "", // Mensagem de erro
      msg: "", // Mensagem de sucesso
      forum: false,

      // Cadastro de novo forum
      message: "", // Título do forum

    };
  }

  onChange_message = (event) => {
    this.setState({
      message: event.target.value
    });
  }

  async createForumMessage() {

    if (this.state.message === "") {
      this.setState({
        msg_error: "Preencha todos os campos"
      });
    }
    else {
      this.setState({
        msg_error: "",
        loading: true
      });
      await forum_client.mutate({
        mutation: CREATE_FORUM_MESSAGE,
        variables: {
          id: 0,
          forum_id: this.props.forum_id,
          message: this.state.message
        }
      });


      setTimeout(() => {
        this.setState({
          message: "",
          loading: false
        });
        this.getVars();
      }, 2000);

    }

  }

  showReplyToUser($item) {
    this.setState(state => ({
      ...state,
      replyToUser:
        <form
          className="form_item"
          onSubmit={e => {
            e.preventDefault();
            forum_client.mutate({
              mutation: SAVE_CHAT_MSG,

              variables: {
                id: 0,
                replay_to_id: $item.id,
                forum_id: this.props.forum_id,
                message: e.target.elements.msg.value,
              }
            });
            this.setState(state => ({
              ...state,
              replyToUser: <AlertInfo alertInfo="Mensagem respondida com sucesso!" />
            }));
            return false;
          }}
        >
          <Grid fluid>
            <Row>
              <Col md={12}>
                <input type="hidden" defaultValue={$item.id} name="replyid" />

                {/* <div className="form-group">
                        <label className="control-label">Pergunta:</label>
                        <div>{$item.message}</div>
                      </div> */}
                <div className="form-group">
                  <label className="control-label">Resposta:</label>
                  <input className="form-control" type="text" name="msg" defaultValue={"@" + $item.user_name + ", "} />
                </div>
                {/* <div className="form-group">
                        <label className="control-label">Quem pode ver essa resposta?</label>
                        <select className="form-control" name="approved">
                          <option value={0}>Responder somente para {$item.user_name}</option>
                          <option value={1}>Responder para todos</option>
                        </select>
                      </div> */}
                <Button bsStyle="info" fill type="submit">
                  Responder
                      </Button>
              </Col>
            </Row>
          </Grid>
        </form>
    }));
  }



  async getVars() {

    var forum = (await forum_client.query({
      fetchPolicy: 'no-cache',
      query: GET_FORUM,
      variables: {
        id: this.props.forum_id,
      }
    })).data.forum;
    console.log(forum);
    this.setState({
      forum
    });
  }


  componentDidMount() {
    this.getVars();
  }



  render() {


    if (!this.state.forum) {
      return <h2>Carregando...</h2>
    }

    return (
      <div>
        <div>

          <Button bsStyle="info" fill pullRight onClick={() => this.props.chageForumId(false)}>
            Voltar para lista de comentários
              </Button>
          <hr />
          <h2>{this.state.forum.title}</h2>
          {
            this.state.forum.forumMessages.map((forumMessage) => (
              <Row className={forumMessage.replay_to ? "border-bottom" : "border-bottom"} key={forumMessage.id}>


  
  
                {forumMessage.replay_to &&
                <Col md={12} className="response-forum">
                  <div className="d-flex">
                    <div className="txt-subtitle">Re: {forumMessage.replay_to.user_name}:  </div>
                    <div className="txt-response"> {forumMessage.replay_to.message}</div>
                  </div>
                  </Col>
                }


                <Col md={11}>
                  <div className="txt-subtitle">
                    Por {forumMessage.user_name}, {forumMessage.datetime_format_br_text}
                  </div>
                  <p>{forumMessage.message}</p>

                </Col>
                <Col md={1}>
                  {(!forumMessage.replay_to) &&
                    <Button bsStyle="info" fill onClick={() => this.showReplyToUser(forumMessage)}>
                      <i className="pe-7s-back btn-icon" />
                    </Button>
                  }
                </Col>
                
              </Row>
            ))
          }

          {this.state.replyToUser}
          <div className="margin-10">
            <FormInputs
              ncols={["col-md-12"]}
              proprieties={[
                {
                  name: "message",
                  label: "Escreva sua mensagem",
                  type: "text",
                  bsClass: "form-control",
                  placeholder: "Escreva sua mensagem",
                  defaultValue: this.state.message,
                  value: this.state.message,
                  onChange: this.onChange_message
                }
              ]}
            />

            <Button bsStyle="info" fill pullRight onClick={() => this.createForumMessage()}>
              Inserir
                </Button>
            {
              this.state.msg_error !== "" &&
              <Alert bsStyle="danger">
                Preencha todos os campos
                  </Alert>
            }
            {
              this.state.loading &&
              <Alert bsStyle="info">
                <div className="txt-center">
                  <strong>Registrando...</strong>
                  <div className="loading_message">
                    Aguarde...
                    </div>
                </div>
              </Alert>
            }

          </div>
        </div>




      </div>

    );
  }
}

export default ForumList;
