import React, { Component } from "react";

import { Grid, Row, Col } from "react-bootstrap";

import { ApolloClient } from 'apollo-client';
import { Query } from "react-apollo";
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';
import { Proof } from "../../components/Proof/Proof.jsx";
import { ProofEvaluative } from "../../components/Proof/ProofEvaluative.jsx";
import { Scorm } from "../../components/Course/Scorm.jsx";
import thumb_certificate from "../../assets/img/certificate-400x225.jpg";
import thumb_reactionevaluation from "../../assets/img/avaliacao-reacao-400x225.jpg";
import thumb_proof from "../../assets/img/avaliacao-aprendizagem-400x225.jpg";
import { SidebarCourse } from "../../components/Sidebar/SidebarCourse.jsx";

import Slider from "react-slick";

const restLink = new HttpLink({ 
  uri: "/api/v1/lms/",
  credentials: 'include'
 });
const lms_client = new ApolloClient({
  link: restLink,
  cache: new InMemoryCache(),
});

const restLink_dam = new HttpLink({
  uri: "/api/v1/dam/",
  credentials: 'include'
});
const dam_client = new ApolloClient({
  link: restLink_dam,
  cache: new InMemoryCache(),
});


const GET_TRAIL_ITEM_HAS_USER = gql`
  query getTrailItemHasUser($trail_id: Int!){
    trail_itens_by_user(trail_id: $trail_id) {
      trailitenshasuser{
        item_id, item_type
      }
    }
  }
`;

const GET_TRAIL = gql`
  query getTrail($trail_id: Int!){
    trail(id:$trail_id){
      id, title, blockcontent
      ,proof{
        id, title
      }
      ,reactionevaluation{
        id, title
      }
    }
  }
`;

const GET_TRAILITEMS = gql`
  query getItens($trail_id: Int!){
    trailitems(trail_id:$trail_id) {
      trailitems{
        id, item_id, item_type
      }
    }
  }
`;

const GET_COURSE = gql`
  query getCourse($course_id: Int!){
    course(id:$course_id){
      id, title, thumbnail, video{
        id, thumbnail
      }
    }
  }
`;


const GET_ASSET = gql`
  query getAsset($dam_id: Int!){
    asset(id:$dam_id){
      id, title, thumbnail
    }
  }
`;


export class TrailCarousel extends Component {


  itemsTrailCompleted;

  constructor() {
    super();

    this.itemsTrailCompleted = [];
    
  }
  
  render() {

    var itemKey = this.props.item_type+"-"+this.props.item_id;
    if(this.itemsTrailCompleted.indexOf(itemKey) === -1) {
      this.itemsTrailCompleted.push(itemKey);
    }

    const windowWidth = window.innerWidth;

    var settings_courses = {
      vertical: true,
      autoplay: false,
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: (windowWidth <= 987 ? 2 : 4),
      slidesToScroll: 2
    };

    return (
      <Query
            client={lms_client}
            variables={{trail_id: this.props.trail_id}}
            query={GET_TRAIL_ITEM_HAS_USER}
            >
            {({ loading, error, data }) => {
              if (loading) return <div>Carregando...</div>;
              if (error) return <div>Erro :(</div>;

                if(data) {
                  data.trail_itens_by_user.trailitenshasuser.map((item) => {
                    var key = item.item_type+"-"+item.item_id;

                    // Verifica se a chave já está no array, evitando adicionar duplicado
                    if(this.itemsTrailCompleted.indexOf(key) === -1){
                      this.itemsTrailCompleted.push(key);
                    }
                  });
                }

                return (

          <Query
              client={lms_client}
              variables={{trail_id:this.props.trail_id}}
              query={GET_TRAIL}
              >
              {({ loading, error, data }) => {
                if (loading) return <div>Carregando...</div>;
                if (error) return <div>Erro :(</div>;

                  const trail = data.trail;

                  return (
              <Grid fluid>
              <Row>
                <Col md={12}>
                  <h2 className="title">Módulos</h2>
                  <div className="trail_bar vertical_carousel">
                  <Query
                      client={lms_client}
                      variables={{trail_id:this.props.trail_id}}
                      query={GET_TRAILITEMS}
                      >
                      {({ loading, error, data }) => {
                        if (loading) return <div>Carregando...</div>;
                        if (error) return <div>Erro :(</div>;

                          var trailItems_data = data.trailitems.trailitems;
                          
                          return (
                            <Slider {...settings_courses}>
                                { data.trailitems.trailitems.map((item, key_item) => (
                                  <div className="item_vetical_carousel">
                                  {item.item_type == "course" ?

                                  <Query
                                      client={lms_client}
                                      variables={{course_id:item.item_id}}
                                      query={GET_COURSE}
                                      >
                                      {({ loading, error, data }) => {
                                        if (loading) return <div>Carregando...</div>;
                                      if (error) return <div>Erro :(</div>;
                                          
                                          if(!data.course) {
                                            return <span></span>
                                          }

                                          var course = data.course;
                                          
                                          return (
                                            <div key={course.id} className={"course-item "+(this.itemsTrailCompleted.indexOf(item.item_type+"-"+item.item_id) !== -1 ? "course-item-view" : "")}>
                                            {trail.blockcontent !== 1 || this.itemsTrailCompleted.indexOf(item.item_type+"-"+item.item_id) !== -1 || ( key_item > 0 && this.itemsTrailCompleted.indexOf(trailItems_data[key_item-1].item_type+"-"+trailItems_data[key_item-1].item_id) !== -1) ? // Se essa trilha tem trava e esse item já está liberado
                                              <a
                                              data-gtm-type="click" 
                                              data-gtm-clicktype="link" 
                                              data-gtm-name={course.title}
                                              href={"/#/trail/"+course.id+"/"+item.item_type+"/"+this.props.trail_id}>
                                                <div className="icon-big">
                                                  <i className={"text-info "+(course.id == this.props.item_id && this.props.item_type == "course" ? "pe-7s-look" :"pe-7s-unlock")} />
                                                </div>
                                                <div className="course_btn_play"></div>
                                                <img  src={(course.thumbnail ? "/lms/assets/"+course.thumbnail : (course.video && course.video.thumbnail ? course.video.thumbnail : ""))} />
                                                <div className="title">
                                                  <span className="icon-visualized"></span>
                                                  {course.title}
                                                </div>
                                              </a>
                                            :
                                              <a>
                                                <div className="icon-big">
                                                  <i className={"text-info "+(course.id == this.props.item_id && this.props.item_type == "course" ? "pe-7s-look" :"pe-7s-lock")} />
                                                </div>
                                                <div className="course_btn_block"></div>
                                                <img  src={(course.thumbnail ? "/lms/assets/"+course.thumbnail : (course.video && course.video.thumbnail ? course.video.thumbnail : ""))} />
                                                <div className="title">
                                                  <span className="icon-visualized"></span>
                                                  {course.title}
                                                </div>
                                                
                                              </a>
                                            }

                                            </div>
                                      )
                                    }}
                                  </Query>

                                  :
                                      <Query
                                      client={dam_client}
                                      variables={{dam_id:item.item_id}}
                                      query={GET_ASSET}
                                      >
                                      {({ loading, error, data }) => {
                                        if (loading) return <div>Carregando...</div>;
                                        if (error) return <div>Erro :(</div>;
                                          
                                          var asset = data.asset;

                                          return (
                                            <div key={asset.id} className={"course-item "+(this.itemsTrailCompleted.indexOf(item.item_type+"-"+item.item_id) !== -1 ? "course-item-view" : "")}>
                                            {trail.blockcontent !== 1 || this.itemsTrailCompleted.indexOf(item.item_type+"-"+item.item_id) !== -1 || ( key_item > 0 && this.itemsTrailCompleted.indexOf(trailItems_data[key_item-1].item_type+"-"+trailItems_data[key_item-1].item_id) !== -1) ? // Se essa trilha tem trava e esse item já está liberado
                                              <a 
                                              data-gtm-type="click" 
                                              data-gtm-clicktype="link" 
                                              data-gtm-name={asset.title}
                                              href={"/#/trail/"+asset.id+"/"+item.item_type+"/"+this.props.trail_id}>
                                              <div className="icon-big">
                                                <i className={"text-info "+(asset.id == this.props.item_id && this.props.item_type == "asset" ? "pe-7s-look" :"pe-7s-unlock")} />
                                              </div>

                                              <div className="course_btn_play"></div>
                                              <img  src={"/dam/assets/"+asset.thumbnail} />
                                              <div className="title">
                                                <span className="icon-visualized"></span>
                                                {asset.title}
                                              </div>
                                              </a>
                                            :
                                              <a >
                                              <div className="icon-big">
                                                <i className={"text-info "+(asset.id == this.props.item_id && this.props.item_type == "asset" ? "pe-7s-look" :"pe-7s-lock")} />
                                              </div>

                                              <div className="course_btn_block"></div>
                                              <img  src={"/dam/assets/"+asset.thumbnail} />
                                              <div className="title">
                                                <span className="icon-visualized"></span>
                                                {asset.title}
                                              </div>
                                              </a>
                                            }
                                            </div>
                                      )
                                    }}
                                  </Query>
                                  }

                                </div>
                                ))}

                                {trail.reactionevaluation ?
                                  <div key={trail.reactionevaluation.id} className="course-item">
                                    {/*trail.blockcontent !== 1 || */(trailItems_data.length === this.itemsTrailCompleted.length || this.props.item_type == "reactionevaluation" ) ? // Desbloqueia a avaliação de desenepnho se a trilha for aberta ou se o número de conteúdos concluídos for igual ao número de conteúdos
                                        <a 
                                        data-gtm-type="click" 
                                        data-gtm-clicktype="link" 
                                        data-gtm-name={trail.reactionevaluation.title}
                                        className="proof_slide" href={"/#/trail/"+trail.reactionevaluation.id+"/reactionevaluation/"+this.props.trail_id}>
                                          <div className="icon-big">
                                            <i className={"text-info "+(trail.reactionevaluation.id == this.props.item_id && this.props.item_type == "reactionevaluation" ? "pe-7s-look" :"pe-7s-unlock")} />
                                          </div>
                                          <img src={thumb_reactionevaluation} />
                                        </a>
                                    :
                                        <a>
                                          <div className="course_btn_block"></div>
                                          <div className="icon-big">
                                            <i className={"text-info "+(trail.reactionevaluation.id == this.props.item_id && this.props.item_type == "reactionevaluation" ? "pe-7s-look" :"pe-7s-lock")} />
                                          </div>
                                          <img src={thumb_reactionevaluation} />
                                        </a>
                                    }
                                </div>
                                :
                                  <span></span>
                                }

                                {trail.proof ?
                                  <div key={trail.proof.id} className="course-item">
                                    {/*trail.blockcontent !== 1 || */(trailItems_data.length === this.itemsTrailCompleted.length || this.props.item_type == "proof" ) ? // Desbloqueia a avaliação de desenepnho se a trilha for aberta ou se o número de conteúdos concluídos for igual ao número de conteúdos
                                        <a 
                                        data-gtm-type="click" 
                                        data-gtm-clicktype="link" 
                                        data-gtm-name={trail.proof.title}
                                        className="proof_slide" href={"/#/trail/"+trail.proof.id+"/proof/"+this.props.trail_id}>
                                          <div className="icon-big">
                                            <i className={"text-info "+(trail.proof.id == this.props.item_id && this.props.item_type == "proof" ? "pe-7s-look" :"pe-7s-unlock")} />
                                          </div>
                                          <img src={thumb_proof} />
                                        </a>
                                    :
                                        <a>
                                          <div className="course_btn_block"></div>
                                          <div className="icon-big">
                                            <i className={"text-info "+(trail.proof.id == this.props.item_id && this.props.item_type == "proof" ? "pe-7s-look" :"pe-7s-lock")} />
                                          </div>
                                          <img src={thumb_proof} />
                                        </a>
                                    }
                                </div>
                                :
                                  <span></span>
                                }
                          </Slider>
                            
                          )
                    }}
                    </Query>
                    </div>
                </Col>
              </Row>
            </Grid>
              )
            }}
          </Query>
          )
        }}
      </Query>
    );
  }
}

export default TrailCarousel;
