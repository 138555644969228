import React, { Component, useState } from "react";
import {
  Grid,
  Row,
  Col,
  Alert,
  Table,
  FormGroup,
  ControlLabel,
} from "react-bootstrap";

import ReactHtmlParser from "react-html-parser";
import Vimeo from "@u-wave/react-vimeo";

// const parse = require('html-react-parser');

import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import gql from "graphql-tag";
import { HttpLink } from "apollo-link-http";

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

import ReactLoading from "react-loading";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; //

import Modal from "react-awesome-modal";

const restLink_account = new HttpLink({
  credentials: "include",
  uri: "/api/v1/account/",
});
const account_client = new ApolloClient({
  link: restLink_account,
  cache: new InMemoryCache(),
});

const restLink_lms = new HttpLink({
  uri: "/api/v1/lms/",
  credentials: "include",
});
const lms_client = new ApolloClient({
  link: restLink_lms,
  cache: new InMemoryCache(),
});

const GET_VIDEOS = gql`
  query videos_collections($lawsuit_id: Int!) {
    videos_collections(lawsuit_id: $lawsuit_id, limit: 9999) {
      videos {
        id
        title
        embedId
      }
    }
  }
`;

const VIDEO_HAS_USER = gql`
  mutation createOrUpdateVideoHasUser(
    $video_id: String!
    $time: Int!
    $time_reproduced: Int!
    $time_seconds: String!
  ) {
    createOrUpdateVideoHasUser(
      input: {
        video_id: $video_id
        time: $time
        time_reproduced: $time_reproduced
        time_seconds: $time_seconds
      }
    ) {
      id
    }
  }
`;
const GET_VIDEO = gql`
  query getVideoByUser($video_id: String!) {
    getVideoByUser(video_id: $video_id) {
      id
      time_seconds
      time_reproduced
    }
  }
`;

var players = new Object();
var intervalGetCurlTime = null;
var arrSeconds = new Object();

async function onReady(event, videoID) {
  players[videoID] = event;
  // console.log("---------------------- onReady");
  // console.log(players);
  // console.log(videoID);
}

async function onPlay(event, videoID) {
  if (typeof players[videoID] !== "undefined") {
    // console.log("---------------------- onPlay");
    // console.log(videoID);
    if (intervalGetCurlTime !== null) {
      clearInterval(intervalGetCurlTime);
    }
    intervalGetCurlTime = setInterval(
      () => getCurTime(players[videoID], videoID),
      900
    );
  }
}

async function getCurTime(player, videoID) {
  var time = parseInt(await player.getCurrentTime());
  var timeAll = parseInt(await player.getDuration());
  if (time !== 0) {
    if (typeof arrSeconds[videoID] === "undefined") {
      arrSeconds[videoID] = [];
    }
    arrSeconds[videoID].push(time);
    arrSeconds[videoID] = uniqArrayValues(arrSeconds[videoID]);
    if (arrSeconds[videoID].length % 10 === 0) {
      setCompletedItem(arrSeconds[videoID], videoID, timeAll);
    }
  }
}

function setCompletedItem(arrSeconds, videoID, timeAll) {
  // console.log("++++++++++++++++++++++ setCompletedItem");
  // console.log({
  //       video_id: videoID, time: timeAll, time_reproduced: arrSeconds.length, time_seconds: JSON.stringify(arrSeconds),
  //     });
  lms_client.mutate({
    mutation: VIDEO_HAS_USER,
    variables: {
      video_id: videoID,
      time: timeAll,
      time_reproduced: arrSeconds.length,
      time_seconds: JSON.stringify(arrSeconds),
    },
  });
}

function uniqArrayValues(a) {
  var seen = {};
  var out = [];
  var len = a.length;
  var j = 0;
  for (var i = 0; i < len; i++) {
    var item = a[i];
    if (seen[item] !== 1) {
      seen[item] = 1;
      out[j++] = item;
    }
  }
  return out;
}

class VideoList extends Component {
  render() {
    return (
      <div>
        <div className="content">
          <div className="container">
            <div className="">
              <h3 name="videos">Replays</h3>
              <Query
                client={account_client}
                query={GET_VIDEOS}
                variables={{
                  lawsuit_id: this.props.lawsuit_id,
                }}
              >
                {({ loading, error, data }) => {
                  if (loading) return <div>Carregando...</div>;
                  if (error) return <div>Erro :(</div>;

                  if (
                    !data.videos_collections ||
                    !data.videos_collections.videos.length
                  ) {
                    return (
                      <p className="z-p">
                        Os replays estarão disponíveis a partir do segundo dia
                        do evento.
                      </p>
                    );
                  }

                  return (
                    <div>
                      <Row className="replay">
                        {data.videos_collections.videos.map((item) => (
                          <React.Fragment>
                            {typeof item.embedId !== "undefined" && (
                              <Col md={4} key={item.id}>
                                <h2>{item.title}</h2>
                                <div className="embed-responsive embed-responsive-16by9 card shadow-sm">
                                  {
                                    //ReactHtmlParser(item.embed)
                                  }
                                  <Query
                                    client={lms_client}
                                    query={GET_VIDEO}
                                    variables={{
                                      video_id: item.embedId,
                                    }}
                                  >
                                    {({ loading, error, data }) => {
                                      if (loading)
                                        return <div>Carregando...</div>;
                                      if (error) return <div>Erro :(</div>;

                                      console.log("==============");
                                      console.log(data);

                                      var start_video = 1;
                                      if (
                                        data.getVideoByUser !== null &&
                                        typeof data.getVideoByUser
                                          .time_reproduced !== "undefined" &&
                                        data.getVideoByUser.time_reproduced !=
                                          ""
                                      ) {
                                        start_video =
                                          data.getVideoByUser.time_reproduced;
                                      }
                                      return (
                                        <Vimeo
                                          loop={false}
                                          speed={true}
                                          video={item.embedId}
                                          onPlay={(event) =>
                                            onPlay(event, item.embedId)
                                          }
                                          onReady={(event) =>
                                            onReady(event, item.embedId)
                                          }
                                          // onEnd={this.props.onEnd}
                                          start={start_video}
                                        />
                                      );
                                    }}
                                  </Query>
                                </div>
                              </Col>
                            )}
                          </React.Fragment>

                          // {/* // <Row className="border-bottom">
                          // //   <Col md={3}>
                          // //     <img src={"/account/assets/"+item.thumbnail} width={200} />
                          // //   </Col>
                          // //   <Col md={9}>
                          // //     <p><strong>{item.title}</strong></p>
                          // //     <p>{item.embed}</p>
                          // //   </Col>
                          // // </Row> */}
                        ))}
                      </Row>
                    </div>
                  );
                }}
              </Query>

              <div className="both"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default VideoList;
