import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";

export class StatsCard extends Component {
  render() {
    return (
      <div className="card card-stats">
        <div className="content">
          <Row>
            <Col xs={12}>
              <div className="icon-big text-center icon-warning">
                {this.props.bigIcon}
              </div>
            </Col>
          </Row>
          <div className="footer">
            <div className="title">
              <div>{this.props.statsTitle}</div>
              {this.props.statsText}
              {this.props.link ?
                  <span><br /><a href={this.props.link}>{this.props.linkText}</a></span>
              : <div></div>}
              {this.props.calendar ?
                  <span><br /><a Style="cursor:pointer;">Adicionar na agenda</a></span>
              : <div></div>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StatsCard;
